import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, CardContent, DialogTitle, Dialog, DialogContent, Grid, TextField
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import { apiOpenService } from '../../api/openApi';
import SelectLink from '../formsUI/selectLink';
import UploadFiles from '../formsUI/FileUploadDialog';

function IntoFactoryDialog(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    onClose, open, reqData
  } = props;
  const [intoFactoryPage, setIntoFactoryPage] = useState({
    customerData: [],
    salesData: []
  });
  const [errMsg, setErrMsg] = useState(null);
  const [arrfile, setArrfile] = useState([]);

  const schema = Yup.object().shape({
    IntofactoryDate: Yup.date().required('日期必填'),
    CustomerID: Yup.string().required('客戶代號必填'),
    RecipientStaffID: Yup.string().required('業務必填'),
    Remark: Yup.string()
  });

  function createIntoFactory(fields, { setSubmitting }) {
    apiIntoFactoryService.apiIntoFactoryAdd(fields)
      .then((res) => {
        // console.log('createIntoFactory_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('createIntoFactory_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateIntoFactory(parmaid, fields, setSubmitting) {
    apiIntoFactoryService.apiIntoFactoryUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateIntoFactory_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('updateIntoFactory_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleCancelClick(value) {
    // console.log('handleCancelClick', value);
    onClose(value);
  }

  function saveIntoFactory(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (!reqData) {
      createIntoFactory(fields, setSubmitting);
    } else {
      updateIntoFactory(reqData[0].IntofactoryID, fields, setSubmitting);
    }
  }
  return (
    <Dialog scroll="paper" fullWidth maxWidth="xl" aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">{reqData ? '編輯入廠單' : '新增入廠單'}</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            IntofactoryID: '',
            IntofactoryDate: '',
            CustomerID: '',
            Customer: '',
            RecipientStaffID: '',
            BusinessStaffName: '',
            IntofactoryNum: '',
            KeyInStaffID: '',
            Remark: '',
            IntofactoryRecipt: ''
          }}
          validationSchema={schema}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={saveIntoFactory}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            setFieldValue
          }) => {
            const fetchData = useCallback(() => {
              const fetchingData = async () => {
                // 取得客戶、業務選單
                const [customerData, salesData] = await Promise.all([
                  apiOpenService.apiGetSelectCustomerData(),
                  apiOpenService.apiGetSelectStaffData('DepartmentID=5')
                ]);

                // 放進state
                setIntoFactoryPage({
                  customerData,
                  salesData
                });

                setOpenBackdrop(false);
              };
              fetchingData();
            }, []);

            useEffect(() => {
              // console.log('reqData', reqData);
              if (reqData) {
                // 編輯模式
                // 列表用篩選取值過來
                // console.log('reqData', reqData[0]);
                const arrFile = reqData[0].IntofactoryRecipt?.split(',');
                setFieldValue('IntofactoryRecipt', arrFile, false);
                setArrfile(arrFile);
                const fields = ['IntofactoryID', 'IntofactoryDate', 'CustomerID', 'Customer', 'RecipientStaffID', 'BusinessStaffName', 'IntofactoryNum', 'KeyInStaffID', 'Remark'];

                // 取得資料架構與新增更新不同，重新塞值
                fields.forEach((field) => {
                  switch (field) {
                    case 'IntofactoryDate':
                      setFieldValue(field, moment.parseZone(reqData[0].IntofactoryDate).format('YYYY-MM-DD'), false);
                      break;
                    case 'Customer':
                      setFieldValue(field, reqData[0].CustomerN, false);
                      break;
                    case 'KeyInStaffID':
                      setFieldValue(field, reqData[0].KeyIn.Name, false);
                      break;
                    default:
                      setFieldValue(field, reqData[0][`${field}`], false);
                      break;
                  }
                });
              }
              setOpenBackdrop(true);
              fetchData();
            }, [fetchData]);

            return (
              <form onSubmit={handleSubmit}>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                  open={openBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.IntofactoryDate)}
                          helperText={errors.IntofactoryDate}
                          fullWidth
                          name="IntofactoryDate"
                          value={values.IntofactoryDate || ''}
                          onChange={handleChange}
                          variant="outlined"
                          type="date"
                          disabled={reqData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectLink
                          name="CustomerID"
                          label="客戶代號"
                          selectValue={values.CustomerID || ''}
                          options={intoFactoryPage.customerData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="客戶"
                          name="Customer"
                          value={values.Customer || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="業務"
                          name="BusinessStaffName"
                          value={values.BusinessStaffName || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectLink
                          name="RecipientStaffID"
                          label="收貨人員"
                          selectValue={values.RecipientStaffID || ''}
                          options={intoFactoryPage.salesData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="入廠單號"
                          name="IntofactoryNum"
                          value={values.IntofactoryNum || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="Key In"
                          name="KeyInStaffID"
                          value={values.KeyInStaffID || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="備註"
                          name="Remark"
                          margin="normal"
                          value={values.Remark || ''}
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </Grid>
                      {!reqData ? null
                        : (
                          <Grid item xs={12}>
                            <UploadFiles data={values} filedata={arrfile} />
                          </Grid>
                        )}
                    </Grid>
                  </CardContent>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting || isValidating}
                      color="primary"
                      variant="contained"
                    >
                      儲存
                    </Button>
                    <Button onClick={() => handleCancelClick(false)} variant="contained">
                      Cancel
                    </Button>
                  </Box>
                  <Fade in={fade}>
                    {isError
                      ? (
                        <Alert severity="error">
                          <AlertTitle>操作失敗</AlertTitle>
                          {errMsg || '請連絡相關人員'}
                        </Alert>
                      )
                      : (
                        <Alert severity="success">
                          <AlertTitle>操作成功</AlertTitle>
                        </Alert>
                      )}
                  </Fade>
                </Card>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

IntoFactoryDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reqData: PropTypes.object
};

export default IntoFactoryDialog;
