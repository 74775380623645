import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow,
  Link
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from 'src/theme/useStyles';

const VenderListResults = ({ venders, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card>
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                廠商代號
              </TableCell>
              <TableCell>
                廠商簡稱
              </TableCell>
              <TableCell>
                電話
              </TableCell>
              <TableCell>
                聯絡人
              </TableCell>
              <TableCell>
                手機
              </TableCell>
              <TableCell>
                地址
              </TableCell>
              <TableCell>
                操作
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {venders && venders.slice(page * limit, page * limit + limit).map((vender) => (
              <TableRow
                hover
                key={vender.VenderID}
              >
                <TableCell>
                  {vender.VenderID}
                </TableCell>
                <TableCell>
                  {vender.VenderN}
                </TableCell>
                <TableCell>
                  {vender.Tel}
                </TableCell>
                <TableCell>
                  {vender.ContactName}
                </TableCell>
                <TableCell>
                  {vender.Phone}
                </TableCell>
                <TableCell>
                  {vender.Address}
                </TableCell>
                <TableCell>
                  <Box>
                    <Link component={RouterLink} to={`${vender.VenderID}/copy`}>
                      <Button
                        className={classes.CopyButton}
                        size="small"
                        variant="contained"
                      >
                        複製
                      </Button>
                    </Link>
                    <Link component={RouterLink} to={`${vender.VenderID}/edit`}>
                      <Button
                        className={classes.EditButton}
                        size="small"
                        variant="contained"
                      >
                        編輯
                      </Button>
                    </Link>
                    <Button
                      className={classes.DeleteButton}
                      size="small"
                      variant="contained"
                      onClick={() => rest.onClick(vender.VenderID)}
                    >
                      刪除
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={venders ? venders.length : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

VenderListResults.propTypes = {
  venders: PropTypes.array
};

export default VenderListResults;
