import fetchWrapper from './fetch-wrapper';

// 取得所有廠商資料
function apiGetVenderAll() {
  // console.log('GetVenderAll');
  return fetchWrapper.get('vender/?Page=1&Pagesize=500');
}

// 搜尋廠商
function apiSearchVender(VenderID, Name) {
  let strURL = '';
  if (VenderID !== '') {
    strURL = strURL.concat('VenderID=', VenderID);
  } else {
    strURL = strURL.concat('VenderN=', Name);
    strURL = strURL.concat('&VenderName=', Name);
  }
  // console.log('Vender_Search', strURL);
  return fetchWrapper.get(`vender/?${strURL}`);
}

function apiVenderAdd(data) {
  // console.log('Vender_ADD', data);
  return fetchWrapper.post('vender/create', data);
}

function apiVenderUpdate(parmaid, data) {
  const body = {
    ...data,
    VenderID: parseInt(parmaid, 10),
  };
  // console.log('Vender_Update', body);
  return fetchWrapper.put('vender/update', body);
}

function apiVenderDelete(id) {
  const body = { VenderID: id };
  return fetchWrapper.delete('vender/delete', body);
}

export const apiVenderService = {
  apiGetVenderAll,
  apiSearchVender,
  apiVenderAdd,
  apiVenderUpdate,
  apiVenderDelete
};
