import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@mui/material';
import SaleListResults from '../components/sale/saleListResults';
import SaleListToolbar from '../components/sale/saleListToolbar';
import { apiSaleService } from '../api/saleApi';
import { apiVenderService } from '../api/venderApi';

const SaleList = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [venderSelect, setVenderSelect] = useState([]);

  const handleSearchChange = (value) => {
    setOpenBackdrop(true);
    apiSaleService.apiSearchSale(value).then((res) => {
      setDataList(res.data.Sales);
      setOpenBackdrop(false);
    });
  };

  useEffect(() => {
    setOpenBackdrop(true);
    apiVenderService.apiGetVenderAll().then((res) => setVenderSelect(res.data));
    apiSaleService.apiGetSaleList().then((x) => {
      setDataList(x.data.Sales);
      setOpenBackdrop(false);
    });
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>銷貨單資料</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <SaleListToolbar venderSelect={venderSelect} onClick={handleSearchChange} />
          <Box sx={{ pt: 3 }}>
            <SaleListResults sales={dataList} />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default SaleList;
