import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Link,
  FormGroup
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiStaffService } from '../../api/staffApi';
import { apiOpenService } from '../../api/openApi';
import Department from './department';

function StaffForm() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = params.id == null;
  const [departState, setdepartState] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    Account: Yup.string().required('工號必填'),
    Name: Yup.string().required('姓名必填'),
    Password: Yup.string().min(6, '密碼至少6位').max(14, '密碼不得超過14位').concat(isAddMode ? Yup.string().required('密碼必填') : null),
    // eslint-disable-next-line consistent-return
    PasswordConfirm: Yup.string().when('Password', (pw, item) => {
      if (pw || isAddMode) return item.required('密碼確認必填');
    }).oneOf([Yup.ref('Password')], '密碼需相同'),
    Departments: Yup.array().min(1, '必須選一個')
  });

  function createStaff(fields, setSubmitting) {
    apiStaffService.apiStaffAdd(fields)
      .then((res) => {
        // console.log('createStaff_OK', res);
        setFade(true);
        setTimeout(() => navigate('/staff', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('createStaff_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateStaff(parmaid, fields, setSubmitting) {
    apiStaffService.apiStaffUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateStaff_OK', res);
        setFade(true);
        setTimeout(() => navigate('/staff', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('updateStaff_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function saveStaff(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (isAddMode) {
      createStaff(fields, setSubmitting);
    } else {
      updateStaff(params.id, fields, setSubmitting);
    }
  }

  return (
    <Formik
      initialValues={{
        Account: '',
        Name: '',
        Password: '',
        Departments: []
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveStaff}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        isValidating,
        setFieldValue
      }) => {
        useEffect(() => {
          setOpenBackdrop(true);
          // console.log('isAddMode', isAddMode);
          apiOpenService.apiGetSelectData('Departments').then((res) => {
            setdepartState(res);
            if (isAddMode) setOpenBackdrop(false);
          });
          if (!isAddMode) {
            apiStaffService.apiSearchStaff(params.id, '').then((res) => {
              // console.log('res', res);
              const fields = ['Account', 'Name', 'Departments'];
              fields.forEach((field) => {
                if (field === 'Departments') {
                  const arr = [];
                  res.data[0].Departments.forEach((val) => arr.push(val.DepartmentID));
                  setFieldValue(field, arr, false);
                } else {
                  setFieldValue(field, res.data[0][field], false);
                }
              });
              setOpenBackdrop(false);
            });
          }
        }, []);

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title={isAddMode ? '新增員工' : '編輯員工'}
              />
              <CardContent>
                <Box mb={5}>
                  <TextField
                    error={Boolean(errors.Account)}
                    helperText={errors.Account}
                    fullWidth
                    label="工號"
                    name="Account"
                    margin="normal"
                    value={values.Account || ''}
                    onChange={handleChange}
                    variant="outlined"
                    disabled={!isAddMode}
                  />

                  <TextField
                    error={Boolean(errors.Name)}
                    helperText={errors.Name}
                    fullWidth
                    label="姓名"
                    name="Name"
                    margin="normal"
                    value={values.Name || ''}
                    onChange={handleChange}
                    variant="outlined"
                  />
                </Box>

                <Box mb={5}>
                  <TextField
                    error={Boolean(errors.Password)}
                    fullWidth
                    helperText={errors.Password}
                    label="密碼"
                    margin="normal"
                    name="Password"
                    onChange={handleChange}
                    type="password"
                    value={values.Password}
                    variant="outlined"
                  />

                  <TextField
                    error={Boolean(errors.PasswordConfirm)}
                    fullWidth
                    helperText={errors.PasswordConfirm}
                    label="密碼確認"
                    margin="normal"
                    name="PasswordConfirm"
                    onChange={handleChange}
                    type="Password"
                    value={values.PasswordConfirm}
                    variant="outlined"
                  />
                </Box>

              </CardContent>

              <Divider />

              <CardHeader
                title="部門"
              />
              <CardContent>
                <FormGroup row>
                  {departState && departState.map((item) => (
                    <Department
                      key={item.DepartmentID}
                      name="Departments"
                      data={item}
                      onChange={setFieldValue}
                    />
                  ))}
                  <Box component="span" color="red">
                    <ErrorMessage name="Departments" />
                  </Box>
                </FormGroup>
              </CardContent>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  color="primary"
                  variant="contained"
                >
                  儲存
                </Button>
                <Link component={RouterLink} to="/Staff">
                  <Button color="secondary" variant="contained">
                    取消
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default StaffForm;
