import { Helmet } from 'react-helmet';
import React from 'react';
import { Box, Container } from '@material-ui/core';
import ExperimentForm from '../components/experimentAddEdit/experimentForm';

const ExperimentAddEdit = () => (
  <>
    <Helmet>
      <title>實驗室資料</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <ExperimentForm />
      </Container>
    </Box>
  </>
);

export default ExperimentAddEdit;
