import { useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, CardContent, DialogTitle, Dialog, DialogContent, Link, Grid, TextField
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { apiDetectionService } from '../../api/detectionApi';
import { apiOpenService } from '../../api/openApi';
import { apiCommodityService } from '../../api/commodityApi';
import SelectWrapper from '../formsUI/selectWrapper';

function DetectionsDialog(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    onClose, open, reqData, intoFactoryID, customerID, commodityID
  } = props;
  const [detectionMethod, setDetectionMethod] = useState([]);
  const [sampleStaffData, setSampleStaffData] = useState([]);
  const [elementData, setElementData] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    CommissionDate: Yup.date().required('委託日期必填'),
    DetectionMethodID: Yup.string().required('請選擇檢測方式'),
    SampleStaffID: Yup.string().required('請選擇取樣人員'),
    ElementID: Yup.string().required('請選擇元素')
  });

  function createDetection(fields, setSubmitting) {
    const body = {
      ...fields,
      IntofactoryID: parseInt(intoFactoryID, 10),
      CustomerID: parseInt(customerID, 10),
      DetectionItems: [{
        CommodityID: parseInt(commodityID, 10),
        ElementID: parseInt(fields.ElementID, 10)
      }]
    };
    apiDetectionService.apiDetectionAdd(body)
      .then((res) => {
        // console.log('createDetection_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('createDetection_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }
  /*
  function updateDetection(parmaid, fields, setSubmitting) {
    apiDetectionService.apiDetectionUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateDetection_OK', res);
        onClose();
      })
      .catch((error) => {
        setSubmitting(false);
        // console.log('updateDetection_Err', error);
        if (error !== '') { setErrMsg(error); }
      });
  }
  */
  function handleCancelClick(value) {
    // console.log('handleCancelClick', value);
    onClose(value);
  }

  function saveDetection(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (reqData.DetectionID === undefined) {
      console.log('detect', fields);
      createDetection(fields, setSubmitting);
    } else {
      // updateDetection(reqData.DetectionID, fields, setSubmitting);
    }
  }

  function handleRecheck() {
    apiDetectionService.apiDetectionRecheck(reqData.DetectionID)
      .then((res) => {
        // console.log('Recheck_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('Recheck_Err', error);
        if (error !== '') { setErrMsg(error); }
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleLabel(DetectionID) {
    const session = sessionStorage.getItem('token');
    localStorage.setItem('token', session);
    window.open(`/label/${DetectionID}/0/2`, '_blank');
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="xl" open={open}>
      <DialogTitle id="simple-dialog-title">檢測單</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            DetectionNum: '',
            DetectionTypeID: 1,
            CommissionDate: moment().format('YYYY-MM-DD'),
            DetectionMethodID: '',
            SampleStaffID: '',
            DetectStaffID: '',
            ElementID: '',
            Value: '',
            DetectionWeight: '',
            Volume: '',
            Recheck: '',
            RecheckObject: '',
            SampleID: '',
            ReportDate: '',
            Remark: '',
          }}
          validationSchema={schema}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={saveDetection}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            setFieldValue
          }) => {
            useEffect(async () => {
              // console.log('DetectionDialog_useEffect');
              setOpenBackdrop(true);
              await apiOpenService.apiGetSelectData('DetectionMethods').then((res) => setDetectionMethod(res));
              await apiOpenService.apiGetSelectStaffData('DepartmentID=5').then((res) => setSampleStaffData(res));
              await apiCommodityService.apiSearchCommodity(commodityID, '').then((res) => {
                const arrElement = res.data[0].CommodityElements.map((i) => ({
                  ElementID: i.Element.ElementID,
                  Element: i.Element.Element
                }));
                setElementData(arrElement);
              });

              // console.log('reqData', reqData);
              if (reqData !== '') {
                const fields = ['DetectionNum', 'CommissionDate', 'DetectionMethodID', 'SampleStaffID', 'DetectStaffID',
                  'ElementID', 'Value', 'DetectionWeight', 'Volume', 'Recheck', 'SampleID', 'ReportDate', 'Remark'];
                fields.forEach((field) => {
                  switch (field) {
                    case 'CommissionDate':
                      setFieldValue(field, moment(reqData.CommissionDate).format('YYYY-MM-DD'), false);
                      break;
                    case 'DetectionMethodID':
                      setFieldValue(field, reqData.DetectionMethod.DetectionMethodID, false);
                      break;
                    case 'SampleStaffID':
                      setFieldValue(field, reqData.DetectionItems[0].DetectionSamples[0].SampleStaff.StaffID, false);
                      break;
                    case 'DetectStaffID':
                      setFieldValue(field, reqData.DetectStaff.StaffID, false);
                      break;
                    case 'ElementID':
                      setFieldValue(field, reqData.DetectionItems[0].DetectionSamples[0].DetectionElements[0].Element.ElementID, false);
                      break;
                    case 'Value':
                      setFieldValue(field, reqData.DetectionItems[0].DetectionSamples[0].DetectionElements[0].Value, false);
                      break;
                    case 'DetectionWeight':
                      setFieldValue(field, reqData.DetectionItems[0].DetectionSamples[0].DetectionElements[0].DetectionWeight, false);
                      break;
                    case 'Volume':
                      setFieldValue(field, reqData.DetectionItems[0].DetectionSamples[0].DetectionElements[0].Volume, false);
                      break;
                    case 'SampleID':
                      setFieldValue(field, reqData.DetectionItems[0].DetectionSamples[0].SampleID, false);
                      break;
                    case 'ReportDate':
                      if (reqData.ReportDate) {
                        setFieldValue(field, moment(reqData.ReportDate).format('YYYY-MM-DD'), false);
                      }
                      break;
                    default:
                      setFieldValue(field, reqData[field], false);
                      break;
                  }
                });
                setOpenBackdrop(false);
              } else {
                setOpenBackdrop(false);
              }
            }, []);

            return (
              <form onSubmit={handleSubmit}>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                  open={openBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="DetectionNum"
                          label="檢測單號"
                          value={values.DetectionNum || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          error={Boolean(errors.CommissionDate)}
                          helperText={errors.CommissionDate}
                          fullWidth
                          name="CommissionDate"
                          label="委託日期"
                          value={values.CommissionDate}
                          onChange={handleChange}
                          variant="outlined"
                          type="date"
                          disabled={reqData !== ''}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectWrapper
                          name="DetectionMethodID"
                          label="檢測方式"
                          selectValue={values.DetectionMethodID || ''}
                          options={detectionMethod}
                          disabled={reqData !== ''}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectWrapper
                          name="SampleStaffID"
                          label="取樣人員"
                          selectValue={values.SampleStaffID || ''}
                          options={sampleStaffData}
                          disabled={reqData !== ''}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="DetectStaffID"
                          label="分析人員"
                          value={values.DetectStaffID || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectWrapper
                          name="ElementID"
                          label="元素"
                          selectValue={values.ElementID || ''}
                          options={elementData}
                          disabled={reqData !== ''}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="Value"
                          label="檢測值"
                          value={values.Value || '0'}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="DetectionWeight"
                          label="取樣重量"
                          value={values.DetectionWeight || '0'}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="Volume"
                          label="定量"
                          value={values.Volume || '0'}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="Recheck"
                          label="複驗"
                          value={values.Recheck === '1' ? '是' : '否' || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="SampleID"
                          label="樣品編號"
                          value={values.SampleID || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <TextField
                          fullWidth
                          name="ReportDate"
                          label="檢驗日期"
                          value={values.ReportDate || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="Remark"
                          label="備註"
                          value={values.Remark || ''}
                          onChange={handleChange}
                          variant="outlined"
                          disabled={reqData !== ''}
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    {reqData.DetectionID === undefined
                      ? (
                        <Button
                          type="submit"
                          disabled={isSubmitting || isValidating}
                          color="primary"
                          variant="contained"
                        >
                          儲存
                        </Button>
                      )
                      : (
                        <div>
                          <Button
                            color="primary"
                            variant="contained"
                            onClick={() => handleLabel(reqData.DetectionID)}
                          >
                            條碼列印
                          </Button>
                          <Button
                            type="button"
                            color="primary"
                            variant="contained"
                            onClick={() => handleRecheck()}
                          >
                            複驗
                          </Button>
                        </div>
                      )}
                    <Button onClick={() => handleCancelClick(false)} variant="contained">
                      Cancel
                    </Button>
                  </Box>
                  <Fade in={fade}>
                    {isError
                      ? (
                        <Alert severity="error">
                          <AlertTitle>操作失敗</AlertTitle>
                          {errMsg || '請連絡相關人員'}
                        </Alert>
                      )
                      : (
                        <Alert severity="success">
                          <AlertTitle>操作成功</AlertTitle>
                        </Alert>
                      )}
                  </Fade>
                </Card>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

DetectionsDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reqData: PropTypes.object,
  intoFactoryID: PropTypes.string,
  customerID: PropTypes.string,
  commodityID: PropTypes.string
};

export default DetectionsDialog;
