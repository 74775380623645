/* eslint-disable react/prop-types */
import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

const SelectWrapper = ({
  name,
  options,
  selectValue,
  index,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);

  const handleChange = (evt) => {
    const { value } = evt.target;
    setFieldValue(name, value);
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: true,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    value: selectValue
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  // console.log(`selectValue${name}`, selectValue);
  // console.log(`SelectWrapper_${name}`, options);

  return (
    <TextField {...configSelect}>
      {options && options.map((option) => {
        let optionKey = '';
        let optionvalue = '';
        switch (name) {
          case 'CategoryID': // 商品類別
            optionKey = option.CategoryID;
            optionvalue = option.Category;
            break;
          case 'ElementID': // 元素
          case `Elements[${index}].ElementID`:
          case `DetectionItems[${index}].ElementID`:
          case `KeyExperiments[${index}].ElementID`:
            optionKey = option.ElementID;
            optionvalue = option.Element;
            break;
          case 'CustomerID':
            optionKey = option.CustomerID;
            optionvalue = option.CustomerName;
            break;
          case 'DetectionTypeID': // 檢測類型
            optionKey = option.DetectionTypeID;
            optionvalue = option.DetectionType;
            break;
          case 'StateID': // 商品狀態
            optionKey = option.StateID;
            optionvalue = option.State;
            break;
          case 'Recipient':
            optionKey = option.SalesID;
            optionvalue = option.Sales;
            break;
          case 'DeliverStaffID':
          case 'BusinessStaffID':
          case 'ScaleStaffID':
          case 'SampleStaffID':
            optionKey = option.StaffID;
            optionvalue = option.Name;
            break;
          case 'MeasureTypeID': // 量測方法
            optionKey = option.MeasureTypeID;
            optionvalue = option.MeasureType;
            break;
          case 'DetectionMethodID': // 檢測方法
            optionKey = option.DetectionMethodID;
            optionvalue = option.DetectionMethod;
            break;
          case 'WarehouseID': // 倉儲位置
            optionKey = option.WarehouseID;
            optionvalue = option.Warehouse;
            break;
          case 'StatusID': // 入廠單狀態
            optionKey = option.StatusID;
            optionvalue = option.Status;
            break;
          case 'BillTypeID': // 發票聯式
            optionKey = option.BillTypeID;
            optionvalue = option.BillType;
            break;
          case 'TaxTypeID': // 課稅別
            optionKey = option.TaxTypeID;
            optionvalue = option.TaxType;
            break;
          case 'PayMethodID':
            optionKey = option.PayMethodID;
            optionvalue = option.PayMethod;
            break;
          default:
            optionKey = option.IsTrueID;
            optionvalue = option.IsTrue;
            break;
        }
        return (
          <MenuItem key={optionKey} value={optionKey}>
            {optionvalue}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectWrapper;
