import React, {
  useCallback, useEffect, useState, useRef
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  Grid,
  TextField,
  Link
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik, ErrorMessage } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import SelectWrapper from '../formsUI/selectWrapper';
import { apiOpenService } from '../../api/openApi';
import { apiExperimentService } from '../../api/experimentApi';

function ExperimentForm() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = params.id == null;
  const initArrExperiment = useRef({
    DetectionSampleID: '',
    DetectionElementID: '',
    DetectionWeight: '',
    ElementID: '',
    Value: '',
    ValueConfirm: '',
    Volume: '',
    Remark: '',
    StateID: ''
  });
  const [experimentSelect, setExperimentSelect] = useState({
    elementState: []
  });
  const [experimentData, setExperimentData] = useState([]);

  const schema = Yup.object().shape({
    KeyExperiments: Yup.array().of(
      Yup.object().shape({
        Value: Yup.number().required('檢測值必填'),
        ValueConfirm: Yup.number().required('確認檢測值必填').oneOf([Yup.ref('Value')], '檢測值需相同'),
        Volume: Yup.number().when('StateID', {
          is: (val) => val === 1,
          then: Yup.number().required('定量必填')
        }),
        DetectionWeight: Yup.number().when('StateID', {
          is: (val) => val === 1,
          then: Yup.number().required('取樣重量必填')
        })
      })
    )
  });

  function saveStaff(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    apiExperimentService.apiExperimentAdd(fields)
      .then((res) => {
        // console.log('createStaff_OK', res);
        setFade(true);
        setTimeout(() => navigate('/experiment', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('createStaff_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  return (
    <Formik
      initialValues={{
        KeyExperiments: [{
          DetectionSampleID: '',
          DetectionElementID: '',
          DetectionWeight: '',
          Value: '',
          ValueConfirm: '',
          Volume: '',
          Remark: '',
          StateID: '',
          SampleID: '',
          ElementID: '',
          DetectionMethod: '',
          DetectionID: params.id
        }]
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveStaff}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        isValidating,
        setFieldValue
      }) => {
        const fetchData = useCallback(() => {
          const fetchingData = async () => {
            // 取得所有下拉選單
            const [elementState] = await Promise.all([
              apiOpenService.apiGetSelectData('Elements') // 元素
            ]);

            // 載入所有下拉選單
            setExperimentSelect({
              elementState
            });

            // 取得實驗室資料
            if (!isAddMode) {
              await apiExperimentService.apiGetExperimentByID(params.id).then((res) => {
                // console.log('res', res);
                if (res.data.ExperimentAllInfos) {
                  for (let i = 1; i <= res.data.ExperimentAllInfos.length; i++) {
                    const arrExperiment = experimentData;
                    arrExperiment.push(initArrExperiment.current);
                    setExperimentData([...arrExperiment]);
                  }
                  const fields = ['DetectionSampleID', 'DetectionElementID', 'DetectionMethod', 'DetectionWeight', 'Value', 'Volume', 'Remark', 'StateID', 'SampleID', 'ElementID', 'DetectionID'];
                  res.data.ExperimentAllInfos.forEach((val, i) => {
                    fields.forEach((field) => {
                      switch (field) {
                        case 'StateID':
                          setFieldValue(`KeyExperiments[${i}][${field}]`, val.CommodityState[field], false);
                          break;
                        case 'ElementID':
                          setFieldValue(`KeyExperiments[${i}][${field}]`, val.Element[field], false);
                          break;
                        default:
                          setFieldValue(`KeyExperiments[${i}][${field}]`, val[field], false);
                          break;
                      }
                    });
                  });
                }
                setOpenBackdrop(false);
              });
            }
          };
          fetchingData();
        }, []);
        useEffect(() => {
          setOpenBackdrop(true);
          fetchData();
          // 清空暫存
          return () => {
            initArrExperiment.current = null;
          };
        }, [fetchData]);

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title="實驗室檢測值輸入"
              />
              <CardContent>
                <Box>
                  {experimentData && experimentData.map((item, i) => (
                    <Grid container spacing={3} mb={3} key={item.DetectionSampleID}>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="樣品編號"
                          name={`KeyExperiments[${i}].SampleID`}
                          value={values.KeyExperiments[i]?.SampleID || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectWrapper
                          name={`KeyExperiments[${i}].ElementID`}
                          label="元素"
                          options={experimentSelect.elementState}
                          index={i}
                          selectValue={values.KeyExperiments[i]?.ElementID || ''}
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="檢測方式"
                          name={`KeyExperiments[${i}].DetectionMethod`}
                          value={values.KeyExperiments[i]?.DetectionMethod || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.KeyExperiments && errors.KeyExperiments[i]?.Value)}
                          helperText={errors.KeyExperiments && errors.KeyExperiments[i]?.Value}
                          fullWidth
                          label="檢測值PPM"
                          name={`KeyExperiments[${i}].Value`}
                          value={values.KeyExperiments[i]?.Value || ''}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.KeyExperiments && errors.KeyExperiments[i]?.ValueConfirm)}
                          helperText={errors.KeyExperiments && errors.KeyExperiments[i]?.ValueConfirm}
                          fullWidth
                          label="確認檢測值PPM"
                          name={`KeyExperiments[${i}].ValueConfirm`}
                          value={values.KeyExperiments[i]?.ValueConfirm || ''}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        {values.KeyExperiments[i]?.StateID === 1
                          ? (
                            <Grid container spacing={3} mb={3}>
                              <Grid item xs={6}>
                                <TextField
                                  error={Boolean(errors.KeyExperiments && errors.KeyExperiments[i]?.DetectionWeight)}
                                  helperText={errors.KeyExperiments && errors.KeyExperiments[i]?.DetectionWeight}
                                  fullWidth
                                  label="取樣重量"
                                  name={`KeyExperiments[${i}].DetectionWeight`}
                                  value={values.KeyExperiments[i]?.DetectionWeight || ''}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                              <Grid item xs={6}>
                                <TextField
                                  error={Boolean(errors.KeyExperiments && errors.KeyExperiments[i]?.Volume)}
                                  helperText={errors.KeyExperiments && errors.KeyExperiments[i]?.Volume}
                                  fullWidth
                                  label="定量"
                                  name={`KeyExperiments[${i}].Volume`}
                                  value={values.KeyExperiments[i]?.Volume || ''}
                                  onChange={handleChange}
                                  variant="outlined"
                                />
                              </Grid>
                            </Grid>
                          ) : null}
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="備註"
                          name={`KeyExperiments[${i}].Remark`}
                          value={values.KeyExperiments[i]?.Remark || ''}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  ))}
                </Box>

              </CardContent>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  color="primary"
                  variant="contained"
                >
                  儲存
                </Button>
                <Link component={RouterLink} to="/experiment">
                  <Button color="secondary" variant="contained">
                    取消
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default ExperimentForm;
