import React, {
  useCallback, useEffect, useState, useRef
} from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Link,
  Grid,
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiCommodityService } from '../../api/commodityApi';
import { apiOpenService } from '../../api/openApi';
import SelectWrapper from '../formsUI/selectWrapper';
import MetalElement from './metalElement';

function CommodityForm() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = params.id == null;
  const isCopy = params.key === 'copy';
  const initArrCommodity = useRef();
  const isTrueState = [
    {
      IsTrueID: '1',
      IsTrue: '是',
    },
    {
      IsTrueID: '0',
      IsTrue: '否',
    },
  ];
  const [commodityPage, setCommodityPage] = useState({
    categoryState: [],
    customerState: [],
    detectionTypeState: [],
    commodityState: [],
  });
  const [elementState, setelementState] = useState([]);

  const schema = Yup.object().shape({
    CategoryID: Yup.string().required('選擇商品分類'),
    ItemName: Yup.string().required('品名必填'),
    CustomerID: Yup.string().required('請選擇客戶'),
    DetectionTypeID: Yup.string().required('請選擇檢測類型'),
    StateID: Yup.string().required('請選擇型態'),
    Disable: Yup.string().required('請選擇停用'),
    Remark: Yup.string(),
    Elements: Yup.array().of(
      Yup.object().shape({
        ElementID: Yup.number().required('請選擇元素'),
      })
    )
  });

  function createCommodity(fields, setSubmitting) {
    apiCommodityService.apiCommodityAdd(fields)
      .then((res) => {
        // console.log('createCommodity_OK', res);
        setFade(true);
        setTimeout(() => navigate('/commodity', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('createCommodity_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateCommodity(parmaid, fields, setSubmitting) {
    apiCommodityService.apiCommodityUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateCommodity_OK', res);
        setFade(true);
        setTimeout(() => navigate('/commodity', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('updateCommodity_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function saveCommodity(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (isAddMode || isCopy) {
      createCommodity(fields, setSubmitting);
    } else {
      updateCommodity(params.id, fields, setSubmitting);
    }
  }

  function onClickAddElement() {
    const arrElement = elementState;
    arrElement.push(initArrCommodity.current);
    setelementState([...arrElement]);
    // const arrElement = initArrCommodity.current;
    // push(arrElement);
  }

  return (
    <Formik
      initialValues={{
        CategoryID: '',
        ItemName: '',
        CustomerID: '',
        DetectionTypeID: '',
        StateID: '',
        Disable: '0',
        Remark: '',
        Elements: [],
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveCommodity}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        setValues,
        isValidating,
        setFieldValue
      }) => {
        const fetchData = useCallback(() => {
          const fetchingData = async () => {
            // 取得所有下拉選單
            const [initElement, categoryState, customerState, detectionTypeState, commodityState] = await Promise.all([
              apiOpenService.apiGetSelectData('Elements'), // 元素
              apiOpenService.apiGetSelectData('CommodityCategorys'), // 商品分類
              apiOpenService.apiGetSelectCustomerData(), // 客戶
              apiOpenService.apiGetSelectData('DetectionTypes'), // 檢測類型
              apiOpenService.apiGetSelectData('CommodityStates'), // 型態
            ]);

            // 初始化元素
            initArrCommodity.current = {
              ElementID: initElement,
              Warningvalue: '',
              Precious: isTrueState,
              WarningOn: isTrueState
            };

            // 載入所有下拉選單
            setCommodityPage({
              categoryState,
              customerState,
              detectionTypeState,
              commodityState,
            });
            setelementState([initArrCommodity.current]);

            // 編輯模式
            if (!isAddMode) {
              await apiCommodityService.apiSearchCommodity(params.id, '').then((res) => {
                if (res.data[0].CommodityElements) {
                  for (let i = 1; i <= res.data[0].CommodityElements.length; i++) {
                    onClickAddElement(values, setValues); // 增加元素項目
                  }
                }
                const fields = ['CommodityCategory', 'ItemName', 'CustomerInfo', 'DetectionType', 'Commoditystate', 'CommodityElements', 'Remark'];
                if (!isCopy) {
                  fields.push('ItemID');
                }
                // console.log('res', res);
                // 取得資料架構與新增更新不同，重新塞值
                fields.forEach((field) => {
                  switch (field) {
                    case 'CommodityCategory':
                      setFieldValue('CategoryID', res.data[0].CommodityCategory.CategoryID, false);
                      break;
                    case 'CustomerInfo':
                      setFieldValue('CustomerID', res.data[0].CustomerInfo.CustomerID, false);
                      break;
                    case 'DetectionType':
                      setFieldValue('DetectionTypeID', res.data[0].DetectionType.DetectionTypeID, false);
                      break;
                    case 'Commoditystate':
                      setFieldValue('StateID', res.data[0].CommodityState.StateID, false);
                      break;
                    case 'CommodityElements': {
                      const arr = [];
                      if (res.data[0].CommodityElements) {
                        res.data[0].CommodityElements.forEach((val) => {
                          arr.push({
                            ElementID: val.Element.ElementID,
                            Warningvalue: val.WarningValue,
                            Precious: val.Precious,
                            WarningOn: val.WarningOn
                          });
                        });
                      }
                      setFieldValue('Elements', arr, false);
                      break;
                    }
                    default:
                      setFieldValue(field, res.data[0][field], false);
                      break;
                  }
                });
              });
            }
            setOpenBackdrop(false);
          };
          fetchingData();
        }, []);

        useEffect(() => {
          setOpenBackdrop(true);
          fetchData();
          // 清空暫存
          return () => {
            initArrCommodity.current = null;
          };
        }, [fetchData]);

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title={isAddMode ? '新增商品' : '編輯商品'}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="CategoryID"
                      label="商品分類"
                      selectValue={values.CategoryID || ''}
                      options={commodityPage.categoryState}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.ItemName)}
                      helperText={errors.ItemName}
                      fullWidth
                      label="品名"
                      name="ItemName"
                      value={values.ItemName || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      disabled
                      name="ItemID"
                      label="品號"
                      value={values.ItemID || ''}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="CustomerID"
                      label="客戶"
                      selectValue={values.CustomerID || ''}
                      options={commodityPage.customerState}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="DetectionTypeID"
                      label="檢測類型"
                      selectValue={values.DetectionTypeID || ''}
                      options={commodityPage.detectionTypeState}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="StateID"
                      label="型態"
                      selectValue={values.StateID || ''}
                      options={commodityPage.commodityState}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="Disable"
                      label="停用"
                      selectValue={values.Disable || ''}
                      options={isTrueState}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="備註"
                      name="Remark"
                      margin="normal"
                      value={values.Remark || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              <CardContent>
                { values.DetectionTypeID === 3 ? (
                  <Box mb={2}>
                    <Button variant="contained" color="primary" onClick={() => onClickAddElement(values, setValues)}>
                      新增元素
                    </Button>
                  </Box>
                ) : '' }
                <Box>
                  {
                    elementState.map((item, i) => (
                      <MetalElement
                        // eslint-disable-next-line react/no-array-index-key
                        key={i}
                        index={i}
                        data={item}
                        change={handleChange}
                        isWarning={values.DetectionTypeID === 2}
                        isExpensive={values.DetectionTypeID === 1}
                        selectValue={values.Elements[i] || ''}
                        errors={errors && errors.Elements}
                      />
                    ))
                  }
                </Box>
              </CardContent>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2,
                }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  color="primary"
                  variant="contained"
                >
                  儲存
                </Button>
                <Link component={RouterLink} to="/commodity">
                  <Button color="secondary" variant="contained">
                    Cancel
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default CommodityForm;
