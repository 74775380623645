import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, CardContent, DialogTitle, Dialog, DialogContent, Grid, TextField
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import { apiOpenService } from '../../api/openApi';
import SelectWrapper from '../formsUI/selectWrapper';

function MeasureDialog(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    onClose, open, reqData, intoFactoryID, commodityID
  } = props;
  const [measureData, setMeasureData] = useState([]);
  const [scaleData, setScaleData] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    MeasureTypeID: Yup.string().required('請選擇種類'),
    ScaleStaffID: Yup.string().required('秤重人員必填'),
    Weight: Yup.number().required('重量必填'),
  });

  function createMeasure(fields, setSubmitting) {
    apiIntoFactoryService.apiMeasureAdd(intoFactoryID, commodityID, fields)
      .then((res) => {
        // console.log('createMeasure_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('createMeasure_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateMeasure(measureID, fields, setSubmitting) {
    apiIntoFactoryService.apiIntoMeasureUpdate(intoFactoryID, commodityID, measureID, fields)
      .then((res) => {
        // console.log('updateIntoFactory_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('updateIntoFactory_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleCancelClick(value) {
    // console.log('handleCancelClick', value);
    onClose(value);
  }

  function saveMeasure(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (reqData.MeasureID === undefined) {
      createMeasure(fields, setSubmitting);
    } else {
      updateMeasure(reqData.MeasureID, fields, setSubmitting);
    }
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="xl" open={open}>
      <DialogTitle id="simple-dialog-title">新增量測</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            MeasureTypeID: '',
            ScaleStaffID: '',
            Weight: '',
            Remark: ''
          }}
          validationSchema={schema}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={saveMeasure}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            setFieldValue
          }) => {
            useEffect(async () => {
              // console.log('MeasureDialog_useEffect');
              setOpenBackdrop(true);
              await apiOpenService.apiGetSelectData('MeasureTypes').then((res) => setMeasureData(res));
              await apiOpenService.apiGetSelectStaffData('DepartmentID=5').then((res) => setScaleData(res));

              if (reqData !== '') {
                const fields = ['MeasureTypeID', 'ScaleStaffID', 'Weight', 'Remark'];
                fields.forEach((field) => {
                  switch (field) {
                    case 'MeasureTypeID':
                      setFieldValue(field, reqData.MeasureType.MeasureTypeID, false);
                      break;
                    case 'ScaleStaffID':
                      setFieldValue(field, reqData.Staff.StaffID, false);
                      break;
                    default:
                      setFieldValue(field, reqData[field], false);
                      break;
                  }
                });
                setOpenBackdrop(false);
              } else {
                setOpenBackdrop(false);
              }
            }, []);

            return (
              <form onSubmit={handleSubmit}>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                  open={openBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <SelectWrapper
                          name="MeasureTypeID"
                          label="量測種類"
                          selectValue={values.MeasureTypeID || ''}
                          options={measureData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectWrapper
                          name="ScaleStaffID"
                          label="秤重人員"
                          selectValue={values.ScaleStaffID || ''}
                          options={scaleData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.Weight)}
                          helperText={errors.Weight}
                          fullWidth
                          label="重量"
                          name="Weight"
                          value={values.Weight || ''}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="備註"
                          name="Remark"
                          margin="normal"
                          value={values.Remark || ''}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting || isValidating}
                      color="primary"
                      variant="contained"
                    >
                      儲存
                    </Button>
                    <Button onClick={() => handleCancelClick(false)} variant="contained">
                      Cancel
                    </Button>
                  </Box>
                  <Fade in={fade}>
                    {isError
                      ? (
                        <Alert severity="error">
                          <AlertTitle>操作失敗</AlertTitle>
                          {errMsg || '請連絡相關人員'}
                        </Alert>
                      )
                      : (
                        <Alert severity="success">
                          <AlertTitle>操作成功</AlertTitle>
                        </Alert>
                      )}
                  </Fade>
                </Card>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

MeasureDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reqData: PropTypes.object,
  intoFactoryID: PropTypes.string,
  commodityID: PropTypes.string
};

export default MeasureDialog;
