import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, CardContent, DialogTitle, Dialog, DialogContent, Grid, TextField
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import { apiOpenService } from '../../api/openApi';
import SelectWrapper from '../formsUI/selectWrapper';

function StatusFormDialog(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    onClose, open, reqData, intoFactoryID, commodityID
  } = props;
  // console.log('reqData', reqData);
  const [statusFormSelect, setStatusFormSelect] = useState({
    wareHouseData: [],
    statusTypeData: [],
    deliverStaffData: []
  });
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    DeliverTime: Yup.date().required('日期必填'),
    WarehouseID: Yup.date().required('請選擇倉別'),
    StatusID: Yup.string().required('請選擇狀態'),
    DeliverStaffID: Yup.string().required('請選擇送貨人員')
  });

  function createStatusForm(fields, setSubmitting) {
    apiIntoFactoryService.apiStatusFormAdd(fields)
      .then((res) => {
        // console.log('createStatusForm_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('createStatusForm_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateStatusForm(StatusformID, fields, setSubmitting) {
    apiIntoFactoryService.apiIntoStatusUpdate(intoFactoryID, commodityID, StatusformID, fields)
      .then((res) => {
        // console.log('updateIntoFactory_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('updateIntoFactory_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleListItemClick(value) {
    // console.log('handleListItemClick', value);
    onClose(value);
  }

  function saveStatusForm(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (reqData.StatusformID === undefined) {
      createStatusForm(fields, setSubmitting);
    } else {
      updateStatusForm(reqData.StatusformID, fields, setSubmitting);
    }
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="xl" open={open}>
      <DialogTitle id="simple-dialog-title">新增軌跡</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            IntofactoryID: intoFactoryID,
            CommodityID: commodityID,
            StatusformID: '',
            WarehouseID: '',
            StatusID: '',
            DeliverStaffID: '',
            DeliverTime: moment().format('YYYY-MM-DDTHH:mm'),
            Remark: '',
          }}
          validationSchema={schema}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={saveStatusForm}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            setFieldValue
          }) => {
            const fetchData = useCallback(() => {
              const fetchingData = async () => {
                const [wareHouseData, statusTypeData, deliverStaffData] = await Promise.all([
                  apiOpenService.apiGetSelectData('WarehouseTypes'),
                  apiOpenService.apiGetSelectData('StatusTypes'),
                  apiOpenService.apiGetSelectStaffData('DepartmentID=5')
                ]);

                setStatusFormSelect({
                  wareHouseData,
                  statusTypeData,
                  deliverStaffData
                });

                // 編輯模式
                if (reqData.StatusformID !== undefined) {
                  // 列表用篩選取值過來
                  const fields = ['StatusformID', 'WarehouseID', 'StatusID', 'DeliverStaffID', 'DeliverTime', 'Remark'];

                  // 取得資料架構與新增更新不同，重新塞值
                  fields.forEach((field) => {
                    switch (field) {
                      case 'DeliverStaffID':
                        setFieldValue(field, reqData.DeliverStaff.StaffID, false);
                        break;
                      case 'DeliverTime':
                        setFieldValue(field, moment.parseZone(reqData.DeliverTime).format('YYYY-MM-DDTHH:mm'), false);
                        break;
                      case 'WarehouseID':
                        setFieldValue(field, reqData.WarehouseType.WarehouseID, false);
                        break;
                      case 'StatusID':
                        setFieldValue(field, reqData.StatusType.StatusID, false);
                        break;
                      default:
                        setFieldValue(field, reqData[field], false);
                        break;
                    }
                  });
                }
                setOpenBackdrop(false);
              };
              fetchingData();
            }, []);

            useEffect(async () => {
              setOpenBackdrop(true);
              fetchData();
            }, [fetchData]);

            return (
              <form onSubmit={handleSubmit}>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                  open={openBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={3}>
                        <TextField
                          error={Boolean(errors.DeliverTime)}
                          helperText={errors.DeliverTime}
                          fullWidth
                          name="DeliverTime"
                          label="時間"
                          value={values.DeliverTime}
                          onChange={handleChange}
                          variant="outlined"
                          type="datetime-local"
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectWrapper
                          name="WarehouseID"
                          label="倉別"
                          selectValue={values.WarehouseID || ''}
                          options={statusFormSelect.wareHouseData}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectWrapper
                          name="StatusID"
                          label="狀態"
                          selectValue={values.StatusID || ''}
                          options={statusFormSelect.statusTypeData}
                        />
                      </Grid>
                      <Grid item xs={3}>
                        <SelectWrapper
                          name="DeliverStaffID"
                          label="送貨人員"
                          selectValue={values.DeliverStaffID || ''}
                          options={statusFormSelect.deliverStaffData}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          name="Remark"
                          label="備註"
                          onChange={handleChange}
                          value={values.Remark || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting || isValidating}
                      color="primary"
                      variant="contained"
                    >
                      儲存
                    </Button>
                    <Button onClick={() => handleListItemClick(false)} variant="contained">
                      Cancel
                    </Button>
                  </Box>
                  <Fade in={fade}>
                    {isError
                      ? (
                        <Alert severity="error">
                          <AlertTitle>操作失敗</AlertTitle>
                          {errMsg || '請連絡相關人員'}
                        </Alert>
                      )
                      : (
                        <Alert severity="success">
                          <AlertTitle>操作成功</AlertTitle>
                        </Alert>
                      )}
                  </Fade>
                </Card>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

StatusFormDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reqData: PropTypes.object,
  intoFactoryID: PropTypes.string,
  commodityID: PropTypes.string
};

export default StatusFormDialog;
