import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Link,
  Grid
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiCustomerService } from '../../api/customerApi';
import { apiOpenService } from '../../api/openApi';
import SelectWrapper from '../formsUI/selectWrapper';

function CustomerForm() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = params.id == null;
  const isCopy = params.key === 'copy';
  const [salesData, setSalesData] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    CustomerKey: Yup.string().required('客戶代號必填').matches(/^[a-zA-Z]{2}\d{4}$/, '格式錯誤，需開頭2位英文+4位數字'),
    CustomerN: Yup.string().required('客戶簡稱必填'),
    CustomerName: Yup.string().required('客戶全名必填'),
    BusinessStaffID: Yup.string().required('選擇業務'),
    ContactName: Yup.string().required('聯絡人必填'),
    ContactPosition: Yup.string().required('聯絡人職稱必填'),
    Tel: Yup.string().required('電話/分機必填'),
    Phone: Yup.string().required('手機必填'),
    Fax: Yup.string().required('傳真必填'),
    Vat: Yup.string().required('統一編號必填'),
    Email: Yup.string().email('信箱格式錯誤').required('E-Mail必填'),
    Agent: Yup.string().required('代理人員必填'),
    AgentPosition: Yup.string().required('代理人職稱必填'),
    Address: Yup.string().required('地址必填'),
    Remark: Yup.string()
  });

  function createCustomer(fields, setSubmitting) {
    apiCustomerService.apiCustomerAdd(fields)
      .then((res) => {
        // console.log('createCustomer_OK', res);
        setFade(true);
        setTimeout(() => navigate('/customer', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('createCustomer_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateCustomer(parmaid, fields, setSubmitting) {
    apiCustomerService.apiCustomerUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateCustomer_OK', res);
        setFade(true);
        setTimeout(() => navigate('/customer', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('updateCustomer_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function saveCustomer(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (isAddMode || isCopy) {
      createCustomer(fields, setSubmitting);
    } else {
      updateCustomer(params.id, fields, setSubmitting);
    }
  }

  return (
    <Formik
      initialValues={{
        CustomerKey: '',
        CustomerN: '',
        CustomerName: '',
        ContactName: '',
        ContactPosition: '',
        Tel: '',
        Phone: '',
        Fax: '',
        Vat: '',
        Email: '',
        Agent: '',
        AgentPosition: '',
        Address: '',
        Remark: '',
        BusinessStaffID: ''
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveCustomer}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        isValidating,
        setFieldValue
      }) => {
        useEffect(() => {
          setOpenBackdrop(true);
          // console.log('isAddMode', isAddMode);
          // 取得業務選單
          apiOpenService.apiGetSelectStaffData('DepartmentID=3').then((res) => {
            setSalesData(res);
            if (isAddMode) setOpenBackdrop(false);
          });
          if (!isAddMode) {
            apiCustomerService.apiSearchCustomer(params.id, '').then((res) => {
              let fields;
              if (isCopy) {
                fields = ['CustomerN', 'CustomerName', 'ContactName', 'ContactPosition', 'Tel', 'Phone', 'Fax', 'Vat', 'Email', 'Agent', 'AgentPosition', 'Address', 'Remark', 'BusinessStaffID'];
              } else {
                fields = ['CustomerKey', 'CustomerN', 'CustomerName', 'ContactName', 'ContactPosition', 'Tel', 'Phone', 'Fax', 'Vat', 'Email', 'Agent', 'AgentPosition', 'Address', 'Remark', 'BusinessStaffID'];
              }
              fields.forEach((field) => {
                if (field === 'BusinessStaffID') {
                  setFieldValue(field, res.data[0].BusinessStaff.StaffID, false);
                } else {
                  setFieldValue(field, res.data[0][field], false);
                }
              });
              setOpenBackdrop(false);
            });
          }
        }, []);

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title={(isAddMode || isCopy) ? '新增客戶' : '編輯客戶'}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.CustomerKey)}
                      helperText={errors.CustomerKey}
                      fullWidth
                      label="客戶代號"
                      name="CustomerKey"
                      value={values.CustomerKey || ''}
                      onChange={handleChange}
                      variant="outlined"
                      disabled={!(isAddMode || isCopy)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.CustomerN)}
                      helperText={errors.CustomerN}
                      fullWidth
                      label="客戶簡稱"
                      name="CustomerN"
                      value={values.CustomerN || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.CustomerName)}
                      helperText={errors.CustomerName}
                      fullWidth
                      label="客戶全名"
                      name="CustomerName"
                      value={values.CustomerName || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="BusinessStaffID"
                      label="業務"
                      selectValue={values.BusinessStaffID || ''}
                      options={salesData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.Tel)}
                      helperText={errors.Tel}
                      fullWidth
                      label="電話/分機"
                      name="Tel"
                      value={values.Tel || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.Fax)}
                      helperText={errors.Fax}
                      fullWidth
                      label="傳真"
                      name="Fax"
                      value={values.Fax || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.Vat)}
                      helperText={errors.Vat}
                      fullWidth
                      label="統一編號"
                      name="Vat"
                      value={values.Vat || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.ContactName)}
                      helperText={errors.ContactName}
                      fullWidth
                      label="聯絡人"
                      name="ContactName"
                      value={values.ContactName || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.ContactPosition)}
                      helperText={errors.ContactPosition}
                      fullWidth
                      label="聯絡人職稱"
                      name="ContactPosition"
                      value={values.ContactPosition || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.Phone)}
                      helperText={errors.Phone}
                      fullWidth
                      label="手機"
                      name="Phone"
                      value={values.Phone || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.Email)}
                      helperText={errors.Email}
                      fullWidth
                      label="E-Mail"
                      name="Email"
                      value={values.Email || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.Agent)}
                      helperText={errors.Agent}
                      fullWidth
                      label="代理人員"
                      name="Agent"
                      value={values.Agent || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.AgentPosition)}
                      helperText={errors.AgentPosition}
                      fullWidth
                      label="代理人職稱"
                      name="AgentPosition"
                      value={values.AgentPosition || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      error={Boolean(errors.Address)}
                      helperText={errors.Address}
                      fullWidth
                      label="地址"
                      name="Address"
                      margin="normal"
                      value={values.Address || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="備註"
                      name="Remark"
                      margin="normal"
                      value={values.Remark || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  color="primary"
                  variant="contained"
                >
                  儲存
                </Button>
                <Link component={RouterLink} to="/Customer">
                  <Button color="secondary" variant="contained">
                    取消
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default CustomerForm;
