import fetchWrapper from './fetch-wrapper';

const uploadIntoFactoryBase = (data, files, name) => {
  const body = {
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    File: Array.from(new Uint8Array(files)),
    FileName: name,
  };
  // console.log('body', body);
  return fetchWrapper.postupload('intofactory/uploadIntoFactoryBaseFile', body);
};

const uploadIntoFactoryItem = (data, files, name) => {
  // console.log('uploadIntoFactoryItem', files);
  const body = {
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    CommodityID: parseInt(data.CommodityID, 10),
    File: Array.from(new Uint8Array(files)),
    FileName: name,
  };
  // console.log('body', body);
  return fetchWrapper.postupload('intofactory/uploadItemFile', body);
};

const uploadDetection = (data, files, name) => {
  const body = {
    DetectionID: parseInt(data.DetectionID, 10),
    File: Array.from(new Uint8Array(files)),
    FileName: name,
  };
  // console.log('body', body);
  return fetchWrapper.postupload('detection/uploadDetectionFile', body);
};

function download(blob, filename) {
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.style.display = 'none';
  a.href = url;
  // the filename you want
  a.download = filename;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
  window.URL.revokeObjectURL(url);
}

const getFilesIntoFactoryBase = async (IntofactoryID, fileName) => {
  await fetchWrapper.getdownload(`intofactory/downloadIntoFactoryBaseFile?IntofactoryID=${IntofactoryID}&FileName=${fileName}`)
    .then((blob) => {
      download(blob, fileName);
    });
};

const getFilesIntoFactoryItem = async (IntofactoryID, CommodityID, fileName) => {
  await fetchWrapper.getdownload(`intofactory/downloadItemFile?IntofactoryID=${IntofactoryID}&CommodityID=${CommodityID}&FileName=${fileName}`)
    .then((blob) => {
      download(blob, fileName);
    });
};

const getFilesDetection = async (DetectionID, fileName) => {
  await fetchWrapper.getdownload(`detection/downloadDetectionFile?DetectionID=${DetectionID}&FileName=${fileName}`)
    .then((blob) => {
      download(blob, fileName);
    });
};

function delFilesIntoFactoryBase(IntofactoryID, fileName) {
  const body = {
    IntofactoryID: parseInt(IntofactoryID, 10),
    FileName: fileName
  };
  return fetchWrapper.delete('intofactory/deleteIntoFactoryBaseFile', body);
}

function delFilesIntoFactoryItem(IntofactoryID, CommodityID, fileName) {
  const body = {
    IntofactoryID: parseInt(IntofactoryID, 10),
    CommodityID: parseInt(CommodityID, 10),
    FileName: fileName
  };
  return fetchWrapper.delete('intofactory/deleteItemFile', body);
}

function delFilesDetection(DetectionID, fileName) {
  const body = {
    DetectionID: parseInt(DetectionID, 10),
    FileName: fileName
  };
  return fetchWrapper.delete('detection/deleteDetectionFile', body);
}

export default {
  uploadIntoFactoryBase,
  uploadIntoFactoryItem,
  uploadDetection,
  getFilesIntoFactoryBase,
  getFilesIntoFactoryItem,
  getFilesDetection,
  delFilesIntoFactoryBase,
  delFilesIntoFactoryItem,
  delFilesDetection
};
