/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import { Grid, TextField } from '@material-ui/core';
import React from 'react';
import SelectWrapper from '../formsUI/selectWrapper';

const MetalElement = (props) => {
  console.log('MetalElement_props');
  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={3}>
        <SelectWrapper
          name={`Elements[${props.index}].ElementID`}
          label="元素"
          options={props.data.ElementID}
          index={props.index}
          selectValue={props.selectValue.ElementID || ''}
        />
      </Grid>
      {props.isExpensive
        ? (
          <Grid item xs={3}>
            <SelectWrapper
              name={`Elements[${props.index}].Precious`}
              label="貴金屬"
              options={props.data.Precious}
              index={props.index}
              selectValue={props.selectValue.Precious || ''}
            />
          </Grid>
        ) : null}
      {props.isWarning
        ? (
          <>
            <Grid item xs={3}>
              <TextField
                fullWidth
                label="告警值"
                name={`Elements[${props.index}].Warningvalue`}
                value={props.selectValue.Warningvalue || ''}
                onChange={props.change}
                variant="outlined"
              />
            </Grid>
            <Grid item xs={3}>
              <SelectWrapper
                name={`Elements[${props.index}].WarningOn`}
                label="告警"
                options={props.data.WarningOn}
                index={props.index}
                selectValue={props.selectValue.WarningOn || ''}
              />
            </Grid>
          </>
        ) : null}
      {/* <Grid item xs={3}>
        <Button
          margin="normal"
          type="button"
          color="secondary"
          variant="outlined"
          onClick={() => props.remove(props.index)}
        >
          x
        </Button>
      </Grid> */}
    </Grid>
  );
};

export default MetalElement;
