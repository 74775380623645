// import {
//   IntoFactoryData, IntoFactoryitem, MeasureType, DetectionMethod, SampleStaff, Warehouse, StatusType
// } from '../__mocks__/intofactory';
// import { selectStaff } from '../__mocks__/staff';
import moment from 'moment';
import fetchWrapper from './fetch-wrapper';

/* 查詢功能start */
// 搜尋入廠單
function apiSearchIntoFactory(data) {
  // console.log('data', data);
  let strURL = '';
  strURL = strURL.concat('From=', data.From);
  strURL = strURL.concat('&To=', data.To);
  if (data.CustomerID !== '') {
    strURL = strURL.concat('&CustomerID=', data.CustomerID);
  }
  if (data.ItemName !== '') {
    strURL = strURL.concat('&ItemName=', data.ItemName);
  }
  // console.log('Customer_Search', strURL);
  return fetchWrapper.get(`intofactory/getIntoFactoryList?${strURL}`);
}

// 取得入廠單列表
function apiGetIntoFactoryList() {
  // console.log('GetIntoFactoryList');
  return fetchWrapper.get('intofactory/getIntoFactoryList?Page=1&Pagesize=500');
}

// 取得入廠單-品項下完整資料
function apiGetIntoFactoryItem(IntofactoryID, CommodityID) {
  // console.log('getIntoFactoryCommodityByID');
  return fetchWrapper.get(`intofactory/getIntoFactoryCommodityByID?IntofactoryID=${IntofactoryID}&CommodityID=${CommodityID}`);
}

// 取得入廠單資訊for報表
function apiGetIntoFactoryBaseNumList(data) {
  // console.log('GetIntoFactoryBaseNumList');
  return fetchWrapper.get(`intofactory/getIntoFactoryBaseNumList?CustomerID=${data}`);
}

/* 查詢功能end */

/* 新增功能start */
// 新增入廠單基本資料
function apiIntoFactoryAdd(data) {
  // console.log('ADD', data);
  return fetchWrapper.post('intofactory/createIntoFactoryBase', data);
}

// 新增品項
function apiIntoFactoryitemAdd(data) {
  const body = {
    ...data,
    Gram: parseFloat(data.Gram),
    RealWeight: parseFloat(data.RealWeight),
    Weight: parseFloat(data.Weight)
  };
  // console.log('IntoFactoryItem_ADD', body);
  return fetchWrapper.post('intofactory/createItem', body);
}

// 複製商品資料
function apiGetIntoFactoryCommodityByID(id) {
  const body = {
    IntofactoryID: parseInt(id, 10)
  };
  // console.log('GetIntoFactoryitemById', body);
  return fetchWrapper.post('intofactory/addCopyIntoFactoryBaseItems', body);
}

// 新增量測
function apiMeasureAdd(intoFactoryID, commodityID, data) {
  const body = {
    ...data,
    IntofactoryID: parseInt(intoFactoryID, 10),
    CommodityID: parseInt(commodityID, 10),
    Weight: parseFloat(data.Weight)
  };
  // console.log('Measure_ADD', body);
  return fetchWrapper.post('intofactory/createMeasure', body);
}

// 新增軌跡
function apiStatusFormAdd(data) {
  const body = {
    ...data,
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    CommodityID: parseInt(data.CommodityID, 10),
    DeliverTime: moment(data.DeliverTime).format(),
  };
  // console.log('StatusForm_ADD', body);
  return fetchWrapper.post('intofactory/createStatus', body);
}

// 轉製程單
function apiProcessingAdd(data) {
  const body = {
    ...data,
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    CommodityID: parseInt(data.CommodityID, 10),
    DeliverTime: moment(data.DeliverTime).format(),
  };
  // console.log('Processing_ADD', body);
  return fetchWrapper.post('processing/create', body);
}

// 轉銷貨單
function apiSaleAdd(data) {
  const body = {
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    CommodityID: parseInt(data.CommodityID, 10)
  };
  // console.log('SaleAdd_ADD', body);
  return fetchWrapper.post('sale/returnSaleFromIntofactory', body);
}

// 轉報價單
function apiQutationAdd(data) {
  const body = {
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    CommodityID: parseInt(data.CommodityID, 10)
  };
  // console.log('Qutation_ADD', body);
  return fetchWrapper.post('qutation/returnQutationFromInfactory', body);
}

// 通知老闆報價
function apiWantQutation(data) {
  const body = {
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    CommodityID: parseInt(data.CommodityID, 10)
  };
  // console.log('WantQutation', body);
  return fetchWrapper.post('intofactory/customerWantQutation', body);
}

/* 新增功能end */

/* 更新功能start */
// 更新入廠單基本資料
function apiIntoFactoryUpdate(parmaid, data) {
  // const res = data && JSON.parse(data);
  // console.log('IntoFactory_Update', data);
  const body = {
    ...data,
    IntofactoryID: parmaid,
  };
  return fetchWrapper.put('intofactory/updateIntoFactoryBase', body);
}

// 更新入廠單品項資料
function apiIntoFactoryItemUpdate(data) {
  // const res = data && JSON.parse(data);
  const body = {
    ...data,
    Gram: parseFloat(data.Gram),
    Weight: parseFloat(data.Weight),
    RealWeight: parseFloat(data.RealWeight),
  };
  // console.log('IntoFactory_Update', body);
  return fetchWrapper.put('intofactory/updateItem', body);
}

// 更新量測
function apiIntoMeasureUpdate(intoFactoryID, commodityID, measureID, data) {
  // const res = data && JSON.parse(data);
  const body = {
    ...data,
    IntofactoryID: parseInt(intoFactoryID, 10),
    CommodityID: parseInt(commodityID, 10),
    Weight: parseFloat(data.Weight),
    MeasureID: measureID
  };
  // console.log('Measure_Update', body);
  return fetchWrapper.put('intofactory/updateMeasure', body);
}

// 更新軌跡
function apiIntoStatusUpdate(intoFactoryID, commodityID, statusformID, data) {
  // const res = data && JSON.parse(data);
  const body = {
    ...data,
    IntofactoryID: parseInt(intoFactoryID, 10),
    CommodityID: parseInt(commodityID, 10),
    StatusformID: statusformID,
    DeliverTime: moment(data.DeliverTime).format(),
  };
  // console.log('Status_Update', body);
  return fetchWrapper.put('intofactory/updateStatus', body);
}
/* 更新功能end */

/* 刪除功能 */
// 入廠單號
function apiIntoFactoryDelete(id) {
  const body = { IntofactoryID: id };
  return fetchWrapper.delete('intofactory/deleteIntoFactoryBaseInfo', body);
}

// 入廠品項單號
function apiIntoFactoryItemDelete(id) {
  const body = { IntofactoryItemID: id };
  return fetchWrapper.delete('intofactory/deleteItem', body);
}

// 量測
function apiMeasureDelete(id) {
  const body = { MeasureID: id };
  return fetchWrapper.delete('intofactory/deleteMeasure', body);
}

// 軌跡
function apiStatusformDelete(id) {
  const body = { StatusformID: id };
  return fetchWrapper.delete('intofactory/deleteStatus', body);
}

export const apiIntoFactoryService = {
  apiSearchIntoFactory,
  apiGetIntoFactoryList,
  apiGetIntoFactoryItem,
  apiGetIntoFactoryCommodityByID,
  apiGetIntoFactoryBaseNumList,
  apiIntoFactoryAdd,
  apiMeasureAdd,
  apiIntoFactoryitemAdd,
  apiStatusFormAdd,
  apiProcessingAdd,
  apiQutationAdd,
  apiSaleAdd,
  apiWantQutation,
  apiIntoFactoryUpdate,
  apiIntoFactoryItemUpdate,
  apiIntoMeasureUpdate,
  apiIntoStatusUpdate,
  apiIntoFactoryDelete,
  apiIntoFactoryItemDelete,
  apiMeasureDelete,
  apiStatusformDelete
};
