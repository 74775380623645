import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  Box,
  Divider,
  Drawer,
  Hidden,
  List,
  // Typography
} from '@material-ui/core';
import NavItem from './navItem';
/*
const user = {
  jobTitle: 'Senior Developer',
  name: 'Katarina Smith'
};
*/
/*
const items = [
  {
    href: '/staff',
    title: '員工資料'
  },
  {
    href: '/commodity',
    title: '商品'
  },
  {
    href: '/customer',
    title: '客戶'
  },
  {
    href: '/vender',
    title: '廠商'
  },
  {
    href: '/experiment',
    title: '實驗室'
  },
  {
    href: '/detection',
    title: '檢測單'
  },
  {
    href: '/intofactory',
    title: '入廠單'
  },
  {
    href: '/settings',
    title: 'Settings'
  },
  {
    href: '/login',
    title: 'Login'
  },
  {
    href: '/404',
    title: 'Error'
  }
];
*/
const DashboardSidebar = ({ onMobileClose, openMobile }) => {
  const location = useLocation();
  const [items, setItems] = useState([]);
  const navigate = useNavigate();
  const role = JSON.parse(sessionStorage.getItem('Staffme'));

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
    const arrRole = [];
    if (role) {
      if (role.Departments[0].DepartmentID === 7 && role.Departments[0].DepartmentID !== 1) {
        arrRole.push({
          href: '/experiment',
          title: '實驗室'
        });
      } else {
        if (role.Role.StaffSystem === '1') {
          arrRole.push({
            href: '/staff',
            title: '員工資料'
          });
        }
        if (role.Role.CustomerSystem === '1') {
          arrRole.push({
            href: '/customer',
            title: '客戶'
          });
        }
        if (role.Role.VenderSystem === '1') {
          arrRole.push({
            href: '/vender',
            title: '廠商'
          });
        }
        if (role.Role.CommoditySystem === '1') {
          arrRole.push({
            href: '/commodity',
            title: '商品'
          });
        }
        if (role.Role.IntofactorySystem === '1') {
          arrRole.push({
            href: '/intofactory',
            title: '入廠單'
          });
        }
        if (role.Role.DetectionSystem === '1' && role.Departments.DepartmentID !== 7) {
          arrRole.push({
            href: '/detection',
            title: '檢測單'
          });
        }
        if (role.Departments[0].DepartmentID === 1 || role.Departments[0].DepartmentID === 4) {
          arrRole.push({
            href: '/experiment',
            title: '實驗室'
          });
        }
        if (role.Role.QutationSystem === '1') {
          arrRole.push({
            href: '/qutation',
            title: '報價單'
          });
        }
        if (role.Role.SaleSystem === '1') {
          arrRole.push({
            href: '/sale',
            title: '銷貨單'
          });
        }
      }
      setItems(arrRole);
    } else {
      const session = sessionStorage.getItem('token');
      if (!session) {
        navigate('/login', { replace: true });
      }
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      {/* <Box
        sx={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'column',
          p: 2
        }}
      >
        <Typography
          color="textPrimary"
          variant="h5"
        >
          {user.name}
        </Typography>
        <Typography
          color="textSecondary"
          variant="body2"
        >
          {user.jobTitle}
        </Typography>
      </Box> */}
      <Divider />
      <Box sx={{ p: 2 }}>
        <List>
          {items.map((item) => (
            <NavItem
              href={item.href}
              key={item.title}
              title={item.title}
            />
          ))}
        </List>
      </Box>
    </Box>
  );

  return (
    <>
      <Hidden lgUp>
        <Drawer
          anchor="right"
          onClose={onMobileClose}
          open={openMobile}
          variant="temporary"
          PaperProps={{
            sx: {
              width: 256
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          anchor="left"
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: 256,
              top: 64,
              height: 'calc(100% - 64px)'
            }
          }}
        >
          {content}
        </Drawer>
      </Hidden>
    </>
  );
};

DashboardSidebar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

DashboardSidebar.defaultProps = {
  onMobileClose: () => { },
  openMobile: false
};

export default DashboardSidebar;
