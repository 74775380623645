import React, { useState } from 'react';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  InputAdornment,
  SvgIcon,
  Link
} from '@material-ui/core';
import { Search as SearchIcon } from 'react-feather';
import { Link as RouterLink } from 'react-router-dom';

const StaffListToolbar = (props) => {
  const [value, setValue] = useState('');
  // eslint-disable-next-line react/prop-types
  const { onClick } = props;
  return (
    <Box>
      <Box xs={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Link component={RouterLink} to="add">
          <Button color="primary" variant="contained">
            新增員工
          </Button>
        </Link>
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={8}>
                <TextField
                  fullWidth
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <SvgIcon fontSize="small" color="action">
                          <SearchIcon />
                        </SvgIcon>
                      </InputAdornment>
                    )
                  }}
                  placeholder="請填員工工號或姓名"
                  variant="outlined"
                  onChange={(e) => setValue(e.target.value)}
                  value={value}
                />
              </Grid>
              <Grid item xs={4} mt={1}>
                <Button onClick={() => onClick(value)} color="primary" variant="contained">
                  搜尋
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
export default StaffListToolbar;
