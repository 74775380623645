import moment from 'moment';
import fetchWrapper from './fetch-wrapper';

/* 查詢功能start */
// 搜尋銷貨單
function apiSearchSale(data) {
  let strURL = '';
  strURL = strURL.concat('From=', data.From);
  strURL = strURL.concat('&To=', data.To);
  if (data.VenderID !== '') {
    strURL = strURL.concat('&VenderID=', data.VenderID);
  }
  if (data.ItemName !== '') {
    strURL = strURL.concat('&ItemName=', data.ItemName);
  }
  // console.log('Sale_Search', strURL);
  return fetchWrapper.get(`sale/getSaleList?${strURL}`);
}

// 取得銷貨列表
function apiGetSaleList() {
  // console.log('Sale_GetList');
  return fetchWrapper.get('sale/getSaleList?Page=1&Pagesize=500');
}

// 取得銷貨單明細
function apiGetSaleDetail(SaleID) {
  return fetchWrapper.get(`sale/getSale?SaleID=${SaleID}`);
}

/* 查詢功能end */

/* 新增功能start */
// 新增銷貨單
function apiSaleAdd(data) {
  const body = {
    ...data,
    VenderID: parseInt(data.VenderID, 10),
    PayMethodID: parseInt(data.PayMethodID, 10),
    BillTypeID: parseInt(data.BillTypeID, 10),
    TaxTypeID: parseInt(data.TaxTypeID, 10),
    // IntofactoryID: parseInt(data.Intofactory.IntofactoryID, 10),
  };
  // console.log('SaleADD', body);
  return fetchWrapper.post('sale/createSale', body);
}

// 新增銷貨單品項
function apiSaleitemAdd(data) {
  const body = {
    ...data,
    SaleID: parseInt(data.SaleID, 10),
    CommodityID: parseInt(data.CommodityID.CommodityID, 10),
    RealWeight: parseFloat(data.RealWeight),
    Amount: parseFloat(data.Amount)
  };
  // console.log('SaleItem_ADD', body);
  return fetchWrapper.post('sale/createSaleItem', body);
}

/* 新增功能end */

/* 更新功能start */
// 更新銷貨單
function apiSaleUpdate(parmaid, data) {
  const body = {
    ...data,
    SaleID: parseInt(parmaid, 10),
    VenderID: parseInt(data.VenderID, 10),
    PayMethodID: parseInt(data.PayMethodID, 10),
    BillTypeID: parseInt(data.BillTypeID, 10),
    TaxTypeID: parseInt(data.TaxTypeID, 10),
    IntofactoryID: parseInt(data.Intofactory.IntofactoryID, 10)
  };
  // console.log('Sale_Update', body);
  return fetchWrapper.put('sale/updateSale', body);
}

// 更新銷貨單品項
function apiSaleItemUpdate(parmaid, data) {
  const body = {
    ...data,
    SaleItemID: parseInt(parmaid, 10),
    SaleID: parseInt(data.SaleID, 10),
    CommodityID: parseInt(data.CommodityID.CommodityID, 10),
    RealWeight: parseFloat(data.RealWeight),
    Amount: parseFloat(data.Amount)
  };
  // console.log('SaleItem_Update', body);
  return fetchWrapper.put('sale/updateSaleItem', body);
}

/* 更新功能end */

/* 刪除功能 */
// 刪除銷貨單
function apiSaleDelete(id) {
  const body = { SaleID: id };
  return fetchWrapper.delete('sale/deleteSale', body);
}

// 刪除銷貨單品項
function apiSaleItemDelete(QItemID, QID) {
  const body = { SaleID: QID, SaleItemID: QItemID };
  return fetchWrapper.delete('sale/deleteSaleItem', body);
}

/* 刪除功能end */

/* 複製功能 */

// 複製銷貨單資料
function apiSaleCopyByID(id) {
  const body = {
    SaleID: parseInt(id, 10)
  };
  return fetchWrapper.post('sale/copySale', body);
}

/* 複製功能end */

export const apiSaleService = {
  apiSearchSale,
  apiGetSaleList,
  apiGetSaleDetail,
  apiSaleAdd,
  apiSaleitemAdd,
  apiSaleUpdate,
  apiSaleItemUpdate,
  apiSaleDelete,
  apiSaleItemDelete,
  apiSaleCopyByID
};
