// import { selectDetection } from 'src/__mocks__/detection';
import moment from 'moment';
import fetchWrapper from './fetch-wrapper';

// 取得所有檢測資料
function apiGetDetectionAll() {
  // console.log('Detection_GetAll');
  let strURL = '';
  strURL = strURL.concat('From=', moment().format('YYYY-MM-01'));
  strURL = strURL.concat('&To=', moment(new Date()).format('YYYY-MM-DD'));
  return fetchWrapper.get(`detection/getDetectionList?${strURL}`);
}

// 搜尋條件
function apiSearchDetection(data) {
  let strURL = '';
  strURL = strURL.concat('From=', data.From);
  strURL = strURL.concat('&To=', data.To);
  if (data.CustomerID !== '') {
    strURL = strURL.concat('&CustomerID=', data.CustomerID);
  }
  if (data.ItemName !== '') {
    strURL = strURL.concat('&ItemName=', data.ItemName);
  }
  // console.log('SearchDetection', strURL);
  return fetchWrapper.get(`detection/getDetectionList?${strURL}`);
}

// 搜尋檢測單筆
function apiSearchDetectionByID(id) {
  // console.log('Detection_Search', id);
  return fetchWrapper.get(`detection/?DetectionID=${id}`);
}

function apiDetectionAdd(data) {
  // console.log('Detection_ADD', data);
  return fetchWrapper.post('detection/create', data);
}

// 暫時不能更新
function apiDetectionUpdate(parmaid, data) {
  const body = {
    ...data,
    IntofactoryID: parseInt(data.IntofactoryID, 10),
    CommodityID: parseInt(data.CommodityID, 10),
    DetectiontypeID: parseInt(data.DetectiontypeID, 10),
    DetectionID: parmaid
  };
  // console.log('Detection_Update', body);
  return fetchWrapper.put('detection/update', body);
}

// 刪除檢測單
function apiDetectionDelete(id) {
  const body = { DetectionID: id };
  return fetchWrapper.delete('detection/deleteDetection', body);
}

// // 刪除檢測品項
// function apiDetectionDeleteItem(id) {
//   const body = { DetectionItemID: id };
//   return fetchWrapper.delete('detection/deleteDetectionItem', body);
// }

// 檢測單重測
function apiDetectionRecheck(id) {
  const body = { DetectionID: id };
  // console.log('DetectionRecheck', body);
  return fetchWrapper.post('detection/createDetectionRecheck', body);
}

export const apiDetectionService = {
  apiGetDetectionAll,
  apiSearchDetection,
  apiSearchDetectionByID,
  apiDetectionAdd,
  apiDetectionUpdate,
  apiDetectionDelete,
  // apiDetectionDeleteItem,
  apiDetectionRecheck
};
