import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Autocomplete, Box, Button, Card, CardContent, DialogTitle, Dialog, DialogContent, Grid, TextField
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { apiSaleService } from '../../api/saleApi';
import { apiCommodityService } from '../../api/commodityApi';

function saleItemDialog(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const {
    onClose, open, reqData, saleID
  } = props;
  const [commodity, setCommodity] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    RealWeight: Yup.number().required('實際重量必填'),
    Amount: Yup.number().required('金額必填')
  });

  function createSaleItem(fields, setSubmitting) {
    apiSaleService.apiSaleitemAdd(fields)
      .then((res) => {
        // console.log('createSale_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('createSale_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateSaleItem(saleItemID, fields, setSubmitting) {
    apiSaleService.apiSaleItemUpdate(saleItemID, fields)
      .then((res) => {
        // console.log('updateSaleItem_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('updateSaleItem_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleCancelClick(value) {
    // console.log('handleCancelClick', value);
    onClose(value);
  }

  function saveSaleItem(fields, { setSubmitting }) {
    // console.log('SaleID', type.SaleID);
    setOpenBackdrop(true);
    if (reqData.SaleItemID === undefined) {
      createSaleItem(fields, setSubmitting);
    } else {
      updateSaleItem(reqData.SaleItemID, fields, setSubmitting);
    }
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="xl" aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">新增銷貨單品項</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            SaleID: saleID,
            SaleItemID: '',
            CommodityID: '',
            ItemName: '',
            RealWeight: '',
            Amount: '',
            Remark: ''
          }}
          validationSchema={schema}
            // eslint-disable-next-line react/jsx-no-bind
          onSubmit={saveSaleItem}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            setFieldValue
          }) => {
            useEffect(() => {
              setOpenBackdrop(true);
              apiCommodityService.apiGetCommodityAll().then((res) => {
                const commodityData = res.data && res.data.map((item) => ({
                  CommodityID: item.CommodityID, ItemID: item.ItemID, ItemName: item.ItemName
                }));
                setCommodity(commodityData);
                setOpenBackdrop(false);
              });
              if (reqData.SaleItemID !== undefined) {
                // console.log('reqData', reqData);
                const fields = ['SaleItemID', 'CommodityID', 'RealWeight', 'Amount', 'Remark'];
                fields.forEach((field) => {
                  switch (field) {
                    case 'CommodityID':
                      setFieldValue(field, reqData.Commodity, false);
                      setFieldValue('ItemName', reqData.Commodity.ItemName, false);
                      break;
                    default:
                      setFieldValue(field, reqData[field], false);
                      break;
                  }
                });
                setOpenBackdrop(false);
              } else {
                setOpenBackdrop(false);
              }
            }, []);

            return (
              <form onSubmit={handleSubmit}>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                  open={openBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <Autocomplete
                          id="CommodityID"
                          name="CommodityID"
                          options={commodity}
                      // eslint-disable-next-line no-shadow
                          getOptionLabel={(option) => option.ItemID}
                          onChange={(e, value) => {
                            // console.log(value);
                            setFieldValue(
                              'CommodityID',
                              value || null
                            );
                            setFieldValue(
                              'ItemName',
                              value?.ItemName || null
                            );
                          }}
                          value={values.CommodityID || null}
                          renderInput={(item) => (
                            <TextField
                              {...item}
                              error={Boolean(errors.CommodityID)}
                              helperText={errors.CommodityID}
                              label="品號"
                              variant="outlined"
                              name="CommodityID"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="品名"
                          name="ItemName"
                          value={values.ItemName || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.RealWeight)}
                          helperText={errors.RealWeight}
                          fullWidth
                          label="實際重量"
                          name="RealWeight"
                          value={values.RealWeight || ''}
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.Amount)}
                          helperText={errors.Amount}
                          fullWidth
                          label="金額"
                          name="Amount"
                          value={values.Amount || ''}
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="備註"
                          name="Remark"
                          margin="normal"
                          value={values.Remark || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting || isValidating}
                      color="primary"
                      variant="contained"
                    >
                      儲存
                    </Button>
                    <Button onClick={() => handleCancelClick(false)} variant="contained">
                      Cancel
                    </Button>
                  </Box>
                  <Fade in={fade}>
                    {isError
                      ? (
                        <Alert severity="error">
                          <AlertTitle>操作失敗</AlertTitle>
                          {errMsg || '請連絡相關人員'}
                        </Alert>
                      )
                      : (
                        <Alert severity="success">
                          <AlertTitle>操作成功</AlertTitle>
                        </Alert>
                      )}
                  </Fade>
                </Card>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

saleItemDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reqData: PropTypes.object.isRequired,
};

export default saleItemDialog;
