import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Link,
  Grid,
  MenuItem,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiSaleService } from '../../api/saleApi';
import { apiOpenService } from '../../api/openApi';
import { apiVenderService } from '../../api/venderApi';
import SelectWrapper from '../formsUI/selectWrapper';
import useStyles from '../../theme/useStyles';
import SaleItemDialog from './saleItemDialog';

function SaleForm() {
  const role = JSON.parse(sessionStorage.getItem('Staffme'));
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = params.id == null;
  const isCopy = params.key === 'copy';
  const [salePage, setSalePage] = useState({
    VenderData: [],
    PayMethodData: [],
    BillTypeData: [],
    TaxTypeData: []
  });
  const [errMsg, setErrMsg] = useState(null);
  const [amount, setAmount] = useState(0);
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  // 品項_start
  const [saleItemData, setSaleItemData] = useState({});
  const [saleItemOpen, setSaleItemOpen] = useState(false);
  const saleItemClickOpen = (data) => {
    setSaleItemData(data);
    setSaleItemOpen(true);
  };

  const saleItemClose = () => {
    setSaleItemOpen(false);
    window.location.reload();
  };

  const deletesaleItem = (id) => {
    // console.log('SaleItem_Delete', id);
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiSaleService.apiSaleItemDelete(id, params.id)
        .then(() => {
          window.location.reload();
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('SaleItemDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };
  // 品項_end

  const schema = Yup.object().shape({
    VenderID: Yup.string().required('選擇客戶'),
    SaleDate: Yup.date().required('日期必填'),
    PayMethodID: Yup.string().required('選擇付款方式'),
    EstimatePayDate: Yup.string().required('預結帳日必填'),
    BillTypeID: Yup.number().required('選擇發票聯式'),
    TaxTypeID: Yup.string().required('選擇課稅別'),
    BillDate: Yup.string().required('發票日期必填'),
    BillNumber: Yup.string().when('BillTypeID', (val, str) => {
      try {
        return val === 4 ? str : str.required('發票號碼必填');
      } catch (e) {
        // console.log('BillNumber_e', e);
        return null;
      }
    }),
  });

  function createSale(fields, setSubmitting) {
    apiSaleService.apiSaleAdd(fields)
      .then((res) => {
        // console.log('createSale_OK', res);
        setFade(true);
        setTimeout(() => navigate('/sale', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('createSale_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateSale(parmaid, fields, setSubmitting) {
    apiSaleService.apiSaleUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateSale_OK', res);
        setFade(true);
        setTimeout(() => navigate('/sale', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('updateSale_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function saveSale(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (isAddMode || isCopy) {
      createSale(fields, setSubmitting);
    } else {
      updateSale(params.id, fields, setSubmitting);
    }
  }

  const tabChange = (event, newValue) => {
    localStorage.setItem('tabChange', newValue);
    setTab(newValue);
  };

  function TabPanel(props) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Formik
      initialValues={{
        SalesID: '',
        SalesNum: '',
        VenderID: '',
        SaleDate: moment().format('YYYY-MM-DD'),
        PayMethodID: '',
        EstimatePayDate: moment().format('YYYY-MM-DD'),
        BillTypeID: '',
        TaxTypeID: '',
        BillDate: moment().format('YYYY-MM-DD'),
        BillNumber: '',
        Intofactory: '',
        Remark: '',
        SumAmount: 0,
        TaxAmount: 0,
        TotalAmount: 0,
        SaleItems: []
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveSale}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        isValidating,
        setFieldValue
      }) => {
        function faxCount(TaxTypeID) {
          let sum = 0;
          let total = 0;
          let tax = 0;
          // console.log('TaxTypeID', TaxTypeID);
          switch (TaxTypeID) {
            case 1:
              // 應稅外加
              sum = amount;
              tax = sum * 0.05;
              total = sum + tax;
              break;
            case 2:
              // 應稅內含
              total = amount;
              sum = Math.ceil(total / 1.05);
              tax = total - sum;
              break;
            case 3:
              // 未稅
              sum = amount;
              total = sum;
              break;
            default:
              break;
          }
          // console.log('total', amount, sum, tax, total);
          setFieldValue('SumAmount', sum, false);
          setFieldValue('TaxAmount', tax, false);
          setFieldValue('TotalAmount', total, false);
        }
        const fetchData = useCallback(() => {
          const fetchingData = async () => {
            // 取得客戶、業務選單
            const [VenderData, PayMethodData, BillTypeData, TaxTypeData] = await Promise.all([
              apiVenderService.apiGetVenderAll().then((x) => x.data),
              apiOpenService.apiGetSelectData('PayMethods'),
              apiOpenService.apiGetSelectData('BillTypes'),
              apiOpenService.apiGetSelectData('TaxTypes')
            ]);

            // 放進state
            setSalePage({
              VenderData,
              PayMethodData,
              BillTypeData,
              TaxTypeData
            });

            if (!isAddMode) {
              apiSaleService.apiGetSaleDetail(params.id).then((res) => {
                const fields = ['SalesNum', 'VenderID', 'SaleDate', 'PayMethodID', 'EstimatePayDate', 'BillTypeID', 'TaxTypeID', 'BillDate', 'BillNumber', 'Intofactory', 'Remark', 'SaleItems'];
                if (!isCopy) {
                  fields.push('SalesID');
                }
                fields.forEach((field) => {
                  switch (field) {
                    case 'VenderID':
                      setFieldValue(field, res.data[0].VenderInfo[field], false);
                      break;
                    case 'PayMethodID':
                      setFieldValue(field, res.data[0].PayMethod[field], false);
                      break;
                    case 'BillTypeID':
                      setFieldValue(field, res.data[0].BillType[field], false);
                      break;
                    case 'TaxTypeID':
                      setFieldValue(field, res.data[0].TaxType[field], false);
                      break;
                    case 'Intofactory':
                      setFieldValue(field, res.data[0].IntoFactoryBaseInfo, false);
                      break;
                    default:
                      setFieldValue(field, res.data[0][field], false);
                      break;
                  }
                });
              });
            }
            setOpenBackdrop(false);
          };
          fetchingData();
        }, []);

        useEffect(() => {
          setOpenBackdrop(true);
          fetchData();
        }, []);

        useEffect(() => {
          if (values.SaleItems?.length > 0) {
            const sum = values.SaleItems.map((item) => item.Amount).reduce((prev, curr) => prev + curr, 0);
            setAmount(sum);
            // console.log('sum', sum);
            faxCount(values.TaxTypeID);
          }
        }, [values.SaleItems, amount]);

        function onChangeVender(evt) {
          const { name, value } = evt.target;
          setFieldValue(name, value);
        }

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title={(isAddMode || isCopy) ? '新增銷貨單' : '編輯銷貨單'}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.VenderID)}
                      helperText={errors.VenderID}
                      select
                      fullWidth
                      name="VenderID"
                      label="廠商"
                      value={values.VenderID || ''}
                      onChange={(e) => onChangeVender(e)}
                      variant="outlined"
                    >
                      {salePage.VenderData && salePage.VenderData.map((option) => (
                        <MenuItem key={option.VenderID} value={option.VenderID}>
                          {option.VenderName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.SaleDate)}
                      helperText={errors.SaleDate}
                      fullWidth
                      name="SaleDate"
                      label="日期"
                      value={values.SaleDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="銷貨單號"
                      name="SalesNum"
                      value={values.SalesNum || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="PayMethodID"
                      label="付款方式"
                      selectValue={values.PayMethodID || ''}
                      options={salePage.PayMethodData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.EstimatePayDate)}
                      helperText={errors.EstimatePayDate}
                      fullWidth
                      name="EstimatePayDate"
                      label="預結帳日"
                      value={values.EstimatePayDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="BillTypeID"
                      label="發票聯式"
                      selectValue={values.BillTypeID || ''}
                      options={salePage.BillTypeData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      select
                      fullWidth
                      name="TaxTypeID"
                      label="課稅別"
                      value={values.TaxTypeID || ''}
                      variant="outlined"
                      onChange={(e) => {
                        setFieldValue('TaxTypeID', e.target.value, false);
                        faxCount(e.target.value);
                      }}
                    >
                      {salePage.TaxTypeData && salePage.TaxTypeData.map((option) => (
                        <MenuItem key={option.TaxTypeID} value={option.TaxTypeID}>
                          {option.TaxType}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.BillDate)}
                      helperText={errors.BillDate}
                      fullWidth
                      name="BillDate"
                      label="發票日期"
                      value={values.BillDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.BillNumber)}
                      helperText={errors.BillNumber}
                      fullWidth
                      label="發票號碼"
                      name="BillNumber"
                      value={values.BillNumber || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="備註"
                      name="Remark"
                      margin="normal"
                      value={values.Remark || ''}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              <Box>
                <Tabs value={tab} onChange={tabChange}>
                  <Tab label="商品資料" id="simple-tab-0" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Button
                  className={classes.AddButton}
                  size="small"
                  variant="contained"
                  onClick={() => saleItemClickOpen({})}
                >
                  新增品項
                </Button>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        品號
                      </TableCell>
                      <TableCell>
                        品名
                      </TableCell>
                      <TableCell>
                        實際重量
                      </TableCell>
                      <TableCell>
                        金額
                      </TableCell>
                      <TableCell>
                        備註
                      </TableCell>
                      <TableCell>
                        操作
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.SaleItems && values.SaleItems.map((item) => (
                      <TableRow
                        hover
                        key={item.SaleItemID}
                      >
                        <TableCell>
                          {item.Commodity.ItemID}
                        </TableCell>
                        <TableCell>
                          {item.Commodity.ItemName}
                        </TableCell>
                        <TableCell>
                          {item.RealWeight}
                        </TableCell>
                        <TableCell>
                          {item.Amount}
                        </TableCell>
                        <TableCell>
                          {item.Remark}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Button
                              className={classes.EditButton}
                              size="small"
                              variant="contained"
                              onClick={() => saleItemClickOpen(item)}
                            >
                              編輯
                            </Button>
                            {role.Departments[0].DepartmentID === 1
                              ? (
                                <Button
                                  className={classes.DeleteButton}
                                  size="small"
                                  variant="contained"
                                  onClick={() => deletesaleItem(item.SaleItemID)}
                                >
                                  刪除
                                </Button>
                              )
                              : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <SaleItemDialog saleID={params.id} reqData={saleItemData} open={saleItemOpen} onClose={saleItemClose} />
              </TabPanel>

              <Divider />
              <Box sx={{
                display: 'flex',
                justifyContent: 'flex-end',
                p: 2
              }}
              >
                <Typography
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                  mx={2}
                >
                  合計金額:
                  <Box component="span">
                    {values.SumAmount}
                  </Box>
                </Typography>
                <Typography
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                  mx={2}
                >
                  稅額:
                  <Box component="span">
                    {values.TaxAmount}
                  </Box>
                </Typography>
                <Typography
                  align="center"
                  color="textPrimary"
                  gutterBottom
                  variant="h5"
                  mx={2}
                >
                  總金額:
                  <Box component="span">
                    {values.TotalAmount}
                  </Box>
                </Typography>
              </Box>
              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  color="primary"
                  variant="contained"
                >
                  儲存
                </Button>
                <Link component={RouterLink} to="/sale">
                  <Button color="secondary" variant="contained">
                    取消
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default SaleForm;
