import { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Card,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import useStyles from 'src/theme/useStyles';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import CommodityDialog from './commodityDialog';
import IntoFactoryDialog from './intoFactoryDialog';

const IntoFactoryListResults = ({ intoFactorys }) => {
  const role = JSON.parse(sessionStorage.getItem('Staffme'));
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [errMsg, setErrMsg] = useState(null);
  const classes = useStyles();
  // TEST dialog start
  // IntoFactory
  const [openIntoFactory, setOpenIntoFactory] = useState(false);
  const [intoFactoryData, setIntoFactoryData] = useState(null);
  const IntoFactoryClickOpen = (id, data) => {
    setIntoFactoryData(data.filter((item) => item.IntofactoryID === id));
    setOpenIntoFactory(true);
  };
  const IntoFactoryClose = () => {
    setOpenIntoFactory(false);
    window.location.reload();
  };

  // Commodity
  const [openCommodity, setOpenCommodity] = useState(false);
  const CommodityClickOpen = (id, data) => {
    setIntoFactoryData(data.filter((item) => item.IntofactoryID === id));
    setOpenCommodity(true);
  };
  const CommodityClose = () => {
    setOpenCommodity(false);
    window.location.reload();
  };
  // TEST dialog end

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteBaseInfo = (id) => {
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiIntoFactoryService.apiIntoFactoryDelete(id)
        .then((res) => {
          // console.log('IntoFactoryListDelete_OK', res);
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('IntoFactoryListDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };

  const handleDeleteItem = (id) => {
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiIntoFactoryService.apiIntoFactoryItemDelete(id)
        .then((res) => {
          // console.log('IntoFactoryListDelete_OK', res);
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('IntoFactoryListDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };

  function handleCopyID(IntofactoryID) {
    apiIntoFactoryService.apiGetIntoFactoryCommodityByID(IntofactoryID)
      .then((res) => {
        // console.log('copyIntoFactory_OK', res);
        setFade(true);
        setTimeout(() => window.location.reload(), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('copyIntoFactory_Err', error);
        if (error !== '') { setErrMsg(error); }
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleLabel(IntofactoryID, CommodityID) {
    const session = sessionStorage.getItem('token');
    localStorage.setItem('token', session);
    window.open(`/label/${IntofactoryID}/${CommodityID}/1`, '_blank');
  }

  // console.log('intoFactorys', intoFactorys);
  return (
    <Card>
      {fade
        ? (
          <Fade in={fade}>
            {isError
              ? (
                <Alert severity="error">
                  <AlertTitle>操作失敗</AlertTitle>
                  {errMsg || '請連絡相關人員'}
                </Alert>
              )
              : (
                <Alert severity="success">
                  <AlertTitle>操作成功</AlertTitle>
                </Alert>
              )}
          </Fade>
        ) : null}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid className={classes.CellLine} container spacing={0}>
        <Grid item xs={2}>
          <Box className={classes.TextBox}>日期</Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.TextBox}>單號</Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.TextBox}>客戶代號</Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.TextBox}>客戶</Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.TextBox}>業務</Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.TextBox}>操作</Box>
        </Grid>
      </Grid>
      <div>
        {intoFactorys && intoFactorys.slice(page * limit, page * limit + limit).map((intoFactory) => (
          <Accordion key={intoFactory.IntofactoryID}>
            <AccordionSummary>
              <Grid container spacing={0}>
                <Grid item xs={2}>
                  <Box className={classes.TextBox}>{moment.parseZone(intoFactory.IntofactoryDate).format('YYYY-MM-DD')}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.TextBox}>{intoFactory.IntofactoryNum}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.TextBox}>{intoFactory.CustomerKey}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.TextBox}>{intoFactory.CustomerN}</Box>
                </Grid>
                <Grid item xs={1}>
                  <Box className={classes.TextBox}>{intoFactory.BusinessStaffName}</Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={classes.TextBox}>
                    <Button
                      className={classes.CopyButton}
                      size="small"
                      variant="contained"
                      onClick={() => handleCopyID(intoFactory.IntofactoryID)}
                    >
                      複製
                    </Button>
                    <Button
                      className={classes.AddButton}
                      size="small"
                      variant="contained"
                      onClick={() => CommodityClickOpen(intoFactory.IntofactoryID, intoFactorys)}
                    >
                      新增品項
                    </Button>
                    <Button
                      className={classes.EditButton}
                      size="small"
                      variant="contained"
                      onClick={() => IntoFactoryClickOpen(intoFactory.IntofactoryID, intoFactorys)}
                    >
                      編輯
                    </Button>
                    {role.Departments[0].DepartmentID === 1
                      ? (
                        <Button
                          className={classes.DeleteButton}
                          size="small"
                          variant="contained"
                          onClick={() => handleDeleteBaseInfo(intoFactory.IntofactoryID)}
                        >
                          刪除
                        </Button>
                      )
                      : null}
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Table style={{
                margin: '0% 5% 0% 5%', boxShadow: '0 0 0 1px rgb(63 63 68 / 15%), 0 1px 2px 0 rgb(63 63 68 / 15%)', width: '80%', background: 'aliceblue'
              }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      品名
                    </TableCell>
                    <TableCell>
                      識別序號
                    </TableCell>
                    <TableCell>
                      報價
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {intoFactory.IntofactoryListItemInfos && intoFactory.IntofactoryListItemInfos.map((item) => (
                    <TableRow
                      hover
                      key={item.CommodityID}
                    >
                      <TableCell>
                        {item.ItemName}
                      </TableCell>
                      <TableCell>
                        {item.SerialNo}
                      </TableCell>
                      <TableCell>
                        {item.Qutation === '1' ? '是' : '否'}
                      </TableCell>
                      <TableCell>
                        <Box className={classes.TextBox}>
                          <Link component={RouterLink} to={`${intoFactory.IntofactoryID}/${item.CommodityID}`}>
                            <Button
                              className={classes.EditButton}
                              size="small"
                              variant="contained"
                            >
                              編輯
                            </Button>
                          </Link>
                          {role.Departments[0].DepartmentID === 1
                            ? (
                              <Button
                                className={classes.DeleteButton}
                                size="small"
                                variant="contained"
                                onClick={() => handleDeleteItem(item.IntofactoryItemID)}
                              >
                                刪除
                              </Button>
                            )
                            : null}
                          <Button size="small" variant="contained" onClick={() => { handleLabel(intoFactory.IntofactoryID, item.CommodityID); }}>
                            條碼列印
                          </Button>
                        </Box>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
        <IntoFactoryDialog open={openIntoFactory} onClose={IntoFactoryClose} reqData={intoFactoryData} />
        <CommodityDialog reqData={intoFactoryData} open={openCommodity} onClose={CommodityClose} />
      </div>
      <TablePagination
        component="div"
        count={intoFactorys ? intoFactorys.length : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

IntoFactoryListResults.propTypes = {
  intoFactorys: PropTypes.array
};

export default IntoFactoryListResults;
