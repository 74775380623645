import fetchWrapper from './fetch-wrapper';
// import {
//   Commodity, Category, Element, Unit, CustomerInfo, DetectionType, CommodityState
// } from 'src/__mocks__/commodity';

// 取得所有商品資料
function apiGetCommodityAll() {
  // console.log('GetCommodityAll');
  return fetchWrapper.get('commodity/?Page=1&Pagesize=500');
}

// 搜尋商品
function apiSearchCommodity(CommodityID, Item) {
  let strURL = '';
  if (CommodityID !== '') {
    strURL = strURL.concat('CommodityID=', CommodityID);
  }
  if (Item !== '') {
    strURL = strURL.concat('ItemName=', Item);
    strURL = strURL.concat('&CommodityID=', Item);
  }
  // console.log('Commodity_Search', strURL);
  return fetchWrapper.get(`commodity/?${strURL}`);
}

// 客戶ID取得商品下拉選單資料(非入廠單檢測用)
function apiGetCustomerToCommodity(CustomerID) {
  // console.log('GetCommodityselect', CustomerID);
  return fetchWrapper.get(`commodity/?CustomerID=${CustomerID}&DetectionTypeID=2|3`).then((res) => {
    // console.log('GetCommodityselect_res', res);
    const data = [];
    if (res.data) {
      res.data.forEach((item) => {
        const arrElement = [];
        item.CommodityElements.forEach((val) => {
          arrElement.push({
            ElementID: val.Element.ElementID,
            Element: val.Element.Element
          });
        });
        data.push({
          Element: [...arrElement],
          CommodityID: item.CommodityID,
          ItemID: item.ItemID,
          ItemName: item.ItemName
        });
      });
    }
    return data;
  });
}

function elementToBody(data) {
  const elementBody = [];
  data.forEach((element) => {
    const warningvalue = parseFloat(element.Warningvalue);
    elementBody.push({
      ...element,
      Warningvalue: warningvalue
    });
  });
  // console.log('elementBody', elementBody);
  return elementBody;
}

function apiCommodityAdd(data) {
  const elementdata = elementToBody(data.Elements);
  const body = {
    ...data,
    Elements: elementdata
  };
  // console.log('Commodity_ADD', body);
  return fetchWrapper.post('commodity/create', body);
}

function apiCommodityUpdate(parmaid, data) {
  const elementdata = elementToBody(data.Elements);
  const body = {
    ...data,
    CommodityID: parseInt(parmaid, 10),
    Elements: elementdata
  };
  // console.log('Commodity_Update', body);
  return fetchWrapper.put('commodity/update', body);
}

function apiCommodityDelete(id) {
  const body = { CommodityID: id };
  return fetchWrapper.delete('commodity/delete', body);
}

export const apiCommodityService = {
  apiGetCommodityAll,
  apiSearchCommodity,
  apiGetCustomerToCommodity,
  apiCommodityDelete,
  apiCommodityAdd,
  apiCommodityUpdate
};
