import { makeStyles } from '@material-ui/styles';
import {
  yellow, red, blue, green
} from '@material-ui/core/colors';

const useStyles = makeStyles({
  EditButton: {
    color: 'black',
    backgroundColor: yellow[500],
    '&:hover': {
      backgroundColor: yellow[700],
    },
  },
  AddButton: {
    color: 'white',
    backgroundColor: blue[500],
    '&:hover': {
      backgroundColor: blue[700],
    },
  },
  DeleteButton: {
    color: 'white',
    backgroundColor: red[500],
    '&:hover': {
      backgroundColor: red[700],
    },
  },
  CopyButton: {
    color: 'white',
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  TextBox: {
    textAlign: 'left'
  },
  CellLine: {
    borderBottom: '1px solid rgba(224, 224, 224, 1)',
    padding: '16px',
    fontFamily: 'Roboto, Helvetica, Arial, sans-serif',
    fontWeight: 500,
    fontSize: '0.875rem'
  }
});

export default useStyles;
