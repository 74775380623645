import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import SaleDialog from './saleDialog';

const SaleListToolbar = (props) => {
  const [value, setValue] = useState({
    VenderID: '',
    ItemName: '',
    From: moment().format('YYYY-MM-01'),
    To: moment(new Date()).format('YYYY-MM-DD')
  });
  const { onClick, venderSelect } = props;

  // Add Dialog Start
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  // Add Dialog End

  return (
    <Box>
      <Box sx={{ display: 'flex', justifyContent: 'flex-start' }}>
        <Button color="primary" variant="contained" onClick={handleClickOpen}>
          新增銷貨單
        </Button>
        <SaleDialog open={open} onClose={handleClose} type={{}} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  name="VenderID"
                  label="廠商"
                  value={value.VenderID || ''}
                  placeholder="查詢廠商代號"
                  variant="outlined"
                  onChange={(e) => setValue({ ...value, VenderID: e.target.value })}
                >
                  {venderSelect && venderSelect.map((option) => (
                    <MenuItem key={option.VenderID} value={option.VenderID}>
                      {option.VenderName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="ItemName"
                  value={value.ItemName || ''}
                  placeholder="查詢品名"
                  variant="outlined"
                  onChange={(e) => setValue({ ...value, ItemName: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="From"
                  value={value.From}
                  placeholder="查詢起始日期"
                  variant="outlined"
                  onChange={(e) => {
                    setValue({ ...value, From: moment(e.target.value).format('YYYY-MM-DD') });
                  }}
                  type="date"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="To"
                  value={value.To}
                  placeholder="查詢結束日期"
                  variant="outlined"
                  onChange={(e) => {
                    setValue({ ...value, To: moment(e.target.value).format('YYYY-MM-DD') });
                  }}
                  type="date"
                />
              </Grid>
              <Grid item xs={3} mt={1}>
                <Button onClick={() => onClick(value)} color="primary" variant="contained">
                  搜尋
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};

export default SaleListToolbar;
