import { Navigate } from 'react-router-dom';
import MainLayout from './components/mainLayout';
import Account from './pages/account';
import Login from './pages/login';
import NotFound from './pages/notFound';
import StaffList from './pages/staffList';
import StaffAddEdit from './pages/staffAddEdit';
import CommodityList from './pages/commodityList';
import CommodityAddEdit from './pages/commodityAddEdit';
import Register from './pages/register';
import Settings from './pages/settings';
import VenderList from './pages/venderList';
import VenderAddEdit from './pages/venderAddEdit';
import CustomerList from './pages/customerList';
import CustomerAddEdit from './pages/customerAddEdit';
import IntoFactoryList from './pages/intoFactoryList';
import IntoFactoryAddEdit from './pages/intoFactoryAddEdit';
import DetectionList from './pages/detectionList';
import DetectionAddEdit from './pages/detectionAddEdit';
import ExperimentList from './pages/experimentList';
import ExperimentAddEdit from './pages/experimentAddEdit';
import DashboardLayout from './components/dashboardLayout';
import ComponentToPrint from './pages/label';
import QutationList from './pages/qutationList';
import QutationAddEdit from './pages/qutationAddEdit';
import SaleList from './pages/saleList';
import SaleAddEdit from './pages/saleAddEdit';

const routes = [
  {
    path: 'app',
    element: <DashboardLayout />,
    children: [
      { path: 'account', element: <Account /> },
      { path: 'checklist', element: <Settings /> },
      { path: 'incoming', element: <CustomerList /> },
      { path: 'settings', element: <Settings /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: '/',
    element: <MainLayout />,
    children: [
      { path: 'login', element: <Login /> },
      { path: 'label/:id/:key/:type', element: <ComponentToPrint /> },
      { path: 'register', element: <Register /> },
      { path: '404', element: <NotFound /> },
      { path: '/', element: <Navigate to="/login" /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'commodity',
    element: <DashboardLayout />,
    children: [
      { path: 'add', element: <CommodityAddEdit /> },
      { path: ':id/:key', element: <CommodityAddEdit /> },
      { path: '/', element: <CommodityList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'staff',
    element: <DashboardLayout />,
    children: [
      { path: 'add', element: <StaffAddEdit /> },
      { path: ':id', element: <StaffAddEdit /> },
      { path: '/', element: <StaffList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'customer',
    element: <DashboardLayout />,
    children: [
      { path: 'add', element: <CustomerAddEdit /> },
      { path: ':id/:key', element: <CustomerAddEdit /> },
      { path: '/', element: <CustomerList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'vender',
    element: <DashboardLayout />,
    children: [
      { path: 'add', element: <VenderAddEdit /> },
      { path: ':id/:key', element: <VenderAddEdit /> },
      { path: '/', element: <VenderList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'intofactory',
    element: <DashboardLayout />,
    children: [
      { path: 'add', element: <IntoFactoryAddEdit /> },
      { path: ':id/:key', element: <IntoFactoryAddEdit /> },
      { path: '/', element: <IntoFactoryList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'detection',
    element: <DashboardLayout />,
    children: [
      { path: 'add', element: <DetectionAddEdit /> },
      { path: ':id/:key', element: <DetectionAddEdit /> },
      { path: '/', element: <DetectionList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'experiment',
    element: <DashboardLayout />,
    children: [
      { path: ':id/:key', element: <ExperimentAddEdit /> },
      { path: '/', element: <ExperimentList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'qutation',
    element: <DashboardLayout />,
    children: [
      { path: ':id/:key', element: <QutationAddEdit /> },
      { path: '/', element: <QutationList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  },
  {
    path: 'sale',
    element: <DashboardLayout />,
    children: [
      { path: ':id/:key', element: <SaleAddEdit /> },
      { path: '/', element: <SaleList /> },
      { path: '*', element: <Navigate to="/404" /> }
    ]
  }
];

export default routes;
