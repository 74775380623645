import { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Box,
  Button,
  Card,
  Grid,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import useStyles from 'src/theme/useStyles';
import { apiQutationService } from '../../api/qutationApi';

const QutationListResults = ({ qutations, ...rest }) => {
  const role = JSON.parse(sessionStorage.getItem('Staffme'));
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const [errMsg, setErrMsg] = useState(null);
  const classes = useStyles();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  const handleDeleteQutation = (id) => {
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiQutationService.apiQutationDelete(id)
        .then((res) => {
          // console.log('IntoFactoryListDelete_OK', res);
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('IntoFactoryListDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };

  function handleCopyID(QutationID) {
    apiQutationService.apiQutationCopyByID(QutationID)
      .then((res) => {
        // console.log('copySale_OK', res);
        setFade(true);
        setTimeout(() => window.location.reload(), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('copySale_Err', error);
        if (error !== '') { setErrMsg(error); }
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  return (
    <Card>
      {fade
        ? (
          <Fade in={fade}>
            {isError
              ? (
                <Alert severity="error">
                  <AlertTitle>操作失敗</AlertTitle>
                  {errMsg || '請連絡相關人員'}
                </Alert>
              )
              : (
                <Alert severity="success">
                  <AlertTitle>操作成功</AlertTitle>
                </Alert>
              )}
          </Fade>
        ) : null}
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Grid className={classes.CellLine} container spacing={0}>
        <Grid item xs={1}>
          <Box className={classes.TextBox}>日期</Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.TextBox}>入廠單號</Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.TextBox}>客戶代號</Box>
        </Grid>
        <Grid item xs={1}>
          <Box className={classes.TextBox}>客戶</Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.TextBox}>業務</Box>
        </Grid>
        <Grid item xs={2}>
          <Box className={classes.TextBox}>備註</Box>
        </Grid>
        <Grid item xs={3}>
          <Box className={classes.TextBox}>操作</Box>
        </Grid>
      </Grid>
      <div>
        {qutations && qutations.slice(page * limit, page * limit + limit).map((qutation) => (
          <Accordion key={qutation.QutationID}>
            <AccordionSummary>
              <Grid container spacing={0}>
                <Grid item xs={1}>
                  <Box className={classes.TextBox}>{moment(qutation.QutationDate).format('YYYY-MM-DD')}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.TextBox}>{qutation.IntofactoryNum}</Box>
                </Grid>
                <Grid item xs={1}>
                  <Box className={classes.TextBox}>{qutation.CustomerKey}</Box>
                </Grid>
                <Grid item xs={1}>
                  <Box className={classes.TextBox}>{qutation.CustomerN}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.TextBox}>{qutation.BusinessStaff}</Box>
                </Grid>
                <Grid item xs={2}>
                  <Box className={classes.TextBox}>{qutation.Remark}</Box>
                </Grid>
                <Grid item xs={3}>
                  <Box className={classes.TextBox}>
                    <Button
                      className={classes.CopyButton}
                      size="small"
                      variant="contained"
                      onClick={() => handleCopyID(qutation.QutationID)}
                    >
                      複製
                    </Button>
                    <Link component={RouterLink} to={`${qutation.QutationID}/edit`}>
                      <Button
                        className={classes.EditButton}
                        size="small"
                        variant="contained"
                      >
                        編輯
                      </Button>
                    </Link>
                    {role.Departments[0].DepartmentID === 1
                      ? (
                        <Button
                          className={classes.DeleteButton}
                          size="small"
                          variant="contained"
                          onClick={() => handleDeleteQutation(qutation.QutationID)}
                        >
                          刪除
                        </Button>
                      )
                      : null}
                  </Box>
                </Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Table style={{
                margin: '0% 5% 0% 5%', boxShadow: '0 0 0 1px rgb(63 63 68 / 15%), 0 1px 2px 0 rgb(63 63 68 / 15%)', width: '80%', background: 'aliceblue'
              }}
              >
                <TableHead>
                  <TableRow>
                    <TableCell>
                      品名
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {qutation.QutationItems && qutation.QutationItems.map((item, i) => (
                    <TableRow
                      hover
                      // eslint-disable-next-line react/no-array-index-key
                      key={i}
                    >
                      <TableCell>
                        {item.ItemName}
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
      <TablePagination
        component="div"
        count={qutations ? qutations.length : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

QutationListResults.propTypes = {
  qutations: PropTypes.array
};

export default QutationListResults;
