import { Helmet } from 'react-helmet';
import React from 'react';
import { Box, Container } from '@material-ui/core';
import CustomerForm from '../components/customerAddEdit/customerForm';

const CustomerAddEdit = () => (
  <>
    <Helmet>
      <title>客戶資料</title>
    </Helmet>
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        py: 3
      }}
    >
      <Container maxWidth="lg">
        <CustomerForm />
      </Container>
    </Box>
  </>
);

export default CustomerAddEdit;
