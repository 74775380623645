import React, {
  useCallback, useEffect, useState
} from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Link,
  Grid,
  Stepper,
  Step,
  StepLabel,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import { apiDetectionService } from '../../api/detectionApi';
import { apiOpenService } from '../../api/openApi';
import useStyles from '../../theme/useStyles';
import MeasureDialog from './measureDialog';
import DetectionsDialog from './detectionsDialog';
import StatusFormDialog from './statusFormDialog';
import SelectLink from '../formsUI/selectLink';
import SelectWrapper from '../formsUI/selectWrapper';
import UploadFiles from '../formsUI/FileUpload';

function IntoFactoryForm() {
  const role = JSON.parse(sessionStorage.getItem('Staffme'));
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const intoFactoryID = params.id;
  const commodityID = params.key;
  const [tab, setTab] = useState(0);
  const [commodityData, setCommodityData] = useState([]);
  const classes = useStyles();
  const isTrueState = [
    {
      IsTrueID: '1',
      IsTrue: '是',
    },
    {
      IsTrueID: '0',
      IsTrue: '否',
    },
  ];
  const [errMsg, setErrMsg] = useState(null);
  const [arrfile, setArrfile] = useState([]);

  // 量測_start
  const [measureData, setMeasureData] = useState({});
  const [measureOpen, setMeasureOpen] = useState(false);
  const measureClickOpen = (data) => {
    setMeasureData(data);
    setMeasureOpen(true);
  };

  const measureClose = () => {
    setMeasureOpen(false);
    window.location.reload();
  };

  const deletemeasure = (id) => {
    // console.log('Measure_Delete', id);
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiIntoFactoryService.apiMeasureDelete(id)
        .then(() => {
          window.location.reload();
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('MeasureDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };
  // 量測_end

  // 檢測_start
  const [detectionsData, setDetectionsData] = useState({});
  const [detectionsOpen, setDetectionsOpen] = useState(false);
  const detectionsClickOpen = (data) => {
    setDetectionsData(data);
    setDetectionsOpen(true);
  };

  const detectionsClose = () => {
    setDetectionsOpen(false);
    window.location.reload();
  };

  const deletedetections = (id) => {
    // console.log('Detections_Delete', id);
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiDetectionService.apiDetectionDelete(id)
        .then(() => {
          window.location.reload();
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('DetectionsDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };
  // 檢測_end

  // 軌跡_start
  const [statusFormData, setStatusFormData] = useState({});
  const [statusFormOpen, setStatusFormOpen] = useState(false);
  const statusFormClickOpen = (data) => {
    setStatusFormData(data);
    setStatusFormOpen(true);
  };

  const statusFormClose = () => {
    setStatusFormOpen(false);
    window.location.reload();
  };

  const deletestatusform = (id) => {
    // console.log('Statusform_Delete', id);
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiIntoFactoryService.apiStatusformDelete(id)
        .then(() => {
          window.location.reload();
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('StatusformDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };

  // 軌跡_end

  const schema = Yup.object().shape({
    CommodityID: Yup.string().required('品號必填')
  });

  const tabChange = (event, newValue) => {
    localStorage.setItem('tabChange', newValue);
    setTab(newValue);
  };

  function saveIntoFactory(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    apiIntoFactoryService.apiIntoFactoryItemUpdate(fields)
      .then((res) => {
        // console.log('updateIntoFactory_OK', res);
        setFade(true);
        setTimeout(() => navigate('/Intofactory', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('updateIntoFactory_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function TabPanel(props) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  // 轉製程單
  function fnToProcessing(val) {
    // console.log('val', val);
    if (val.Detections) {
      if (window.confirm('確定要轉製程單嗎?')) {
        setOpenBackdrop(true);
        const Detectionslen = val.Detections.length;
        const DetectionItemslen = val.Detections[Detectionslen - 1].DetectionItems.length;
        const DetectionSampleslen = val.Detections[Detectionslen - 1].DetectionItems[DetectionItemslen - 1].DetectionSamples.length;
        const DetectionElementslen = val.Detections[Detectionslen - 1].DetectionItems[DetectionItemslen - 1].DetectionSamples[DetectionSampleslen - 1].DetectionElements.length;
        const getElementID = val.Detections[Detectionslen - 1].DetectionItems[DetectionItemslen - 1].DetectionSamples[DetectionSampleslen - 1].DetectionElements[DetectionElementslen - 1].Element.ElementID;
        const body = {
          IntofactoryID: val.IntofactoryID,
          CommodityID: val.CommodityID,
          CommissionDate: moment().format('YYYY-MM-DD'),
          ExceptedTime: moment().add(7, 'd').format('YYYY-MM-DD'),
          Processingelements: [
            {
              ElementID: getElementID,
              ResGram: 0,
              RealGram: 0,
              StayGram: 0
            }
          ],
          Processingstatustypeid: 1
        };
        apiIntoFactoryService.apiProcessingAdd(body)
          .then(() => {
            setFade(true);
            setTimeout(() => navigate('/intofactory', { replace: true }), 2000);
            setOpenBackdrop(false);
          })
          .catch((error) => {
            // console.log('StatusformDelete_Err', error);
            if (error !== '') { setErrMsg(error); }
            setOpenBackdrop(false);
            setIsError(true);
            setFade(true);
          });
      }
    } else {
      alert('查無檢測資料，無法轉製程單');
    }
  }

  // 轉報價單
  function fnToQutation(val) {
    // console.log('val', val);
    if (window.confirm('確定要轉報價單嗎?')) {
      setOpenBackdrop(true);
      apiIntoFactoryService.apiQutationAdd(val)
        .then(() => {
          setFade(true);
          setTimeout(() => navigate('/qutation', { replace: true }), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('StatusformDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  }

  // 轉銷貨單
  function fnToSale(val) {
    // console.log('val', val);
    if (window.confirm('確定要轉銷貨單嗎?')) {
      setOpenBackdrop(true);
      apiIntoFactoryService.apiSaleAdd(val)
        .then(() => {
          setFade(true);
          setTimeout(() => navigate('/sale', { replace: true }), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('StatusformDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  }

  // 通知報價
  function fnWantQutation(val) {
    // console.log('val', val);
    if (window.confirm('確定要通知老闆報價嗎?')) {
      setOpenBackdrop(true);
      apiIntoFactoryService.apiWantQutation(val)
        .then((res) => {
          setFade(true);
          alert(res.msg);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('fnWantQutation_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  }

  return (
    <Formik
      initialValues={{
        IntofactoryID: intoFactoryID,
        IntofactoryItemID: '',
        CustomerID: '',
        CommodityID: commodityID,
        ItemName: '',
        Declare: '',
        SerialNo: '',
        Weight: '',
        RealWeight: '',
        Concentration: '',
        Gram: '',
        Remark: '',
        MeasureInfos: [],
        Detections: [],
        IntofactoryStatuss: [],
        CommissionDate: '',
        ExceptedTime: '',
        ItemRecipt: ''
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveIntoFactory}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        isValidating,
        setFieldValue
      }) => {
        const fetchData = useCallback(() => {
          const fetchingData = async () => {
            // 取得品項資料
            await apiIntoFactoryService.apiGetIntoFactoryItem(intoFactoryID, commodityID).then((res) => {
              // 取得品項選單
              apiOpenService.apiGetSelectCommodityData(res.data.IntofactoryBaseInfo.CustomerInfo.CustomerID, 1).then((val) => {
                setCommodityData(val);
                setOpenBackdrop(false);
              });

              // console.log('IntoFactoryItemData', res);
              setFieldValue('CustomerID', res.data.IntofactoryBaseInfo.CustomerInfo.CustomerID, false);
              const itemData = res.data.IntofactoryItemInfos[0];
              const arrFile = itemData.ItemRecipt?.split(',');
              setFieldValue('ItemRecipt', arrFile, false);
              setArrfile(arrFile);
              const fields = ['IntofactoryID', 'IntofactoryItemID', 'CommodityID', 'ItemName', 'Declare', 'SerialNo', 'Weight',
                'RealWeight', 'Concentration', 'Gram', 'Remark', 'MeasureInfos', 'Detections', 'IntofactoryStatuss'];
              fields.forEach((field) => {
                switch (field) {
                  case 'CommodityID':
                    setFieldValue(field, itemData.Commodity[field], false);
                    break;
                  case 'ItemName':
                    setFieldValue(field, itemData.Commodity[field], false);
                    break;
                  case 'Gram': {
                    const calGram = itemData.Weight * itemData.Concentration;
                    setFieldValue(field, calGram, false);
                    break;
                  }
                  default:
                    setFieldValue(field, itemData[field], false);
                    break;
                }
              });
            });
          };
          fetchingData();
        }, []);

        useEffect(() => {
          setOpenBackdrop(true);
          setTab(parseInt(localStorage.getItem('tabChange'), 10) || 0);
          fetchData();
        }, [fetchData]);

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title="編輯入廠單商品"
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <SelectLink
                      fullWidth
                      label="品號"
                      name="CommodityID"
                      selectValue={values.CommodityID || ''}
                      options={commodityData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="品名"
                      name="ItemName"
                      value={values.ItemName || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="識別序號"
                      name="SerialNo"
                      value={values.SerialNo || ''}
                      onChange={handleChange}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      fullWidth
                      label="申報"
                      name="Declare"
                      selectValue={values.Declare || ''}
                      options={isTrueState}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="實際重量"
                      name="RealWeight"
                      value={values.RealWeight || ''}
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="報價重量"
                      name="Weight"
                      value={values.Weight || ''}
                      variant="outlined"
                      onChange={handleChange}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="報價克數"
                      name="Gram"
                      value={values.Gram || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="換算濃度"
                      name="Concentration"
                      value={values.Concentration || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="備註"
                      name="Remark"
                      margin="normal"
                      value={values.Remark || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <UploadFiles data={values} filedata={arrfile} />
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />
              <Box>
                <Tabs value={tab} onChange={tabChange}>
                  <Tab label="量測" id="simple-tab-0" />
                  <Tab label="檢測" id="simple-tab-1" />
                  <Tab label="軌跡" id="simple-tab-2" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Button
                  className={classes.AddButton}
                  size="small"
                  variant="contained"
                  onClick={() => measureClickOpen('')}
                >
                  新增量測
                </Button>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        量測種類
                      </TableCell>
                      <TableCell>
                        秤重人員
                      </TableCell>
                      <TableCell>
                        重量
                      </TableCell>
                      <TableCell>
                        備註
                      </TableCell>
                      <TableCell>
                        操作
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.MeasureInfos && values.MeasureInfos.map((measure) => (
                      <TableRow
                        hover
                        key={measure.MeasureID}
                      >
                        <TableCell>
                          {measure.MeasureType.MeasureType}
                        </TableCell>
                        <TableCell>
                          {measure.Staff.Name}
                        </TableCell>
                        <TableCell>
                          {measure.Weight}
                        </TableCell>
                        <TableCell>
                          {measure.Remark}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Button
                              className={classes.EditButton}
                              size="small"
                              variant="contained"
                              onClick={() => measureClickOpen(measure)}
                            >
                              編輯
                            </Button>
                            {role.Departments[0].DepartmentID === 1
                              ? (
                                <Button
                                  className={classes.DeleteButton}
                                  size="small"
                                  variant="contained"
                                  onClick={() => deletemeasure(measure.MeasureID)}
                                >
                                  刪除
                                </Button>
                              )
                              : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <MeasureDialog intoFactoryID={intoFactoryID} commodityID={commodityID} reqData={measureData} open={measureOpen} onClose={measureClose} />
              </TabPanel>
              <TabPanel value={tab} index={1}>
                <Button
                  className={classes.AddButton}
                  size="small"
                  variant="contained"
                  onClick={() => detectionsClickOpen('')}
                >
                  新增檢測
                </Button>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        檢測單號
                      </TableCell>
                      <TableCell>
                        委託日期
                      </TableCell>
                      <TableCell>
                        取樣人員
                      </TableCell>
                      <TableCell>
                        操作
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.Detections && values.Detections.map((detection) => (
                      <TableRow
                        hover
                        key={detection.DetectionID}
                      >
                        <TableCell>
                          {detection.DetectionNum}
                        </TableCell>
                        <TableCell>
                          {moment(detection.CommissionDate).format('YYYY-MM-DD')}
                        </TableCell>
                        <TableCell>
                          {detection.DetectionItems[0].DetectionSamples[0].SampleStaff.Name}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Button
                              className={classes.EditButton}
                              size="small"
                              variant="contained"
                              onClick={() => detectionsClickOpen(detection)}
                            >
                              瀏覽
                            </Button>
                            {role.Departments[0].DepartmentID === 1
                              ? (
                                <Button
                                  className={classes.DeleteButton}
                                  size="small"
                                  variant="contained"
                                  onClick={() => deletedetections(detection.DetectionID)}
                                >
                                  刪除
                                </Button>
                              )
                              : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <DetectionsDialog intoFactoryID={intoFactoryID} commodityID={commodityID} customerID={values.CustomerID} reqData={detectionsData} open={detectionsOpen} onClose={detectionsClose} />
              </TabPanel>
              <TabPanel value={tab} index={2}>
                <Box>
                  <Button
                    className={classes.AddButton}
                    size="small"
                    variant="contained"
                    onClick={() => statusFormClickOpen('')}
                  >
                    新增軌跡
                  </Button>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>
                          時間
                        </TableCell>
                        <TableCell>
                          倉別
                        </TableCell>
                        <TableCell>
                          狀態
                        </TableCell>
                        <TableCell>
                          送貨人員
                        </TableCell>
                        <TableCell>
                          備註
                        </TableCell>
                        <TableCell>
                          操作
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {values.IntofactoryStatuss && values.IntofactoryStatuss.map((statusdata) => (
                        <TableRow
                          hover
                          key={statusdata.StatusformID}
                        >
                          <TableCell>
                            {moment.parseZone(statusdata.DeliverTime).format('YYYY-MM-DD')}
                          </TableCell>
                          <TableCell>
                            {statusdata.WarehouseType.Warehouse}
                          </TableCell>
                          <TableCell>
                            {statusdata.StatusType.Status}
                          </TableCell>
                          <TableCell>
                            {statusdata.DeliverStaff.Name}
                          </TableCell>
                          <TableCell>
                            {statusdata.Remark}
                          </TableCell>
                          <TableCell>
                            <Box>
                              <Button
                                className={classes.EditButton}
                                size="small"
                                variant="contained"
                                onClick={() => statusFormClickOpen(statusdata)}
                              >
                                編輯
                              </Button>
                              {role.Departments[0].DepartmentID === 1
                                ? (
                                  <Button
                                    className={classes.DeleteButton}
                                    size="small"
                                    variant="contained"
                                    onClick={() => deletestatusform(statusdata.StatusformID)}
                                  >
                                    刪除
                                  </Button>
                                )
                                : null}
                            </Box>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                    <StatusFormDialog intoFactoryID={intoFactoryID} commodityID={commodityID} reqData={statusFormData} open={statusFormOpen} onClose={statusFormClose} />
                  </Table>
                  <Divider />
                  <Box my={3}>
                    軌跡導覽
                    <Stepper alternativeLabel activeStep={1}>
                      {values.IntofactoryStatuss && values.IntofactoryStatuss.map((step) => (
                        <Step key={step.StatusformID}>
                          <StepLabel>
                            <Grid item xs={12}>
                              {moment.parseZone(step.DeliverTime).format('YYYY-MM-DD')}
                            </Grid>
                            <Grid item xs={12}>
                              {moment.parseZone(step.DeliverTime).format('HH:mm')}
                            </Grid>
                            <Grid item xs={12}>
                              {step.WarehouseType.Warehouse}
                            </Grid>
                            <Grid item xs={12}>
                              {step.StatusType.Status}
                            </Grid>
                          </StepLabel>
                        </Step>
                      ))}
                    </Stepper>
                  </Box>
                </Box>
              </TabPanel>
              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => fnWantQutation(values)}
                >
                  通知老闆報價
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => fnToQutation(values)}
                >
                  轉報價單
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => fnToSale(values)}
                >
                  轉銷貨單
                </Button>
                <Button
                  type="button"
                  color="primary"
                  variant="contained"
                  onClick={() => fnToProcessing(values)}
                >
                  轉製程單
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  color="primary"
                  variant="contained"
                >
                  儲存
                </Button>
                <Link component={RouterLink} to="/IntoFactory">
                  <Button color="secondary" variant="contained">
                    Cancel
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default IntoFactoryForm;
