import { useCallback, useState, useEffect } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, CardContent, DialogTitle, Dialog, DialogContent, Grid, TextField, MenuItem
} from '@material-ui/core';
import {
  Alert, AlertTitle, Autocomplete, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { apiQutationService } from '../../api/qutationApi';
import { apiOpenService } from '../../api/openApi';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import SelectWrapper from '../formsUI/selectWrapper';

function QutationDialog(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const { onClose, open } = props;
  const [qutationPage, setQutationPage] = useState({
    CustomerData: [],
    PayMethodData: [],
    BillTypeData: [],
    TaxTypeData: [],
    IntofactoryData: []
  });
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    CustomerID: Yup.string().required('選擇客戶'),
    QutationDate: Yup.date().required('日期必填'),
    PayMethodID: Yup.string().required('選擇付款方式'),
    EstimatePayDate: Yup.string().required('預結帳日必填'),
    PayDate: Yup.string().required('付款時間必填'),
    BillTypeID: Yup.number().required('選擇發票聯式'),
    TaxTypeID: Yup.string().required('選擇課稅別'),
    BillDate: Yup.string().required('發票日期必填'),
    BillNumber: Yup.string().when('BillTypeID', (val, str) => {
      try {
        return val === 4 ? str : str.required('發票號碼必填');
      } catch (e) {
        // console.log('BillNumber_e', e);
        return null;
      }
    }),
    Intofactory: Yup.string().required('選擇入廠單號')
  });

  function createQutation(fields, { setSubmitting }) {
    apiQutationService.apiQutationAdd(fields)
      .then((res) => {
        // console.log('createQutation_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('createQutation_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleCancelClick(value) {
    // console.log('handleCancelClick', value);
    onClose(value);
  }

  function saveQutation(fields, { setSubmitting }) {
    // console.log('QutationID', type.QutationID);
    setOpenBackdrop(true);
    createQutation(fields, setSubmitting);
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="xl" aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">新增報價單</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            QutationID: '',
            QutationNum: '',
            CustomerID: '',
            QutationDate: moment().format('YYYY-MM-DD'),
            PayMethodID: '',
            EstimatePayDate: moment().format('YYYY-MM-DD'),
            PayDate: moment().format('YYYY-MM-DD'),
            BillTypeID: '',
            TaxTypeID: '',
            BillDate: moment().format('YYYY-MM-DD'),
            BillNumber: '',
            Intofactory: 0,
            Remark: ''
          }}
          validationSchema={schema}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={saveQutation}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            setFieldValue
          }) => {
            const fetchData = useCallback(() => {
              const fetchingData = async () => {
                // 取得客戶、付款方式、發票聯式、課稅別
                const [CustomerData, PayMethodData, BillTypeData, TaxTypeData] = await Promise.all([
                  apiOpenService.apiGetSelectCustomerData(),
                  apiOpenService.apiGetSelectData('PayMethods'),
                  apiOpenService.apiGetSelectData('BillTypes'),
                  apiOpenService.apiGetSelectData('TaxTypes')
                ]);

                // 放進state
                setQutationPage({
                  CustomerData,
                  PayMethodData,
                  BillTypeData,
                  TaxTypeData
                });
                setOpenBackdrop(false);
              };
              fetchingData();
            }, []);

            useEffect(() => {
              setOpenBackdrop(true);
              fetchData();
            }, [fetchData]);

            const fnGetIntofactoryAPI = async (val) => {
              await apiIntoFactoryService.apiGetIntoFactoryBaseNumList(val).then((res) => {
                // console.log('res', res);
                setQutationPage({
                  ...qutationPage,
                  IntofactoryData: res.data && res.data.map((item) => ({ IntofactoryID: item.IntofactoryID, IntofactoryNum: item.IntofactoryNum }))
                });
                // console.log('qutationPage', qutationPage);
              });
            };

            function onChangeCustomer(evt) {
              const { name, value } = evt.target;
              setFieldValue(name, value);
              fnGetIntofactoryAPI(value);
            }

            return (
              <form onSubmit={handleSubmit}>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                  open={openBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.CustomerID)}
                          helperText={errors.CustomerID}
                          select
                          fullWidth
                          name="CustomerID"
                          label="客戶"
                          value={values.CustomerID || ''}
                          onChange={(e) => onChangeCustomer(e)}
                          variant="outlined"
                        >
                          {qutationPage.CustomerData && qutationPage.CustomerData.map((option) => (
                            <MenuItem key={option.CustomerID} value={option.CustomerID}>
                              {option.CustomerName}
                            </MenuItem>
                          ))}
                        </TextField>
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.QutationDate)}
                          helperText={errors.QutationDate}
                          fullWidth
                          name="QutationDate"
                          label="日期"
                          value={values.QutationDate}
                          onChange={handleChange}
                          variant="outlined"
                          type="date"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="報價單號"
                          name="QutationNum"
                          value={values.QutationNum || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectWrapper
                          name="PayMethodID"
                          label="付款方式"
                          selectValue={values.PayMethodID || ''}
                          options={qutationPage.PayMethodData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.EstimatePayDate)}
                          helperText={errors.EstimatePayDate}
                          fullWidth
                          name="EstimatePayDate"
                          label="預結帳日"
                          value={values.EstimatePayDate}
                          onChange={handleChange}
                          variant="outlined"
                          type="date"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.PayDate)}
                          helperText={errors.PayDate}
                          fullWidth
                          name="PayDate"
                          label="付款時間"
                          value={values.PayDate}
                          onChange={handleChange}
                          variant="outlined"
                          type="date"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectWrapper
                          name="BillTypeID"
                          label="發票聯式"
                          selectValue={values.BillTypeID || ''}
                          options={qutationPage.BillTypeData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectWrapper
                          name="TaxTypeID"
                          label="課稅別"
                          selectValue={values.TaxTypeID || ''}
                          options={qutationPage.TaxTypeData}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          error={Boolean(errors.BillDate)}
                          helperText={errors.BillDate}
                          fullWidth
                          name="BillDate"
                          label="發票日期"
                          value={values.BillDate}
                          onChange={handleChange}
                          variant="outlined"
                          type="date"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          error={Boolean(errors.BillNumber)}
                          helperText={errors.BillNumber}
                          label="發票號碼"
                          name="BillNumber"
                          value={values.BillNumber || ''}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <Autocomplete
                          id="Intofactory"
                          name="Intofactory"
                          options={qutationPage.IntofactoryData}
                          // eslint-disable-next-line no-shadow
                          getOptionLabel={(option) => option.IntofactoryNum}
                          onChange={(e, value) => {
                            // console.log(value);
                            setFieldValue(
                              'Intofactory',
                              value || null
                            );
                          }}
                          value={values.Intofactory || null}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              error={Boolean(errors.Intofactory)}
                              helperText={errors.Intofactory}
                              label="入廠單號"
                              variant="outlined"
                              name="Intofactory"
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="備註"
                          name="Remark"
                          margin="normal"
                          value={values.Remark || ''}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting || isValidating}
                      color="primary"
                      variant="contained"
                    >
                      儲存
                    </Button>
                    <Button onClick={() => handleCancelClick(false)} variant="contained">
                      Cancel
                    </Button>
                  </Box>
                  <Fade in={fade}>
                    {isError
                      ? (
                        <Alert severity="error">
                          <AlertTitle>操作失敗</AlertTitle>
                          {errMsg || '請連絡相關人員'}
                        </Alert>
                      )
                      : (
                        <Alert severity="success">
                          <AlertTitle>操作成功</AlertTitle>
                        </Alert>
                      )}
                  </Fade>
                </Card>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

QutationDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired
};

export default QutationDialog;
