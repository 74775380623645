import React, { useCallback, useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Link,
  Grid,
  MenuItem,
  Tabs,
  Tab,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow
} from '@material-ui/core';
import {
  Alert, AlertTitle, Autocomplete, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiQutationService } from '../../api/qutationApi';
import { apiOpenService } from '../../api/openApi';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import SelectWrapper from '../formsUI/selectWrapper';
import useStyles from '../../theme/useStyles';
import QutationItemDialog from './qutationItemDialog';

function QutationForm() {
  const role = JSON.parse(sessionStorage.getItem('Staffme'));
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = params.id == null;
  const isCopy = params.key === 'copy';
  const [qutationPage, setQutationPage] = useState({
    CustomerData: [],
    PayMethodData: [],
    BillTypeData: [],
    TaxTypeData: []
  });
  const [intofactoryData, setIntofactoryData] = useState([]);
  const [errMsg, setErrMsg] = useState(null);
  const classes = useStyles();
  const [tab, setTab] = useState(0);

  // 品項_start
  const [qutationItemData, setQutationItemData] = useState({});
  const [qutationItemOpen, setQutationItemOpen] = useState(false);
  const qutationItemClickOpen = (data) => {
    setQutationItemData(data);
    setQutationItemOpen(true);
  };

  const qutationItemClose = () => {
    setQutationItemOpen(false);
    window.location.reload();
  };

  const deletequtationItem = (id) => {
    // console.log('QutationItem_Delete', id);
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiQutationService.apiQutationItemDelete(id, params.id)
        .then(() => {
          window.location.reload();
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('QutationItemDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };
  // 品項_end

  const schema = Yup.object().shape({
    CustomerID: Yup.string().required('選擇客戶'),
    QutationDate: Yup.date().required('日期必填'),
    PayMethodID: Yup.string().required('選擇付款方式'),
    EstimatePayDate: Yup.string().required('預結帳日必填'),
    PayDate: Yup.string().required('付款時間必填'),
    BillTypeID: Yup.number().required('選擇發票聯式'),
    TaxTypeID: Yup.string().required('選擇課稅別'),
    BillDate: Yup.string().required('發票日期必填'),
    BillNumber: Yup.string().when('BillTypeID', (val, str) => {
      try {
        return val === 4 ? str : str.required('發票號碼必填');
      } catch (e) {
        // console.log('BillNumber_e', e);
        return null;
      }
    }),
    Intofactory: Yup.string().required('選擇入廠單號')
  });

  function createQutation(fields, setSubmitting) {
    apiQutationService.apiQutationAdd(fields)
      .then((res) => {
        // console.log('createQutation_OK', res);
        setFade(true);
        setTimeout(() => navigate('/qutation', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('createQutation_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function updateQutation(parmaid, fields, setSubmitting) {
    apiQutationService.apiQutationUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateQutation_OK', res);
        setFade(true);
        setTimeout(() => navigate('/qutation', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('updateQutation_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function saveQutation(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (isAddMode || isCopy) {
      createQutation(fields, setSubmitting);
    } else {
      updateQutation(params.id, fields, setSubmitting);
    }
  }

  const tabChange = (event, newValue) => {
    localStorage.setItem('tabChange', newValue);
    setTab(newValue);
  };

  function TabPanel(props) {
    const {
      children, value, index, ...other
    } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ p: 3 }}>
            {children}
          </Box>
        )}
      </div>
    );
  }

  TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
  };

  return (
    <Formik
      initialValues={{
        QutationID: '',
        QutationNum: '',
        CustomerID: '',
        QutationDate: moment().format('YYYY-MM-DD'),
        PayMethodID: '',
        EstimatePayDate: moment().format('YYYY-MM-DD'),
        PayDate: moment().format('YYYY-MM-DD'),
        BillTypeID: '',
        TaxTypeID: '',
        BillDate: moment().format('YYYY-MM-DD'),
        BillNumber: '',
        Intofactory: '',
        Remark: '',
        QutationItems: []
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveQutation}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        isValidating,
        setFieldValue
      }) => {
        const fnGetIntofactoryAPI = async (val) => {
          await apiIntoFactoryService.apiGetIntoFactoryBaseNumList(val).then((res) => {
            if (res.data) {
              setIntofactoryData(res.data.map((item) => ({ IntofactoryID: item.IntofactoryID, IntofactoryNum: item.IntofactoryNum })));
            }
            // console.log('qutationPage', qutationPage);
          });
        };

        const fetchData = useCallback(() => {
          const fetchingData = async () => {
            // 取得客戶、業務選單
            const [CustomerData, PayMethodData, BillTypeData, TaxTypeData] = await Promise.all([
              apiOpenService.apiGetSelectCustomerData(),
              apiOpenService.apiGetSelectData('PayMethods'),
              apiOpenService.apiGetSelectData('BillTypes'),
              apiOpenService.apiGetSelectData('TaxTypes')
            ]);

            // 放進state
            setQutationPage({
              CustomerData,
              PayMethodData,
              BillTypeData,
              TaxTypeData
            });

            if (!isAddMode) {
              apiQutationService.apiGetQutationDetail(params.id).then((res) => {
                const fields = ['QutationNum', 'CustomerID', 'QutationDate', 'PayMethodID', 'EstimatePayDate', 'PayDate', 'BillTypeID', 'TaxTypeID', 'BillDate', 'BillNumber', 'Intofactory', 'Remark', 'QutationItems'];
                if (!isCopy) {
                  fields.push('QutationID');
                }
                fnGetIntofactoryAPI(res.data[0].CustomerInfo.CustomerID);
                fields.forEach((field) => {
                  switch (field) {
                    case 'CustomerID':
                      setFieldValue(field, res.data[0].CustomerInfo[field], false);
                      break;
                    case 'PayMethodID':
                      setFieldValue(field, res.data[0].PayMethod[field], false);
                      break;
                    case 'BillTypeID':
                      setFieldValue(field, res.data[0].BillType[field], false);
                      break;
                    case 'TaxTypeID':
                      setFieldValue(field, res.data[0].TaxType[field], false);
                      break;
                    case 'Intofactory':
                      setFieldValue(field, res.data[0].IntoFactoryBaseInfo, false);
                      break;
                    case 'EstimatePayDate':
                    case 'PayDate':
                    case 'BillDate':
                      if (res.data[0][field] === '') {
                        setFieldValue(field, moment('1970-01-01').format('YYYY-MM-DD'), false);
                      } else {
                        setFieldValue(field, res.data[0][field], false);
                      }
                      break;
                    default:
                      setFieldValue(field, res.data[0][field], false);
                      break;
                  }
                });
              });
            }
            setOpenBackdrop(false);
          };
          fetchingData();
        }, []);

        useEffect(() => {
          setOpenBackdrop(true);
          fetchData();
        }, []);

        function onChangeCustomer(evt) {
          const { name, value } = evt.target;
          setFieldValue(name, value);
          fnGetIntofactoryAPI(value);
        }

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title={(isAddMode || isCopy) ? '新增報價單' : '編輯報價單'}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.CustomerID)}
                      helperText={errors.CustomerID}
                      select
                      fullWidth
                      name="CustomerID"
                      label="客戶"
                      value={values.CustomerID || ''}
                      onChange={(e) => onChangeCustomer(e)}
                      variant="outlined"
                    >
                      {qutationPage.CustomerData && qutationPage.CustomerData.map((option) => (
                        <MenuItem key={option.CustomerID} value={option.CustomerID}>
                          {option.CustomerName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.QutationDate)}
                      helperText={errors.QutationDate}
                      fullWidth
                      name="QutationDate"
                      label="日期"
                      value={values.QutationDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="報價單號"
                      name="QutationNum"
                      value={values.QutationNum || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="PayMethodID"
                      label="付款方式"
                      selectValue={values.PayMethodID || ''}
                      options={qutationPage.PayMethodData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.EstimatePayDate)}
                      helperText={errors.EstimatePayDate}
                      fullWidth
                      name="EstimatePayDate"
                      label="預結帳日"
                      value={values.EstimatePayDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.PayDate)}
                      helperText={errors.PayDate}
                      fullWidth
                      name="PayDate"
                      label="付款時間"
                      value={values.PayDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="BillTypeID"
                      label="發票聯式"
                      selectValue={values.BillTypeID || ''}
                      options={qutationPage.BillTypeData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="TaxTypeID"
                      label="課稅別"
                      selectValue={values.TaxTypeID || ''}
                      options={qutationPage.TaxTypeData}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.BillDate)}
                      helperText={errors.BillDate}
                      fullWidth
                      name="BillDate"
                      label="發票日期"
                      value={values.BillDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.BillNumber)}
                      helperText={errors.BillNumber}
                      fullWidth
                      label="發票號碼"
                      name="BillNumber"
                      value={values.BillNumber || ''}
                      onChange={handleChange}
                      variant="outlined"
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <Autocomplete
                      id="Intofactory"
                      name="Intofactory"
                      options={intofactoryData}
                      // eslint-disable-next-line no-shadow
                      getOptionLabel={(option) => option.IntofactoryNum}
                      onChange={(e, value) => {
                        // console.log(value);
                        setFieldValue(
                          'Intofactory',
                          value || null
                        );
                      }}
                      value={values.Intofactory || null}
                      renderInput={(item) => (
                        <TextField
                          {...item}
                          error={Boolean(errors.Intofactory)}
                          helperText={errors.Intofactory}
                          label="入廠單號"
                          variant="outlined"
                          name="Intofactory"
                        />
                      )}
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="備註"
                      name="Remark"
                      margin="normal"
                      value={values.Remark || ''}
                      variant="outlined"
                    />
                  </Grid>
                </Grid>
              </CardContent>

              <Divider />

              <Box>
                <Tabs value={tab} onChange={tabChange}>
                  <Tab label="商品資料" id="simple-tab-0" />
                </Tabs>
              </Box>
              <TabPanel value={tab} index={0}>
                <Button
                  className={classes.AddButton}
                  size="small"
                  variant="contained"
                  onClick={() => qutationItemClickOpen({})}
                >
                  新增品項
                </Button>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell>
                        品號
                      </TableCell>
                      <TableCell>
                        品名
                      </TableCell>
                      <TableCell>
                        報價重量
                      </TableCell>
                      <TableCell>
                        報價克數
                      </TableCell>
                      <TableCell>
                        金額
                      </TableCell>
                      <TableCell>
                        備註
                      </TableCell>
                      <TableCell>
                        操作
                      </TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {values.QutationItems && values.QutationItems.map((item) => (
                      <TableRow
                        hover
                        key={item.QutationItemID}
                      >
                        <TableCell>
                          {item.Commodity.ItemID}
                        </TableCell>
                        <TableCell>
                          {item.Commodity.ItemName}
                        </TableCell>
                        <TableCell>
                          {item.Weight}
                        </TableCell>
                        <TableCell>
                          {item.Gram}
                        </TableCell>
                        <TableCell>
                          {item.Amount}
                        </TableCell>
                        <TableCell>
                          {item.Remark}
                        </TableCell>
                        <TableCell>
                          <Box>
                            <Button
                              className={classes.EditButton}
                              size="small"
                              variant="contained"
                              onClick={() => qutationItemClickOpen(item)}
                            >
                              編輯
                            </Button>
                            {role.Departments[0].DepartmentID === 1
                              ? (
                                <Button
                                  className={classes.DeleteButton}
                                  size="small"
                                  variant="contained"
                                  onClick={() => deletequtationItem(item.QutationItemID)}
                                >
                                  刪除
                                </Button>
                              )
                              : null}
                          </Box>
                        </TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
                <QutationItemDialog qutationID={params.id} customerID={values.CustomerID} reqData={qutationItemData} open={qutationItemOpen} onClose={qutationItemClose} />
              </TabPanel>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                <Button
                  type="submit"
                  disabled={isSubmitting || isValidating}
                  color="primary"
                  variant="contained"
                >
                  儲存
                </Button>
                <Link component={RouterLink} to="/qutation">
                  <Button color="secondary" variant="contained">
                    取消
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default QutationForm;
