import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@mui/material';
import QutationListResults from '../components/qutation/qutationListResults';
import QutationListToolbar from '../components/qutation/qutationListToolbar';
import { apiQutationService } from '../api/qutationApi';
import { apiOpenService } from '../api/openApi';

const QutationList = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [customerSelect, setCustomerSelect] = useState([]);

  const handleSearchChange = (value) => {
    setOpenBackdrop(true);
    apiQutationService.apiSearchQutation(value).then((res) => {
      setDataList(res.data.Qutations);
      setOpenBackdrop(false);
    });
  };

  useEffect(() => {
    setOpenBackdrop(true);
    apiOpenService.apiGetSelectCustomerData().then((res) => setCustomerSelect(res));
    apiQutationService.apiGetQutationList().then((x) => {
      setDataList(x.data.Qutations);
      setOpenBackdrop(false);
    });
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>報價單資料</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <QutationListToolbar customerSelect={customerSelect} onClick={handleSearchChange} />
          <Box sx={{ pt: 3 }}>
            <QutationListResults qutations={dataList} />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default QutationList;
