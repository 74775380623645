/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/prop-types */
import {
  Grid, TextField, MenuItem, Divider
} from '@material-ui/core';
import React, { useState, useEffect } from 'react';
import { useFormikContext } from 'formik';
import SelectWrapper from '../formsUI/selectWrapper';

const DetectionItems = ({
  index,
  data,
  selectValue,
  disabled
}) => {
  const { setFieldValue } = useFormikContext();
  const [arrElement, setArrElement] = useState([]);
  const [commodity, setCommodity] = useState(true);

  function onChangeItemID(evt) {
    // console.log('evt', evt);
    const { name, value } = evt.target;
    setFieldValue(name, value);
    let dataList = [];
    dataList = data.Data?.filter((item) => item.CommodityID === value);
    if (dataList.length > 0) {
      // console.log('dataList', dataList);
      setFieldValue(`DetectionItems[${index}].ItemName`, dataList[0].ItemName);
      setArrElement(dataList[0].Element);
      setCommodity(false);
    }
  }

  useEffect(() => {
    // console.log('DetectionItems_index', index);
    // console.log('DetectionItems_data', data);
    if (data.Data.length > 0 && data.Data[0]?.Element) {
      setArrElement(data.Data[0].Element);
      setCommodity(false);
    }
    // console.log('DetectionItems_arrElement', arrElement);
  }, [data]);

  return (
    <Grid container spacing={3} mb={3}>
      <Grid item xs={6}>
        <TextField
          select={data.Data.length > 0}
          fullWidth
          name={`DetectionItems[${index}].CommodityID`}
          label="品號"
          onChange={(e) => onChangeItemID(e)}
          value={selectValue.CommodityID || ''}
          variant="outlined"
          disabled={disabled || commodity}
        >
          {data.Data.length && data.Data.map((option) => (
            <MenuItem key={option.CommodityID} value={option.CommodityID}>
              {option.ItemID}
              -
              {option.ItemName}
            </MenuItem>
          ))}
        </TextField>
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="品名"
          name={`DetectionItems[${index}].ItemName`}
          value={selectValue.ItemName || ''}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="樣品編號"
          name={`DetectionItems[${index}].SampleID`}
          value={selectValue.SampleID || ''}
          variant="outlined"
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        {disabled ? (
          <TextField
            fullWidth
            label="元素"
            name={`DetectionItems[${index}].Element`}
            value={selectValue.Element || ''}
            variant="outlined"
            disabled
          />
        ) : (
          <SelectWrapper
            name={`DetectionItems[${index}].ElementID`}
            label="元素"
            selectValue={selectValue.ElementID || ''}
            options={arrElement}
            index={index}
            disabled={disabled}
          />
        )}
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="檢測值"
          name={`DetectionItems[${index}].Value`}
          value={disabled ? selectValue.Value : '0'}
          variant="outlined"
          defaultValue="0"
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="檢測重量"
          name={`DetectionItems[${index}].DetectionWeight`}
          value={disabled ? selectValue.DetectionWeight : '0'}
          variant="outlined"
          defaultValue="0"
          disabled
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="定量"
          name={`DetectionItems[${index}].Volume`}
          value={disabled ? selectValue.Volume : '0'}
          variant="outlined"
          defaultValue="0"
          disabled
        />
      </Grid>
      <Grid item xs={12}>
        <Divider />
      </Grid>
    </Grid>
  );
};

export default DetectionItems;
