import React, {
  useState, useEffect, useRef, useCallback
} from 'react';
import PropTypes from 'prop-types';
import {
  Box, Button, Card, CardContent, DialogTitle, Dialog, DialogContent, Grid, TextField
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { apiIntoFactoryService } from '../../api/intoFactoryApi';
import { apiOpenService } from '../../api/openApi';
import SelectLink from '../formsUI/selectLink';
import SelectWrapper from '../formsUI/selectWrapper';

function CommodityDialog(props) {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const { onClose, open, reqData } = props;
  const [commodity, setCommodity] = useState([]);
  const isTrueState = [
    {
      IsTrueID: '1',
      IsTrue: '是',
    },
    {
      IsTrueID: '0',
      IsTrue: '否',
    },
  ];
  const [errMsg, setErrMsg] = useState(null);
  const infactoryData = useRef(null);

  const schema = Yup.object().shape({
    CommodityID: Yup.string().required('品號必填')
  });

  function createIntoFactory(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    apiIntoFactoryService.apiIntoFactoryitemAdd(fields)
      .then((res) => {
        // console.log('createIntoFactoryItem_OK', res);
        setFade(true);
        setOpenBackdrop(false);
        setTimeout(() => onClose(), 2000);
      })
      .catch((error) => {
        // console.log('createIntoFactoryItem_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }

  function handleCancelClick(value) {
    // console.log('handleCancelClick', value);
    onClose(value);
  }

  return (
    <Dialog scroll="paper" fullWidth maxWidth="xl" aria-labelledby="simple-dialog-title" open={open}>
      <DialogTitle id="simple-dialog-title">新增入廠單商品</DialogTitle>
      <DialogContent>
        <Formik
          initialValues={{
            IntofactoryID: '',
            CommodityID: '',
            ItemName: '',
            Declare: '0',
            Remark: '',
            RealWeight: '',
            Weight: ''
          }}
          validationSchema={schema}
          // eslint-disable-next-line react/jsx-no-bind
          onSubmit={createIntoFactory}
        >
          {({
            errors,
            handleChange,
            handleSubmit,
            isSubmitting,
            values,
            isValidating,
            setFieldValue
          }) => {
            const fetchData = useCallback(() => {
              const fetchingData = async () => {
                await apiOpenService.apiGetSelectCommodityData(infactoryData.current[0].CustomerID, 1).then((res) => {
                  // console.log('res', res);
                  setCommodity(res);
                  setOpenBackdrop(false);
                });
                setOpenBackdrop(false);
              };
              fetchingData();
            }, []);

            useEffect(() => {
              // console.log('CommodityDialog_useEffect');
              if (reqData) {
                infactoryData.current = reqData;
                setFieldValue('IntofactoryID', infactoryData.current[0].IntofactoryID);
                fetchData();
              }
              setOpenBackdrop(true);
            }, []);

            return (
              <form onSubmit={handleSubmit}>
                <Backdrop
                  sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
                  open={openBackdrop}
                >
                  <CircularProgress color="inherit" />
                </Backdrop>
                <Card>
                  <CardContent>
                    <Grid container spacing={3}>
                      <Grid item xs={6}>
                        <SelectLink
                          fullWidth
                          label="品號"
                          name="CommodityID"
                          selectValue={values.CommodityID || ''}
                          options={commodity}
                          disabled={commodity == null}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="品名"
                          name="ItemName"
                          value={values.ItemName || ''}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="識別序號"
                          name=""
                          value=""
                          onChange={handleChange}
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <SelectWrapper
                          fullWidth
                          label="申報"
                          name="Declare"
                          selectValue={values.Declare || ''}
                          options={isTrueState}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="實際重量"
                          name="RealWeight"
                          value={values.RealWeight || ''}
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="報價重量"
                          name="Weight"
                          value={values.Weight || ''}
                          variant="outlined"
                          onChange={handleChange}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="報價克數"
                          name="Gram"
                          value=""
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          label="換算濃度"
                          name="Concentration"
                          value=""
                          variant="outlined"
                          disabled
                        />
                      </Grid>
                      <Grid item xs={12}>
                        <TextField
                          fullWidth
                          label="備註"
                          name="Remark"
                          margin="normal"
                          value={values.Remark || ''}
                          onChange={handleChange}
                          variant="outlined"
                        />
                      </Grid>
                    </Grid>
                  </CardContent>

                  <Box
                    sx={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      p: 2
                    }}
                  >
                    <Button
                      type="submit"
                      disabled={isSubmitting || isValidating}
                      color="primary"
                      variant="contained"
                    >
                      儲存
                    </Button>
                    <Button onClick={() => handleCancelClick(false)} variant="contained">
                      Cancel
                    </Button>
                  </Box>
                  <Fade in={fade}>
                    {isError
                      ? (
                        <Alert severity="error">
                          <AlertTitle>操作失敗</AlertTitle>
                          {errMsg || '請連絡相關人員'}
                        </Alert>
                      )
                      : (
                        <Alert severity="success">
                          <AlertTitle>操作成功</AlertTitle>
                        </Alert>
                      )}
                  </Fade>
                </Card>
                {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
                {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
              </form>
            );
          }}
        </Formik>
      </DialogContent>
    </Dialog>
  );
}

CommodityDialog.propTypes = {
  onClose: PropTypes.func.isRequired,
  open: PropTypes.bool.isRequired,
  reqData: PropTypes.object,
};

export default CommodityDialog;
