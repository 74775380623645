// import { selectStaff } from 'src/__mocks__/staff';
import fetchWrapper from './fetch-wrapper';

// 取得所有員工資料
function apiGetStaffAll() {
  // console.log('Staff_GetAll');
  return fetchWrapper.get('staff/?Page=1&Pagesize=500');
}

// 取得登入員工資料
function apiGetStaffMe() {
  // console.log('Staff_Me');
  return fetchWrapper.get('staff/me');
}

// 搜尋員工
function apiSearchStaff(StaffID, AccName) {
  let strURL = '';
  if (StaffID !== '') {
    strURL = strURL.concat('StaffID=', StaffID);
  }
  if (AccName !== '') {
    strURL = strURL.concat('Account=', AccName);
    strURL = strURL.concat('&Name=', AccName);
  }
  // console.log('Staff_Search', strURL);
  return fetchWrapper.get(`staff/?${strURL}`);
}

function apiStaffAdd(data) {
  // console.log('Staff_Add', data);
  return fetchWrapper.post('staff/create', data);
}

function apiStaffUpdate(parmaid, data) {
  const body = {
    ...data,
    staffid: parseInt(parmaid, 10),
  };
  // console.log('Staff_Update_body', body);
  return fetchWrapper.put('staff/update', body);
}

function apiStaffDelete(id) {
  // console.log('Staff_Delete', id);
  const body = { StaffID: id };
  return fetchWrapper.delete('staff/delete', body);
}

export const apiStaffService = {
  apiGetStaffAll,
  apiGetStaffMe,
  apiSearchStaff,
  apiStaffAdd,
  apiStaffUpdate,
  apiStaffDelete
};
