import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import VenderListResults from '../components/vender/venderListResults';
import VenderListToolbar from '../components/vender/venderListToolbar';
import { apiVenderService } from '../api/venderApi';

const VenderList = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [errMsg, setErrMsg] = useState(null);

  const handleSearchChange = (value) => {
    // console.log('VenderList_Search', value);
    setOpenBackdrop(true);
    apiVenderService.apiSearchVender('', value).then((res) => {
      setDataList(res.data);
      setOpenBackdrop(false);
    });
  };

  const handleDataListClick = (id) => {
    // console.log('VenderList_Delete', id);
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiVenderService.apiVenderDelete(id)
        .then((res) => {
          // console.log('VenderListDelete_OK', res);
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('VenderListDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  };

  useEffect(() => {
    // console.log('VenderList_start');
    setOpenBackdrop(true);
    apiVenderService.apiGetVenderAll().then((x) => {
      setDataList(x.data);
      setOpenBackdrop(false);
    });
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>廠商資料</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <VenderListToolbar onClick={handleSearchChange} />
          <Fade in={fade}>
            {isError
              ? (
                <Alert severity="error">
                  <AlertTitle>刪除失敗</AlertTitle>
                  {errMsg || '請連絡相關人員'}
                </Alert>
              )
              : (
                <Alert severity="success">
                  <AlertTitle>刪除成功</AlertTitle>
                </Alert>
              )}
          </Fade>
          <Box sx={{ pt: 3 }}>
            <VenderListResults venders={dataList} onClick={handleDataListClick} />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default VenderList;
