/* eslint-disable react/prop-types */
/* eslint-disable react/destructuring-assignment */
import React from 'react';
import {
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
import { useField } from 'formik';

const Department = (props) => {
  const [field] = useField({
    ...props
  });

  const handleChange = (event) => {
    const target = event.currentTarget;
    const valueArray = field.value;
    if (target.checked) {
      valueArray.push(parseInt(target.id, 10));
    } else {
      valueArray.splice(valueArray.indexOf(parseInt(target.id, 10)), 1);
    }
    props.onChange(props.name, valueArray);
  };

  return (
    <FormControlLabel
      key={props.data.DepartmentID}
      control={(
        <Checkbox
          id={props.data.DepartmentID}
          name={props.name}
          checked={field.value.includes(props.data.DepartmentID)}
          onChange={handleChange}
          value={field.value && field.value}
        />
  )}
      label={props.data.Department}
    />
  );
};

export default Department;
