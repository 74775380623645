import React, { useState, useEffect } from 'react';
import {
  Box, Typography, Button, ListItem, Grid
} from '@material-ui/core';
import UploadFilesService from '../../api/UploadFilesService';

const UploadFiles = (props) => {
  // console.log('filedata', props);
  const { data, filedata } = props;
  const [selectedFiles, setSelectedFiles] = useState(undefined);
  const [message, setMessage] = useState('');
  const [isError, setIsError] = useState(false);
  const [fileInfos, setFileInfos] = useState([]);

  const selectFile = (event) => {
    // console.log('event', event);
    setSelectedFiles(event.target.files);
  };

  function getArrayBuffer(file) {
    return new Promise((resolve, reject) => {
      // STEP 3: 轉成 ArrayBuffer, i.e., reader.result
      const reader = new FileReader();
      reader.addEventListener('load', () => {
        resolve(reader.result);
      });
      reader.readAsArrayBuffer(file);
    });
  }

  const upload = async () => {
    // console.log('selectedFiles', selectedFiles[0]);
    const getCurrentFile = await getArrayBuffer(selectedFiles[0]);
    if (data.DetectionID !== undefined) {
      await UploadFilesService.uploadDetection(data, getCurrentFile, selectedFiles[0].name)
        .then((response) => {
          // console.log('response', response);
          setMessage(response.msg);
          setIsError(false);
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {
          setMessage(error);
          setIsError(true);
        });
    } else if (data.CommodityID !== undefined) {
      await UploadFilesService.uploadIntoFactoryItem(data, getCurrentFile, selectedFiles[0].name)
        .then((response) => {
          // console.log('response', response);
          setMessage(response.msg);
          setIsError(false);
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {
          setMessage(error);
          setIsError(true);
        });
    } else {
      await UploadFilesService.uploadIntoFactoryBase(data, getCurrentFile, selectedFiles[0].name)
        .then((response) => {
          // console.log('response', response);
          setMessage(response.msg);
          setIsError(false);
          setTimeout(() => window.location.reload(), 2000);
        })
        .catch((error) => {
          setMessage(error);
          setIsError(true);
        });
    }

    setSelectedFiles(undefined);
  };

  function download(item) {
    if (data.DetectionID !== undefined) {
      UploadFilesService.getFilesDetection(data.DetectionID, item);
    } else if (data.CommodityID !== undefined) {
      UploadFilesService.getFilesIntoFactoryItem(data.IntofactoryID, data.CommodityID, item);
    } else {
      UploadFilesService.getFilesIntoFactoryBase(data.IntofactoryID, item);
    }
  }

  function fnDelete(item) {
    if (window.confirm('確定要刪除檔案嗎?')) {
      if (data.DetectionID !== undefined) {
        UploadFilesService.delFilesDetection(data.DetectionID, item)
          .then((res) => {
            setTimeout(() => window.location.reload(), 2000);
          });
      } else if (data.CommodityID !== undefined) {
        UploadFilesService.delFilesIntoFactoryItem(data.IntofactoryID, data.CommodityID, item)
          .then((res) => {
            setTimeout(() => window.location.reload(), 2000);
          });
      } else {
        UploadFilesService.delFilesIntoFactoryBase(data.IntofactoryID, item)
          .then((res) => {
            setTimeout(() => window.location.reload(), 2000);
          });
      }
    }
  }

  useEffect(() => {
    // console.log('upload_data', data);
    // console.log('fileInfos', fileInfos);
    setFileInfos(filedata);
  }, [filedata]);

  return (
    <div className="mg20">
      <Grid container spacing={1}>
        <Grid item xs={2}>
          <label htmlFor="btn-upload">
            <input
              id="btn-upload"
              name="btn-upload"
              style={{ display: 'none' }}
              type="file"
              onChange={selectFile}
            />
            <Button
              className="btn-choose"
              variant="outlined"
              component="span"
            >
              請選擇上傳檔案
            </Button>
          </label>
        </Grid>
        <Grid item xs={2}>
          <Button
            className="btn-upload"
            color="primary"
            variant="contained"
            component="span"
            disabled={!selectedFiles}
            onClick={upload}
          >
            確定上傳
          </Button>
        </Grid>
        <Grid item xs={2}>
          <Typography
            align="center"
            color="textPrimary"
            variant="body1"
          >
            {selectedFiles && selectedFiles[0]?.name}
          </Typography>
        </Grid>
        <Grid item xs={3}>
          <Typography variant="subtitle2" className={`upload-message ${isError ? 'error' : ''}`}>
            {message}
          </Typography>
        </Grid>
      </Grid>
      <Box mt={2} sx={{ p: 2, border: '1px dashed grey', width: '50%' }}>
        <Typography variant="h4">
          檔案清單
        </Typography>
        <ul>
          {fileInfos[0]
            && fileInfos.map((file, index) => (
              <ListItem
                divider
                // eslint-disable-next-line react/no-array-index-key
                key={index}
              >
                <Button onClick={() => download(file)}>{file}</Button>
                <Button
                  size="small"
                  variant="contained"
                  onClick={() => fnDelete(file)}
                >
                  刪除
                </Button>
              </ListItem>
            ))}
        </ul>
      </Box>
    </div>
  );
};

export default UploadFiles;
