import React, {
  useCallback, useEffect, useState, useRef
} from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Divider,
  TextField,
  Link,
  Grid,
  MenuItem
} from '@material-ui/core';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import { Link as RouterLink, useParams, useNavigate } from 'react-router-dom';
import { apiDetectionService } from '../../api/detectionApi';
import { apiOpenService } from '../../api/openApi';
import { apiCommodityService } from '../../api/commodityApi';
import DetectionItems from './detectionItems';
import SelectWrapper from '../formsUI/selectWrapper';
import UploadFiles from '../formsUI/FileUpload';

function DetectionForm() {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const [errMsg, setErrMsg] = useState(null);
  const params = useParams();
  const navigate = useNavigate();
  const isAddMode = params.id == null;
  const isCopy = params.key === 'copy';
  const initArrDetectionItems = useRef();
  const [detectionPage, setDetectionPage] = useState({
    customerSelect: [],
    detectionTypeSelect: [],
    sampleStaffSelect: []
  });
  const [itemsState, setItemsState] = useState([]);
  const [arrfile, setArrfile] = useState([]);

  const schema = Yup.object().shape({
    CustomerID: Yup.string().required('選擇客戶'),
    CommissionDate: Yup.date().required('委託日期必填'),
    DetectionTypeID: Yup.string().required('請選擇檢測類型'),
    SampleStaffID: Yup.string().required('請選擇取樣人員')
  });

  function createDetection(fields, setSubmitting) {
    apiDetectionService.apiDetectionAdd(fields)
      .then((res) => {
        // console.log('createDetection_OK', res);
        setFade(true);
        setTimeout(() => navigate('/detection', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('createDetection_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }
  /*
  function updateDetection(parmaid, fields, setSubmitting) {
    apiDetectionService.apiDetectionUpdate(parmaid, fields)
      .then((res) => {
        // console.log('updateDetection_OK', res);
        setFade(true);
        setTimeout(() => navigate('/detection', { replace: true }), 2000);
        setOpenBackdrop(false);
      })
      .catch((error) => {
        // console.log('updateDetection_Err', error);
        if (error !== '') { setErrMsg(error); }
        setSubmitting(false);
        setOpenBackdrop(false);
        setIsError(true);
        setFade(true);
      });
  }
*/
  function saveDetection(fields, { setSubmitting }) {
    setOpenBackdrop(true);
    if (isAddMode || isCopy) {
      createDetection(fields, setSubmitting);
    } else {
      // updateDetection(params.id, fields, setSubmitting);
      setOpenBackdrop(false);
    }
  }

  function onClickAddDetectionItems() {
    const arrItems = itemsState;
    arrItems.push(initArrDetectionItems.current);
    setItemsState([...arrItems]);
    // console.log('onClickAddDetectionItems', itemsState);
  }

  // 移除品項刪除按鈕，砍整張
  function onClickDeleteDetection(id) {
    // console.log('DetectionItems_Delete', id);
    if (window.confirm('確定要刪除嗎?')) {
      setOpenBackdrop(true);
      apiDetectionService.apiDetectionDelete(id)
        .then((res) => {
          // console.log('DetectionItemsDelete_OK', res);
          setFade(true);
          setTimeout(() => window.location.reload(), 2000);
          setOpenBackdrop(false);
        })
        .catch((error) => {
          // console.log('DetectionItemsDelete_Err', error);
          if (error !== '') { setErrMsg(error); }
          setOpenBackdrop(false);
          setIsError(true);
          setFade(true);
        });
    }
  }

  function handleLabel(DetectionID) {
    const session = sessionStorage.getItem('token');
    localStorage.setItem('token', session);
    window.open(`/label/${DetectionID}/0/2`, '_blank');
  }

  return (
    <Formik
      initialValues={{
        IntofactoryID: 0,
        DetectionID: '',
        CustomerID: '',
        CommissionDate: moment().format('YYYY-MM-DD'),
        DetectionTypeID: '',
        SampleStaffID: '',
        DetectStaffID: '',
        DetectionMethodID: 1,
        DetectionItems: [],
        ReportDate: '',
        Remark: '',
        DetectionRecipt: ''
      }}
      validationSchema={schema}
      // eslint-disable-next-line react/jsx-no-bind
      onSubmit={saveDetection}
    >
      {({
        errors,
        handleChange,
        handleSubmit,
        isSubmitting,
        values,
        isValidating,
        setFieldValue,
        setValues
      }) => {
        const fnGetCommodityAPI = async (val) => {
          await apiCommodityService.apiGetCustomerToCommodity(val).then((res) => {
            // console.log('CommodityAPI_res', res);
            /* const arrCommodity = res.map((i) => ({
              CommodityID: i.CommodityID,
              ItemID: i.ItemID,
              ItemName: i.ItemName
            }));
            const arrElement = res.Element.map((i) => ({
              ElementID: i.ElementID,
              Element: i.Element
            })); */
            initArrDetectionItems.current = { Data: res };
            const num = itemsState.length;
            itemsState.length = 0;
            for (let i = 0; i < num; i++) {
              onClickAddDetectionItems();
            }
          });
        };

        const fetchData = useCallback(() => {
          const fetchingData = async () => {
            // 取得所有下拉選單
            const [customerSelect, detectionTypeSelect, sampleStaffSelect] = await Promise.all([
              apiOpenService.apiGetSelectCustomerData(), // 客戶
              apiOpenService.apiGetSelectData('DetectionTypes').then((res) => res.filter((data) => data.DetectionTypeID !== 1)), // 檢測類型
              apiOpenService.apiGetSelectStaffData('DepartmentID=5'), // 工程師
            ]);

            // 初始化品項
            initArrDetectionItems.current = {
              // Commodity: [],
              // Element: [],
              Data: []
            };

            // 載入所有下拉選單
            setDetectionPage({
              customerSelect,
              detectionTypeSelect,
              sampleStaffSelect
            });
            setItemsState([initArrDetectionItems.current]);
            if (isAddMode) setOpenBackdrop(false);
            // 瀏覽模式(只能刪除無法編輯)
            if (!isAddMode) {
              apiDetectionService.apiSearchDetectionByID(params.id).then((res) => {
                for (let i = 1; i <= res.data[0].DetectionItems.length; i++) {
                  onClickAddDetectionItems(); // 增加品項
                }
                const arrFile = res.data[0].DetectionRecipt?.split(',');
                // console.log('arrFile', arrFile);
                setFieldValue('DetectionRecipt', arrFile, false);
                setArrfile(arrFile);
                const fields = ['CustomerID', 'DetectionTypeID', 'CommissionDate', 'SampleStaffID', 'DetectStaffID', 'DetectionItems', 'Remark'];
                if (!isCopy) {
                  fields.push('DetectionID');
                  fields.push('ReportDate');
                }
                fnGetCommodityAPI(res.data[0].DetectionItems[0].Commodity.CustomerInfo.CustomerID);
                // console.log('SearchDetectionByID_res', res);
                fields.forEach((field) => {
                  switch (field) {
                    case 'CustomerID':
                      setFieldValue(field, res.data[0].DetectionItems[0].Commodity.CustomerInfo.CustomerID, false);
                      break;
                    case 'DetectionTypeID':
                      setFieldValue(field, res.data[0].DetectionType.DetectionTypeID, false);
                      break;
                    case 'CommissionDate':
                      setFieldValue(field, moment(res.data[0].CommissionDate).format('YYYY-MM-DD'), false);
                      break;
                    case 'SampleStaffID':
                      setFieldValue(field, res.data[0].DetectionItems[0].DetectionSamples[0].SampleStaff.StaffID, false);
                      break;
                    case 'DetectStaffID':
                      setFieldValue(field, res.data[0].DetectStaff.StaffID, false);
                      break;
                    case 'DetectionItems': {
                      const arr = [];
                      res.data[0].DetectionItems.forEach((val) => {
                        arr.push({
                          DetectionItemID: val.DetectionItemID,
                          CommodityID: val.Commodity.CommodityID,
                          ItemName: val.Commodity.ItemName,
                          ElementID: val.DetectionSamples[0].DetectionElements[0].Element.ElementID,
                          Element: val.DetectionSamples[0].DetectionElements[0].Element.Element,
                          SampleID: val.DetectionSamples[0].SampleID,
                          Value: val.DetectionSamples[0].DetectionElements[0].Value,
                          DetectionWeight: val.DetectionSamples[0].DetectionElements[0].DetectionWeight,
                          Volume: val.DetectionSamples[0].DetectionElements[0].Volume,
                        });
                      });
                      setFieldValue(field, arr, false);
                      break;
                    }
                    case 'ReportDate':
                      setFieldValue(field, moment(res.data[0].ReportDate).format('YYYY-MM-DD'), false);
                      break;
                    default:
                      setFieldValue(field, res.data[0][field], false);
                      break;
                  }
                });
                setOpenBackdrop(false);
              });
            }
          };
          fetchingData();
        }, []);

        useEffect(() => {
          setOpenBackdrop(true);
          fetchData();

          return () => {
            initArrDetectionItems.current = null;
          };
        }, [fetchData]);

        function onChangeCustomer(evt) {
          const { name, value } = evt.target;
          setFieldValue(name, value);
          fnGetCommodityAPI(value);
        }

        return (
          <form onSubmit={handleSubmit}>
            <Backdrop
              sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
              open={openBackdrop}
            >
              <CircularProgress color="inherit" />
            </Backdrop>
            <Card>
              <CardHeader
                title={isAddMode || isCopy ? '新增檢測' : '瀏覽檢測'}
              />
              <CardContent>
                <Grid container spacing={3}>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.CustomerID)}
                      helperText={errors.CustomerID}
                      select
                      fullWidth
                      name="CustomerID"
                      label="客戶"
                      value={values.CustomerID || ''}
                      onChange={(e) => onChangeCustomer(e)}
                      variant="outlined"
                      disabled={!(isAddMode || isCopy)}
                    >
                      {detectionPage.customerSelect && detectionPage.customerSelect.map((option) => (
                        <MenuItem key={option.CustomerID} value={option.CustomerID}>
                          {option.CustomerName}
                        </MenuItem>
                      ))}
                    </TextField>
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      error={Boolean(errors.CommissionDate)}
                      helperText={errors.CommissionDate}
                      fullWidth
                      name="CommissionDate"
                      label="委託日期"
                      value={values.CommissionDate}
                      onChange={handleChange}
                      variant="outlined"
                      type="date"
                      disabled={!(isAddMode || isCopy)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="檢測單號"
                      name="DetectionID"
                      value={values.DetectionID || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      label="檢測類型"
                      name="DetectionTypeID"
                      selectValue={values.DetectionTypeID || ''}
                      options={detectionPage.detectionTypeSelect}
                      disabled={!(isAddMode || isCopy)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <SelectWrapper
                      name="SampleStaffID"
                      label="取樣人員"
                      selectValue={values.SampleStaffID || ''}
                      options={detectionPage.sampleStaffSelect}
                      disabled={!(isAddMode || isCopy)}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="分析人員"
                      name="DetectStaffID"
                      value={values.DetectStaffID || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth
                      label="報告日期"
                      name="ReportDate"
                      value={values.ReportDate || ''}
                      variant="outlined"
                      disabled
                    />
                  </Grid>
                  <Grid item xs={12}>
                    <TextField
                      fullWidth
                      label="備註"
                      name="Remark"
                      margin="normal"
                      value={values.Remark || ''}
                      onChange={handleChange}
                      variant="outlined"
                      disabled={!(isAddMode || isCopy)}
                    />
                  </Grid>
                  {params.id === undefined ? null
                    : (
                      <Grid item xs={12}>
                        <UploadFiles data={values} filedata={arrfile} />
                      </Grid>
                    )}
                </Grid>
              </CardContent>

              <Divider />

              <CardContent>
                {(isAddMode || isCopy) && (
                  <Box mb={2}>
                    <Button variant="contained" color="primary" onClick={() => onClickAddDetectionItems(values, setValues)}>
                      新增品項
                    </Button>
                  </Box>
                )}
                <Box>
                  {
                    itemsState.map((item, i) => (
                      <Box mb={3}>
                        {/* <Box mb={3}>
                          <Button variant="contained" color="primary" onClick={() => onClickDeleteDetection(values.DetectionItems[i].DetectionID)}>
                            刪除品項
                          </Button>
                        </Box> */}
                        <Box mb={3}>
                          <DetectionItems
                            // eslint-disable-next-line react/no-array-index-key
                            key={i}
                            index={i}
                            data={item}
                            selectValue={values.DetectionItems[i] || ''}
                            disabled={!(isAddMode || isCopy)}
                          />
                        </Box>
                      </Box>
                    ))
                  }
                </Box>
              </CardContent>

              <Divider />

              <Box
                sx={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  p: 2
                }}
              >
                {isAddMode || isCopy ? (
                  <Button
                    type="submit"
                    disabled={isSubmitting || isValidating}
                    color="primary"
                    variant="contained"
                  >
                    儲存
                  </Button>
                )
                  : (
                    <Button size="small" variant="contained" onClick={() => { handleLabel(params.id); }}>
                      條碼列印
                    </Button>
                  )}
                <Link component={RouterLink} to="/detection">
                  <Button color="secondary" variant="contained">
                    Cancel
                  </Button>
                </Link>
              </Box>
              <Fade in={fade}>
                {isError
                  ? (
                    <Alert severity="error">
                      <AlertTitle>操作失敗</AlertTitle>
                      {errMsg || '請連絡相關人員'}
                    </Alert>
                  )
                  : (
                    <Alert severity="success">
                      <AlertTitle>操作成功</AlertTitle>
                    </Alert>
                  )}
              </Fade>
            </Card>
            {/* <pre>{JSON.stringify(errors, null, 4)}</pre> */}
            {/* <pre>{JSON.stringify(values, null, 4)}</pre> */}
          </form>
        );
      }}
    </Formik>
  );
}

export default DetectionForm;
