import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from 'src/theme/useStyles';

const CustomerListResults = ({ customers, ...rest }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card>
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                客戶代號
              </TableCell>
              <TableCell>
                客戶簡稱
              </TableCell>
              <TableCell>
                電話/分機
              </TableCell>
              <TableCell>
                主聯絡人
              </TableCell>
              <TableCell>
                手機
              </TableCell>
              <TableCell>
                業務
              </TableCell>
              <TableCell>
                地址
              </TableCell>
              <TableCell>
                操作
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {customers && customers.slice(page * limit, page * limit + limit).map((customer) => (
              <TableRow
                hover
                key={customer.CustomerID}
              >
                <TableCell>
                  {customer.CustomerKey}
                </TableCell>
                <TableCell>
                  {customer.CustomerN}
                </TableCell>
                <TableCell>
                  {customer.Tel}
                </TableCell>
                <TableCell>
                  {customer.ContactName}
                </TableCell>
                <TableCell>
                  {customer.Phone}
                </TableCell>
                <TableCell>
                  {customer.BusinessStaff.Name}
                </TableCell>
                <TableCell>
                  {customer.Address}
                </TableCell>
                <TableCell>
                  <Box className={classes.TextBox}>
                    <Link component={RouterLink} to={`${customer.CustomerID}/copy`}>
                      <Button
                        className={classes.CopyButton}
                        size="small"
                        variant="contained"
                      >
                        複製
                      </Button>
                    </Link>
                    <Link component={RouterLink} to={`${customer.CustomerID}/edit`}>
                      <Button
                        className={classes.EditButton}
                        size="small"
                        variant="contained"
                      >
                        編輯
                      </Button>
                    </Link>
                    <Button
                      className={classes.DeleteButton}
                      size="small"
                      variant="contained"
                      onClick={() => rest.onClick(customer.CustomerID)}
                    >
                      刪除
                    </Button>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={customers ? customers.length : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

CustomerListResults.propTypes = {
  customers: PropTypes.array
};

export default CustomerListResults;
