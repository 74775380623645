/* eslint-disable react/prop-types */
import React from 'react';
import { TextField, MenuItem } from '@material-ui/core';
import { useField, useFormikContext } from 'formik';

const SelectLink = ({
  name,
  options,
  selectValue,
  index,
  ...otherProps
}) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta] = useField(name);
  const isSelect = options && true;

  const handleChange = (evt) => {
    // console.log('evt', evt);
    const { value } = evt.target;
    let data = [];
    switch (name) {
      case 'CustomerID':
        data = options.filter((item) => item.CustomerID === value);
        setFieldValue(name, value);
        setFieldValue('Customer', data[0].CustomerName);
        setFieldValue('BusinessStaffName', data[0].BusinessStaffName);
        break;
      case 'RecipientStaffID':
        data = options.filter((item) => item.StaffID === value);
        setFieldValue(name, value);
        break;
      case 'CommodityID':
        data = options.filter((item) => item.CommodityID === value);
        setFieldValue(name, value);
        setFieldValue('ItemName', data[0].ItemName);
        break;
      default:
        setFieldValue(name, value);
        break;
    }
  };

  const configSelect = {
    ...field,
    ...otherProps,
    select: isSelect,
    variant: 'outlined',
    fullWidth: true,
    onChange: handleChange,
    value: selectValue
  };

  if (meta && meta.touched && meta.error) {
    configSelect.error = true;
    configSelect.helperText = meta.error;
  }
  // console.log(`selectValue${name}`, selectValue);
  // console.log(`SelectLink_${name}`, options);

  return (
    <TextField {...configSelect}>
      {options && options.map((option) => {
        let optionKey = '';
        let optionvalue = '';
        switch (name) {
          case 'CustomerID':
            optionKey = option.CustomerID;
            optionvalue = `${option.CustomerKey}-${option.CustomerName}`;
            break;
          case 'RecipientStaffID':
            optionKey = option.StaffID;
            optionvalue = option.Name;
            break;
          case 'CommodityID':
            optionKey = option.CommodityID;
            optionvalue = `${option.ItemID}-${option.ItemName}`;
            break;
          case `DetectionItems[${index}].ItemID`:
            optionKey = option.CommodityID;
            optionvalue = option.ItemID;
            break;
          default:
            break;
        }
        return (
          <MenuItem key={optionKey} value={optionKey}>
            {optionvalue}
          </MenuItem>
        );
      })}
    </TextField>
  );
};

export default SelectLink;
