import fetchWrapper from './fetch-wrapper';

// 取得實驗列表
function apiGetExperimentAll() {
  // console.log('Experiment_GetAll');
  return fetchWrapper.get('detection/getExperimentList?Page=1&Pagesize=500');
}

// 實驗室人員操作細節資料
function apiGetExperimentByID(id) {
  // console.log('Experiment_ID');
  return fetchWrapper.get(`detection/getDetectionExperiment?DetectionID=${id}`);
}

// 實驗室人員輸入
function apiExperimentAdd(data) {
  // console.log('Experiment_ADDdata', data);
  const body = data.KeyExperiments.map((obj) => ({
    ...obj,
    Value: parseFloat(obj.Value),
    DetectionWeight: parseFloat(obj.DetectionWeight) || 0,
    Volume: parseFloat(obj.Volume) || 0,
  }));
  // console.log('Experiment_ADDbody', { KeyExperiments: body });
  return fetchWrapper.put('detection/keyExperiment', { KeyExperiments: body });
}

export const apiExperimentService = {
  apiGetExperimentAll,
  apiGetExperimentByID,
  apiExperimentAdd
};
