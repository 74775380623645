import React, { useState, useEffect, useRef } from 'react';
import { Button, Box } from '@material-ui/core';
import ReactToPrint from 'react-to-print';
import { useParams } from 'react-router-dom';
import QRCode from 'qrcode.react';
import { apiIntoFactoryService } from '../api/intoFactoryApi';
import { apiDetectionService } from '../api/detectionApi';

const qrcodePrefix = process.env.REACT_APP_QRCODE_PREFIX;

const Label = React.forwardRef((props, ref) => {
  const [title, setTitle] = useState('');
  const [QRData, setQRData] = useState([]);
  const params = useParams();
  const [intofactoryNum, setIntofactoryNum] = useState('');
  let token = '';

  useEffect(() => {
    token = localStorage.getItem('token');
    if (token) {
      sessionStorage.setItem('token', token);
      localStorage.clear();
      switch (params.type) {
        case '1':
          apiIntoFactoryService.apiGetIntoFactoryItem(params.id, params.key).then((res) => {
            // console.log('res', res);
            setTitle(res.data.IntofactoryBaseInfo.CustomerInfo.CustomerName);
            setQRData(res.data.IntofactoryItemInfos);
            setIntofactoryNum(res.data.IntofactoryBaseInfo.IntofactoryNum);
          });
          break;
        case '2':
          apiDetectionService.apiSearchDetectionByID(params.id).then((res) => {
            // console.log('res', res);
            setTitle(res.data[0].DetectionNum);
            setQRData(res.data[0].DetectionItems);
          });
          break;
        default:
          break;
      }
    }
  }, [token]);

  return (
    <div ref={ref}>
      <style>
        {`@media print{
              @page { size: 40mm 30mm }
              body { visibility: visible; }
              .visible { visibility: hidden; visibility: visible; }
            }
            @page { 
              size: 40mm 30mm;
              margin:0.4in;
            }
            .landscape {
              size: 40mm 30mm;
            }
            .page {
              display: block;
              page-break-before: always;
            }`}
      </style>
      {QRData && QRData.map((item) => (
        <div className="page" style={{ margin: '0px 10px' }}>
          <table style={{ border: '2px', width: '150px', fontSize: '8px' }}>
            <tr>
              <td colSpan={2}>
                {params.type === '1' ? (
                  <div style={{ marginRight: '20px', float: 'left' }}>
                    入廠單品項
                  </div>
                )
                  : (
                    <div style={{ marginRight: '20px', float: 'left' }}>
                      檢測樣品
                    </div>
                  )}
                <div>
                  <QRCode
                    id="qr-gen"
                    value={params.type === '1' ? `${qrcodePrefix}://IntofactoryDetection?IntofactoryID=${params.id}&CommodityID=${params.key}` : item.DetectionSamples[0].SampleID}
                    size={60}
                    level="L"
                  />
                </div>
              </td>
            </tr>
            <tr>
              {params.type === '1'
                ? (
                  <td>
                    <Box style={{ whiteSpace: 'nowrap' }}>
                      {`單號: ${intofactoryNum}`}
                    </Box>
                  </td>
                )
                : (
                  <td>
                    <Box>
                      {`檢測單號: ${title}` || ''}
                    </Box>
                  </td>
                )}
            </tr>
            <tr>
              <td colSpan={2}>
                {params.type === '1' ? `品號: ${item.SerialNo}` : `樣品編號: ${item.DetectionSamples[0].SampleID}` || ''}
              </td>
            </tr>
          </table>
        </div>
      ))}
    </div>
  );
});

const ComponentToPrint = () => {
  const componentRef = useRef();

  return (
    <div>
      <Label ref={componentRef} />
      <br />
      <ReactToPrint
        trigger={() => <Button color="primary" variant="contained">列印</Button>}
        content={() => componentRef.current}
      />
    </div>
  );
};
export default ComponentToPrint;
