import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@mui/material';
import IntoFactoryListResults from '../components/intoFactory/intoFactoryListResults';
import IntoFactoryListToolbar from '../components/intoFactory/intoFactoryListToolbar';
import { apiIntoFactoryService } from '../api/intoFactoryApi';
import { apiOpenService } from '../api/openApi';

const IntoFactoryList = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataList, setDataList] = useState([]);
  const [customerSelect, setCustomerSelect] = useState([]);

  const handleSearchChange = (value) => {
    setOpenBackdrop(true);
    apiIntoFactoryService.apiSearchIntoFactory(value).then((res) => {
      setDataList(res.data);
      setOpenBackdrop(false);
    });
  };

  useEffect(() => {
    setOpenBackdrop(true);
    apiOpenService.apiGetSelectCustomerData().then((res) => setCustomerSelect(res));
    apiIntoFactoryService.apiGetIntoFactoryList().then((x) => {
      setDataList(x.data);
      setOpenBackdrop(false);
    });
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>入廠單資料</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <IntoFactoryListToolbar customerSelect={customerSelect} onClick={handleSearchChange} />
          <Box sx={{ pt: 3 }}>
            <IntoFactoryListResults intoFactorys={dataList} />
          </Box>
        </Container>
      </Box>
    </>
  );
};
export default IntoFactoryList;
