import React, { useState } from 'react';
import moment from 'moment';
import {
  Box,
  Button,
  Card,
  CardContent,
  Grid,
  TextField,
  MenuItem
} from '@material-ui/core';
import useStyles from 'src/theme/useStyles';
import IntoFactoryDialog from './intoFactoryDialog';

const IntoFactoryListToolbar = (props) => {
  const [value, setValue] = useState({
    CustomerID: '',
    ItemName: '',
    From: moment().format('YYYY-MM-01'),
    To: moment(new Date()).format('YYYY-MM-DD')
  });
  const classes = useStyles();
  const { onClick, customerSelect } = props;

  // TEST dialog start
  const [open, setOpen] = useState(false);
  const handleClickOpen = () => {
    setOpen(true);
  };
  const handleClose = () => {
    setOpen(false);
    window.location.reload();
  };
  // TEST dialog end

  // console.log('customerSelect', customerSelect);

  return (
    <Box>
      <Box xs={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          className={classes.AddButton}
          size="small"
          variant="contained"
          onClick={handleClickOpen}
        >
          新增入廠單
        </Button>
        <IntoFactoryDialog open={open} onClose={handleClose} reqData={null} />
      </Box>
      <Box sx={{ mt: 3 }}>
        <Card>
          <CardContent>
            <Grid container spacing={3}>
              <Grid item xs={3}>
                <TextField
                  select
                  fullWidth
                  name="CustomerID"
                  label="客戶"
                  value={value.CustomerID || ''}
                  placeholder="查詢客戶代號"
                  variant="outlined"
                  onChange={(e) => setValue({ ...value, CustomerID: e.target.value })}
                >
                  {customerSelect && customerSelect.map((option) => (
                    <MenuItem key={option.CustomerID} value={option.CustomerID}>
                      {option.CustomerName}
                    </MenuItem>
                  ))}
                </TextField>
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="ItemName"
                  value={value.ItemName || ''}
                  placeholder="查詢品名"
                  variant="outlined"
                  onChange={(e) => setValue({ ...value, ItemName: e.target.value })}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="From"
                  value={value.From}
                  placeholder="查詢起始日期"
                  variant="outlined"
                  onChange={(e) => {
                    setValue({ ...value, From: moment(e.target.value).format('YYYY-MM-DD') });
                  }}
                  type="date"
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  fullWidth
                  name="To"
                  value={value.To}
                  placeholder="查詢結束日期"
                  variant="outlined"
                  onChange={(e) => {
                    setValue({ ...value, To: moment(e.target.value).format('YYYY-MM-DD') });
                  }}
                  type="date"
                />
              </Grid>
              <Grid item xs={3} mt={1}>
                <Button onClick={() => onClick(value)} color="primary" variant="contained">
                  搜尋
                </Button>
              </Grid>
            </Grid>
          </CardContent>
        </Card>
      </Box>
    </Box>
  );
};
export default IntoFactoryListToolbar;
