import React, { useState } from 'react';
import {
  // Link as RouterLink,
  useNavigate
} from 'react-router-dom';
import { Helmet } from 'react-helmet';
import {
  Alert, AlertTitle, Backdrop, CircularProgress, Fade
} from '@mui/material';
import * as Yup from 'yup';
import { Formik } from 'formik';
import {
  Box,
  Button,
  Container,
  // Link,
  TextField,
  Typography
} from '@material-ui/core';
import { apiLoginService } from '../api/loginApi';
import { apiStaffService } from '../api/staffApi';

const Login = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [fade, setFade] = useState(false);
  const [isError, setIsError] = useState(false);
  const navigate = useNavigate();
  const [errMsg, setErrMsg] = useState(null);

  const schema = Yup.object().shape({
    Account: Yup.string().required('工號必填'),
    Password: Yup.string().required('密碼必填')
  });

  const checkin = (fields, { setSubmitting }) => {
    setOpenBackdrop(true);
    apiLoginService.apiGetLoginData(fields).then((response) => {
      // console.log('response', response);
      if (response.token) {
        sessionStorage.setItem('token', response.token);
        setFade(true);
        apiStaffService.apiGetStaffMe().then((me) => {
          // console.log('me', me);
          sessionStorage.setItem('Staffme', JSON.stringify(me.data));
          // 不知道要導到哪，取第一筆
          let url = '';
          if (me.data.Role.StaffSystem === '1') {
            url = '/staff';
          } else if (me.data.Role.CustomerSystem === '1') {
            url = '/customer';
          } else if (me.data.Role.VenderSystem === '1') {
            url = '/vender';
          } else if (me.data.Role.CommoditySystem === '1') {
            url = '/commodity';
          } else if (me.data.Role.IntofactorySystem === '1') {
            url = '/intofactory';
          } else if (me.data.Role.DetectionSystem === '1' && me.data.Departments[0].DepartmentID !== 7) {
            url = '/detection';
          } else if (me.data.Role.QutationSystem === '1') {
            url = '/qutation';
          } else if (me.data.Role.SaleSystem === '1') {
            url = '/sale';
          } else {
            url = '/experiment';
          }
          setTimeout(() => navigate(url, { replace: true }), 1000);
        });
        setOpenBackdrop(false);
      }
    }, (error) => {
      // console.log('login_error', error);
      if (error !== '') { setErrMsg(error); }
      setSubmitting(false);
      setOpenBackdrop(false);
      setIsError(true);
      setFade(true);
    });
  };

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>登入</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          display: 'flex',
          flexDirection: 'column',
          height: '100%',
          justifyContent: 'center'
        }}
      >
        <Container maxWidth="sm">
          <Formik
            initialValues={{
              Account: '',
              Password: ''
            }}
            validationSchema={schema}
            onSubmit={checkin}
          >
            {({
              errors,
              handleBlur,
              handleChange,
              handleSubmit,
              isSubmitting,
              touched,
              values
            }) => (
              <form onSubmit={handleSubmit}>
                <Box sx={{ mb: 3 }}>
                  <Typography
                    color="textPrimary"
                    variant="h2"
                  >
                    員工登入
                  </Typography>
                  <Typography
                    color="textSecondary"
                    gutterBottom
                    variant="body2"
                  >
                    請輸入工號密碼:
                  </Typography>
                </Box>
                <TextField
                  error={Boolean(touched.Account && errors.Account)}
                  fullWidth
                  helperText={touched.Account && errors.Account}
                  label="工號"
                  margin="normal"
                  name="Account"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  value={values.Account}
                  variant="outlined"
                />
                <TextField
                  error={Boolean(touched.Password && errors.Password)}
                  fullWidth
                  helperText={touched.Password && errors.Password}
                  label="密碼"
                  margin="normal"
                  name="Password"
                  onBlur={handleBlur}
                  onChange={handleChange}
                  type="password"
                  value={values.Password}
                  variant="outlined"
                />
                <Box sx={{ py: 2 }}>
                  <Button
                    color="primary"
                    disabled={isSubmitting}
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    登入
                  </Button>
                </Box>
                {/* <Typography
                  color="textSecondary"
                  variant="body1"
                >
                  Don&apos;t have an account?
                  {' '}
                  <Link
                    component={RouterLink}
                    to="/Register"
                    variant="h6"
                  >
                    Sign up
                  </Link>
                </Typography> */}
              </form>
            )}
          </Formik>
          <Fade in={fade}>
            {isError
              ? (
                <Alert severity="error">
                  <AlertTitle>登入失敗</AlertTitle>
                  {errMsg || '工號或密碼錯誤'}
                </Alert>
              )
              : (
                <Alert severity="success">
                  <AlertTitle>登入成功</AlertTitle>
                </Alert>
              )}
          </Fade>
        </Container>
      </Box>
    </>
  );
};

export default Login;
