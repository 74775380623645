import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { Box, Container } from '@material-ui/core';
import { Backdrop, CircularProgress } from '@mui/material';
import ExperimentListResults from '../components/experiment/experimentListResults';
import { apiExperimentService } from '../api/experimentApi';

const ExperimentList = () => {
  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [dataList, setDataList] = useState([]);

  useEffect(() => {
    // console.log('ExperimentList_start');
    setOpenBackdrop(true);
    apiExperimentService.apiGetExperimentAll().then((x) => {
      setDataList(x.data);
      setOpenBackdrop(false);
    });
    // console.log('ExperimentList_end');
  }, []);

  return (
    <>
      <Backdrop
        sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 9999 }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Helmet>
        <title>實驗室資料</title>
      </Helmet>
      <Box
        sx={{
          backgroundColor: 'background.default',
          minHeight: '100%',
          py: 3
        }}
      >
        <Container maxWidth={false}>
          <Box sx={{ pt: 3 }}>
            <ExperimentListResults experiments={dataList} />
          </Box>
        </Container>
      </Box>
    </>
  );
};

export default ExperimentList;
