import { useState } from 'react';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from 'src/theme/useStyles';

const DetectionListResults = ({ detections, ...rest }) => {
  const role = JSON.parse(sessionStorage.getItem('Staffme'));
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  // console.log('detectionData', detections);

  return (
    <Card>
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                日期
              </TableCell>
              <TableCell>
                單號
              </TableCell>
              <TableCell>
                客戶代號
              </TableCell>
              <TableCell>
                客戶
              </TableCell>
              <TableCell>
                操作
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {detections && detections.slice(page * limit, page * limit + limit).map((detection) => (
              <TableRow
                hover
                key={detection.DetectionID}
              >
                <TableCell>
                  {detection.CommissionDate}
                </TableCell>
                <TableCell>
                  {detection.DetectionNum}
                </TableCell>
                <TableCell>
                  {detection.CustomerKey}
                </TableCell>
                <TableCell>
                  {detection.CustomerN}
                </TableCell>
                <TableCell>
                  <Box>
                    <Link component={RouterLink} to={`${detection.DetectionID}/copy`}>
                      <Button
                        className={classes.CopyButton}
                        size="small"
                        variant="contained"
                      >
                        複製
                      </Button>
                    </Link>
                    <Link component={RouterLink} to={`${detection.DetectionID}/view`} className="">
                      <Button
                        className={classes.EditButton}
                        size="small"
                        variant="contained"
                      >
                        檢視
                      </Button>
                    </Link>
                    {role.Departments[0].DepartmentID === 1
                      ? (
                        <Button
                          className={classes.DeleteButton}
                          size="small"
                          variant="contained"
                          onClick={() => rest.onClick(detection.DetectionID)}
                        >
                          刪除
                        </Button>
                      )
                      : null}
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={detections ? detections.length : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[5, 10, 25]}
      />
    </Card>
  );
};

DetectionListResults.propTypes = {
  detections: PropTypes.array
};

export default DetectionListResults;
