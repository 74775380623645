// import { Sales, CustomerData, CustomerSelect } from 'src/__mocks__/customers';
import fetchWrapper from './fetch-wrapper';

// 取得所有客戶資料
function apiGetCustomerAll() {
  // console.log('GetCustomerAll');
  return fetchWrapper.get('customer/?Page=1&Pagesize=500');
}

// 搜尋客戶
function apiSearchCustomer(CustomerID, Name) {
  let strURL = '';
  if (CustomerID !== '') {
    strURL = strURL.concat('CustomerID=', CustomerID);
  } else {
    strURL = strURL.concat('CustomerKey=', Name);
    strURL = strURL.concat('&CustomerN=', Name);
    strURL = strURL.concat('&CustomerName=', Name);
  }
  // console.log('Customer_Search', strURL);
  return fetchWrapper.get(`customer/?${strURL}`);
}

function apiCustomerAdd(data) {
  // console.log('Customer_ADD', data);
  return fetchWrapper.post('customer/create', data);
}

function apiCustomerUpdate(parmaid, data) {
  const body = {
    ...data,
    CustomerID: parseInt(parmaid, 10)
  };
  // console.log('Customer_Update', body);
  return fetchWrapper.put('customer/update', body);
}

function apiCustomerDelete(id) {
  const body = { CustomerID: id };
  return fetchWrapper.delete('customer/delete', body);
}

export const apiCustomerService = {
  apiGetCustomerAll,
  apiSearchCustomer,
  apiCustomerAdd,
  apiCustomerUpdate,
  apiCustomerDelete
};
