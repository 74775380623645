import fetchWrapper from './fetch-wrapper';

// 取得下拉選單資料，commoditycategorys, CommodityStates, Departments, detectionmethods, detectiontypes, Elements, measuretypes, permissions, statustypes, warehousetypes
function apiGetSelectData(name) {
  // console.log(`GetSelectData${name}`);
  return fetchWrapper.get(`openapi/${name}`).then((res) => res.data && res.data.Records);
}

// 取得員工下拉選單資料
function apiGetSelectStaffData(name) {
  // console.log(`GetStaffselect${name}`);
  return fetchWrapper.get(`staff/?${name}`).then((res) => res.data && res.data.map((item) => ({ StaffID: item.StaffID, Name: item.Name })));
}

// 取得客戶下拉選單資料
function apiGetSelectCustomerData() {
  // console.log('GetCustomerselect');
  return fetchWrapper.get('customer/').then((res) => {
    const selectdata = res.data && res.data.map((item) => ({
      CustomerID: item.CustomerID, CustomerKey: item.CustomerKey, CustomerName: item.CustomerName, BusinessStaffName: item.BusinessStaff.Name
    }));
    return selectdata;
  });
}

// 取得商品下拉選單資料(入廠單用)
function apiGetSelectCommodityData(CustomerID, type) {
  // console.log('GetCommodityselect');
  let strURL = `CustomerID=${CustomerID}`;
  if (type !== 0) {
    strURL = strURL.concat('&DetectionTypeID=1');
  }
  return fetchWrapper.get(`commodity/?${strURL}`).then((res) => {
    const selectdata = res.data && res.data.map((item) => ({
      CommodityID: item.CommodityID, ItemID: item.ItemID, ItemName: item.ItemName
    }));
    return selectdata;
  });
}

export const apiOpenService = {
  apiGetSelectData,
  apiGetSelectStaffData,
  apiGetSelectCustomerData,
  apiGetSelectCommodityData
};
