import moment from 'moment';
import fetchWrapper from './fetch-wrapper';

/* 查詢功能start */
// 搜尋報價單
function apiSearchQutation(data) {
  let strURL = '';
  strURL = strURL.concat('From=', data.From);
  strURL = strURL.concat('&To=', data.To);
  if (data.CustomerID !== '') {
    strURL = strURL.concat('&CustomerID=', data.CustomerID);
  }
  if (data.ItemName !== '') {
    strURL = strURL.concat('&ItemName=', data.ItemName);
  }
  // console.log('Qutation_Search', strURL);
  return fetchWrapper.get(`qutation/getQutationList?${strURL}`);
}

// 取得報價列表
function apiGetQutationList() {
  // console.log('Qutation_GetList');
  return fetchWrapper.get('qutation/getQutationList?Page=1&Pagesize=500');
}

// 取得報價單明細
function apiGetQutationDetail(QutationID) {
  return fetchWrapper.get(`qutation/getQutation?QutationID=${QutationID}`);
}

/* 查詢功能end */

/* 新增功能start */
// 新增報價單
function apiQutationAdd(data) {
  const body = {
    ...data,
    CustomerID: parseInt(data.CustomerID, 10),
    PayMethodID: parseInt(data.PayMethodID, 10),
    BillTypeID: parseInt(data.BillTypeID, 10),
    TaxTypeID: parseInt(data.TaxTypeID, 10),
    IntofactoryID: parseInt(data.Intofactory.IntofactoryID, 10),
  };
  // console.log('QutationADD', body);
  return fetchWrapper.post('qutation/createQutation', body);
}

// 新增報價單品項
function apiQutationitemAdd(data) {
  const body = {
    ...data,
    QutationID: parseInt(data.QutationID, 10),
    CommodityID: parseInt(data.CommodityID, 10),
    Weight: parseFloat(data.Weight),
    Concentration: parseFloat(data.Concentration),
    Amount: parseFloat(data.Amount)
  };
  // console.log('QutationItem_ADD', body);
  return fetchWrapper.post('qutation/createQutationItem', body);
}

/* 新增功能end */

/* 更新功能start */
// 更新報價單
function apiQutationUpdate(parmaid, data) {
  const body = {
    ...data,
    QutationID: parseInt(parmaid, 10),
    CustomerID: parseInt(data.CustomerID, 10),
    PayMethodID: parseInt(data.PayMethodID, 10),
    BillTypeID: parseInt(data.BillTypeID, 10),
    TaxTypeID: parseInt(data.TaxTypeID, 10),
    IntofactoryID: parseInt(data.Intofactory.IntofactoryID, 10)
  };
  // console.log('Qutation_Update', body);
  return fetchWrapper.put('qutation/updateQutation', body);
}

// 更新報價單品項
function apiQutationItemUpdate(parmaid, data) {
  const body = {
    ...data,
    QutationItemID: parseInt(parmaid, 10),
    QutationID: parseInt(data.QutationID, 10),
    CommodityID: parseInt(data.CommodityID, 10),
    Weight: parseFloat(data.Weight),
    Concentration: parseFloat(data.Concentration),
    Amount: parseFloat(data.Amount)
  };
  // console.log('QutationItem_Update', body);
  return fetchWrapper.put('qutation/updateQutationItem', body);
}

/* 更新功能end */

/* 刪除功能 */
// 刪除報價單
function apiQutationDelete(id) {
  const body = { QutationID: id };
  return fetchWrapper.delete('qutation/deleteQutation', body);
}

// 刪除報價單品項
function apiQutationItemDelete(QItemID, QID) {
  const body = { QutationID: QID, QutationItemID: QItemID };
  return fetchWrapper.delete('qutation/deleteQutationItem', body);
}

/* 刪除功能end */

/* 複製功能 */

// 複製報價單資料
function apiQutationCopyByID(id) {
  const body = {
    QutationID: parseInt(id, 10)
  };
  return fetchWrapper.post('qutation/copyQutation', body);
}

/* 複製功能end */

export const apiQutationService = {
  apiSearchQutation,
  apiGetQutationList,
  apiGetQutationDetail,
  apiQutationAdd,
  apiQutationitemAdd,
  apiQutationUpdate,
  apiQutationItemUpdate,
  apiQutationDelete,
  apiQutationItemDelete,
  apiQutationCopyByID
};
