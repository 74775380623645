import { useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Box,
  Button,
  Card,
  Link,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TablePagination,
  TableRow
} from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import useStyles from 'src/theme/useStyles';

const ExperimentListResults = ({ experiments }) => {
  const [limit, setLimit] = useState(10);
  const [page, setPage] = useState(0);
  const classes = useStyles();

  const handleLimitChange = (event) => {
    setLimit(event.target.value);
  };

  const handlePageChange = (event, newPage) => {
    setPage(newPage);
  };

  return (
    <Card>
      <Box sx={{ minWidth: 1050 }}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>
                日期
              </TableCell>
              <TableCell>
                檢測單號
              </TableCell>
              <TableCell>
                複驗
              </TableCell>
              <TableCell>
                操作
              </TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {experiments && experiments.slice(page * limit, page * limit + limit).map((experiment) => (
              <TableRow
                hover
                key={experiment.DetectionID}
              >
                <TableCell>
                  {moment(experiment.CommissionDate).format('YYYY-MM-DD')}
                </TableCell>
                <TableCell>
                  {experiment.DetectionNum}
                </TableCell>
                <TableCell>
                  {experiment.Recheck === '1' ? '是' : '否'}
                </TableCell>
                <TableCell>
                  <Box>
                    <Link component={RouterLink} to={`${experiment.DetectionID}/edit`}>
                      <Button
                        className={classes.EditButton}
                        size="small"
                        variant="contained"
                      >
                        編輯
                      </Button>
                    </Link>
                  </Box>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Box>
      <TablePagination
        component="div"
        count={experiments ? experiments.length : 0}
        onPageChange={handlePageChange}
        onRowsPerPageChange={handleLimitChange}
        page={page}
        rowsPerPage={limit}
        rowsPerPageOptions={[10, 25, 50, 100]}
      />
    </Card>
  );
};

ExperimentListResults.propTypes = {
  experiments: PropTypes.array
};

export default ExperimentListResults;
