const baseUrl = process.env.REACT_APP_API_URL;

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text);
    // console.log('handleResponse_data', data);

    if (!response.ok) {
      const error = (data && data.msg) || response.statusText;
      return Promise.reject(error);
    }

    return data;
  });
}

function get(url) {
  const header = new Headers();
  const bearer = sessionStorage.getItem('token');
  header.append('Authorization', bearer);
  const requestOptions = {
    method: 'GET',
    headers: header
  };
  // console.log('requestOptions', requestOptions);
  return fetch(`${baseUrl}/${url}`, requestOptions).then(handleResponse);
}

function getdownload(url) {
  const header = new Headers();
  const bearer = sessionStorage.getItem('token');
  header.append('Authorization', bearer);
  const requestOptions = {
    method: 'GET',
    headers: header
  };
  // console.log('requestOptions', requestOptions);
  return fetch(`${baseUrl}/${url}`, requestOptions).then((res) => res.blob());
}

function post(url, body) {
  const header = new Headers();
  const bearer = sessionStorage.getItem('token');
  header.append('Authorization', bearer);
  header.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'POST',
    headers: header,
    body: JSON.stringify(body)
  };
  // console.log('requestOptions', requestOptions);
  return fetch(`${baseUrl}/${url}`, requestOptions).then(handleResponse);
}

function postupload(url, body) {
  const header = new Headers();
  const bearer = sessionStorage.getItem('token');
  header.append('Authorization', bearer);
  header.append('Content-Type', 'multipart/form-data');
  const requestOptions = {
    method: 'POST',
    headers: header,
    body: JSON.stringify(body)
  };
  // console.log('requestOptions', requestOptions);
  return fetch(`${baseUrl}/${url}`, requestOptions).then(handleResponse);
}

function put(url, body) {
  const header = new Headers();
  const bearer = sessionStorage.getItem('token');
  header.append('Authorization', bearer);
  header.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'PUT',
    headers: header,
    body: JSON.stringify(body)
  };
  return fetch(`${baseUrl}/${url}`, requestOptions).then(handleResponse);
}

// prefixed with underscored because delete is a reserved word in javascript
function Ddelete(url, body) {
  const header = new Headers();
  const bearer = sessionStorage.getItem('token');
  header.append('Authorization', bearer);
  header.append('Content-Type', 'application/json');
  const requestOptions = {
    method: 'DELETE',
    headers: header,
    body: JSON.stringify(body)
  };
  return fetch(`${baseUrl}/${url}`, requestOptions).then(handleResponse);
}

export default {
  get,
  getdownload,
  post,
  postupload,
  put,
  delete: Ddelete
};
